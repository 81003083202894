// Customizable Area Start
import React from "react";
import B2bMasterProductController, {
  IB2BProductMaster,
  IB2BSections,
  ISection,
  Props,
  ServiceData,
  Services,
  configJSON,
} from "./B2bMasterProductController";
import {
  styled,
  Box,
  IconButton,
  Popover,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Paper,
} from "@material-ui/core";
import {
  ListingPageHeading,
  FilterBox,
  MainButtonComponent,
} from "../../../components/src/customComponents/CustomTags.web";
import {
  renderBaseOnConditions,
  sortStringCondition,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import FilterIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { LoadingComponent } from "../../../components/src/Loader.web";
import SortingTableHeader from "../../../components/src/SortingTableHeader2";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import {
  priceListWebStyle,
  TableStyle,
  StyledIconButton,
  StyledTableCell,
  OptionsWrapper,
} from "./CfPriceList.web";
import DragHandleIcon from "@material-ui/icons/DragHandle";
// Customizable Area End

// Customizable Area Start
const Strings = configJSON.strings.b2bMasterProductListString;
// Customizable Area End

export default class B2bMasterProduct extends B2bMasterProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getImportExportPopover = () => {
    const { openImportExportPopover } = this.state;
    const open = Boolean(openImportExportPopover);
    const popoverId = open ? "simple-popover" : undefined;
    return (
      <ExportImportPopover
        popOverId={popoverId}
        openAction={open}
        popoverOpen={openImportExportPopover}
        popoverClose={() => this.handleCloseImportExportPopver()}
        exportTemplateButtonClick={() => console.debug("template")}
        importCsvButtonClick={() => console.debug("import")}
        exportCsvButtonClick={() => console.debug("export")}
      />
    );
  };

  getActionPopover = () => {
    const { openActionPopover } = this.state;
    const open = Boolean(this.state.openActionPopover);
    const popOverId = open ? "simple-popover" : undefined;

    return (
      <StyledActionPopover
        data-test-id='popver'
        open={open}
        anchorEl={openActionPopover}
        onClose={this.handleCloseActionPopver}
        id={popOverId}
        style={{ boxShadow: "none" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {renderBaseOnConditions(
          true,
          <div className={"popoverContainer"}>
            {renderBaseOnConditions(
              true,
              <Box className={"popoverButton"}>{Strings.view}</Box>,
              <></>
            )}
            {renderBaseOnConditions(
              true,
              <Box data-test-id="editButton" onClick={this.handleEditProductMaster} className={"popoverButton"}>{Strings.edit}</Box>,
              <></>
            )}
          </div>,
          <div className={"popoverContainer"}>
            <Box className={"noPermissions"}>{Strings.noPermissions}</Box>
          </div>
        )}
      </StyledActionPopover>
    );
  };

  checkSections(section: IB2BSections, rowId: string) {
    let sections: string[] = [];
    if (section.data?.length !== 0) {
      section.data?.map((item: ISection, index: number) => {
        return sections.push(item?.attributes.section_name)
      })
      return sections.length > 4 ?
        <span data-test-id="sectionNames" style={{ position: 'relative' }} onMouseEnter={() => { this.handleSectionPopupOpen(rowId) }} onMouseLeave={() => { this.handleSectionPopupClose() }}>
          {sections.slice(0, 4).join(", ").substring(0 ,25)}...
          <div style={{ ...priceListWebStyle.sectionPopup, display: `${rowId === String(this.state.sectionPopupId) ? "" : "none"}` }}>
            {sections.join(", ")}
          </div>
        </span> : sections.join(", ").substring(0 ,25)
    }
    else {
      return "--"
    }
  }

  checkService(service: Services, rowId: string) {
    let services: string[] = [];
    if (service?.data.length !== 0) {
      service?.data.map((item: ServiceData) => {
        return services.push(item.attributes.name)
      })
      return services.length > 2 ?
        <span data-test-id="sectionNames" style={{ position: 'relative' }} onMouseEnter={() => { this.handleSectionPopupOpen(rowId) }} onMouseLeave={() => { this.handleSectionPopupClose() }}>
          {services.slice(0, 3).join(", ").substring(0 ,20)}...
          <div style={{ ...priceListWebStyle.sectionPopup, display: `${rowId === String(this.state.sectionPopupId) ? "" : "none"}` }}>
            {services.join(", ")}
          </div>
        </span> : services.join(", ").substring(0 ,20)
    }
    else {
      return "--"
    }
  }


  // Customizable Area End

  render() {
    // Customizable Area Start
    const { openImportExportPopover, openActionPopover } = this.state;
    const open = Boolean(openImportExportPopover);
    const popoverId = open ? "simple-popover" : undefined;
    // Customizable Area End
    return (
      // Customizable Area Start
      <BusinessAcountListStyleWrapper>
        <Box className={"topHeaderBox"}>
          <ListingPageHeading>{Strings.headingTitle}</ListingPageHeading>
          <Box className={"topRightBox"}>
            <FilterBox
              data-test-id={"filterBtn"}
              className={sortStringCondition(
                this.state.isAppliedFilter,
                "active-border",
                ""
              )}
            >
              <FilterIcon color={this.handleReturnColorType()} />
              {renderBaseOnConditions(
                this.state.isAppliedFilter,
                <div className={"filterActiveIcon"} />,
                <></>
              )}
            </FilterBox>
            <Box>
              <LoadingComponent
                isLoading={this.state.isLoadingPermission}
                height={56}
                width={175}
                isAllowed={true}
                children={
                  <IconButton
                    data-test-id={`export-import-btn`}
                    id={"IconButton"}
                    aria-describedby={popoverId}
                    onClick={(event) => this.openImportExportPopover(event)}
                    className={"export-btn"}
                  >
                    {Strings.exportimportText}{" "}
                    {openImportExportPopover ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                }
              />
            </Box>

            <Box>
              <LoadingComponent
                isLoading={this.state.isLoadingPermission}
                height={56}
                width={175}
                isAllowed={true}
                children={
                  <MainButtonComponent className={"mr-0"}>
                    {Strings.addPriceList}
                  </MainButtonComponent>
                }
              />
            </Box>

            <Box>
              <LoadingComponent
                isLoading={this.state.isLoadingPermission}
                height={56}
                width={175}
                isAllowed={true}
                children={
                  <MainButtonComponent data-test-id="add-product-master" onClick={this.handleAddB2BProduct} className={"mr-0"}>
                    {Strings.addProduct}
                  </MainButtonComponent>
                }
              />
            </Box>
          </Box>
        </Box>

        <Box>
          <TableContainer component={Paper} style={{ overflow: "visible" }}>
            <TableStyle>
              <DragDropContext
                onDragEnd={this.onDragEnd}
                data-test-id='tblDragDrop'
              >
                <Droppable droppableId='droppable'>
                  {(provided: DroppableProvided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <Table
                        style={priceListWebStyle.table}
                        aria-label='customized table'
                      >
                        <TableHead>
                          <TableRow
                            style={{
                              background: "#204B9C",
                              color: "#FFFFFF",
                              paddingTop: "5px",
                              borderRadius: "5px",
                            }}
                          >
                            <TableCell
                              style={{
                                ...priceListWebStyle.tableHeader,
                                ...priceListWebStyle.actionHeader,
                                ...priceListWebStyle.tableHeaderLeft,
                                width: "3%",
                              }}
                              align='right'
                            />
                            <SortingTableHeader
                              minHeightType={true}
                              titleContainerClassName='headerTxt'
                              title='Product Name'
                              sortingKey='name'
                              width='30%'
                              {...this.getSortingProps()}
                            />
                            <SortingTableHeader
                              minHeightType={true}
                              titleContainerClassName='headerTxt'
                              title='Product Type'
                              sortingKey='type'
                              width='30%'
                              {...this.getSortingProps()}
                            />
                            <SortingTableHeader
                              minHeightType={true}
                              titleContainerClassName='headerTxt'
                              title='Service'
                              sortingKey='service'
                              width='35%'
                              {...this.getSortingProps()}
                            />
                            <SortingTableHeader
                              minHeightType={true}
                              titleContainerClassName='headerTxt'
                              title='Section'
                              sortingKey='sections'
                              width='25%'
                              {...this.getSortingProps()}
                            />
                            <SortingTableHeader
                              minHeightType={true}
                              titleContainerClassName='headerTxt'
                              title='Approx. Weight'
                              sortingKey='approx_weight'
                              width='30%'
                              {...this.getSortingProps()}
                            />
                            <TableCell
                              style={{
                                ...priceListWebStyle.tableHeader,
                                ...priceListWebStyle.actionHeader,
                                ...priceListWebStyle.tableHeaderRight,
                                width: "3%",
                              }}
                              align='right'
                            />
                          </TableRow>
                        </TableHead>
                        <TableBody data-test-id='tblBody'>
                          {this.state.isLoading && (
                            <>
                              {Array(10)
                                .fill(1)
                                .map((_, index) => (
                                  <TableRow
                                    className='tableRow'
                                    data-test-id={index}
                                  >
                                    {[...Array(1)].map((_, cellIndex) => (
                                      <TableRowsSkeleton key={cellIndex} />
                                    ))}
                                  </TableRow>
                                ))}
                            </>
                          )}
                          {!this.state.isLoading &&
                          this.state.dragItem?.length !== 0 ? (
                            this.state.dragItem?.map(
                              (rowData: IB2BProductMaster, index: number) => (
                                <Draggable
                                  key={rowData.id}
                                  draggableId={rowData.id}
                                  index={index}
                                  isDragDisabled={Boolean(
                                    this.state.isDragableDisabled
                                  )}
                                >
                                  {(
                                    provided: DraggableProvided,
                                    snapshot: DraggableStateSnapshot
                                  ) => (
                                    <TableRow
                                      data-test-id={"tableTR"}
                                      key={rowData.id}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <TableCell
                                        style={{
                                          ...priceListWebStyle.actionCellContainer,
                                          width: "5%",
                                        }}
                                        align='right'
                                        key={rowData.id}
                                        data-test-id={`rowDraggable-${index}`}
                                        {...provided.dragHandleProps}
                                      >
                                        <StyledIconButton>
                                          <DragHandleIcon />
                                        </StyledIconButton>
                                      </TableCell>

                                      <TableCell
                                        data-test-id={"tableTD"}
                                        style={{
                                          ...priceListWebStyle.cellContainer,
                                          width: "30%",
                                          opacity: this.checkDisabled(
                                            rowData.attributes.activated
                                          ),
                                        }}
                                        scope='row'
                                      >
                                        <StyledTableCell className='border-left'>
                                          {rowData.attributes.name}
                                        </StyledTableCell>
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          ...priceListWebStyle.cellContainer,
                                          width: "30%",
                                          opacity: this.checkDisabled(
                                            rowData.attributes.activated
                                          ),
                                        }}
                                        align='left'
                                      >
                                        <StyledTableCell
                                          data-test-id={`productType-${rowData.id}`}
                                        >
                                          {
                                            rowData.attributes.product_type
                                              .value
                                          }
                                        </StyledTableCell>
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          ...priceListWebStyle.cellContainer,
                                          width: "35%",
                                          opacity: this.checkDisabled(
                                            rowData.attributes.activated
                                          ),
                                        }}
                                        align='left'
                                      >
                                        <StyledTableCell
                                          data-test-id={`services-${rowData.id}`}
                                        >
                                       {this.checkService(rowData.attributes.services as unknown as Services,rowData.id)}
                                        </StyledTableCell>
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          ...priceListWebStyle.cellContainer,
                                          width: "35%",
                                          opacity: this.checkDisabled(
                                            rowData.attributes.activated
                                          ),
                                        }}
                                        align='left'
                                      >
                                        <StyledTableCell
                                          data-test-id={`sections-${rowData.id}`}
                                        >
                                         {this.checkSections(rowData.attributes.sections,rowData.id)}
                                        </StyledTableCell>
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          ...priceListWebStyle.cellContainer,
                                          width: "25%",
                                          opacity: this.checkDisabled(
                                            rowData.attributes.activated
                                          ),
                                        }}
                                        align='left'
                                      >
                                        <StyledTableCell
                                          data-test-id={`approx_weight-${rowData.id}`}
                                          className="border-right"
                                        >
                                          {rowData.attributes.approx_weight}
                                        </StyledTableCell>
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          ...priceListWebStyle.actionCellContainer,
                                          width: "5%",
                                        }}
                                        align='right'
                                      >
                                        <OptionsWrapper>
                                          <IconButton
                                            data-test-id={"more-menu-button"}
                                            id={"IconButton" + index}
                                            onClick={(event) => this.handleOpenActionPopver(event,rowData.id)}
                                          >
                                            <MoreVertIcon />
                                          </IconButton>
                                        </OptionsWrapper>
                                      </TableCell>
                                      
                                    </TableRow>
                                  )}
                                </Draggable>
                              )
                            )
                          ) : (
                            !this.state.isLoading &&   <TableRow>
                              <TableCell
                                style={priceListWebStyle.nodataCellContainer}
                                align='left'
                                colSpan={5}
                              >
                                <div
                                  style={{
                                    ...priceListWebStyle.noDataCell,
                                    textAlign: "center",
                                  }}
                                >
                                  No product were found
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                          {provided.placeholder}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </TableStyle>
          </TableContainer>
        </Box>

        {renderBaseOnConditions(
          this.state.dragItem?.length !== 0 ,
          <TablePagination
            data-test-id='productListPagination'
            rowsPerPageOptions={[10]}
            component='div'
            count={this.state.productListPagination?.total_count}
            rowsPerPage={this.state.pageSize}
            page={this.state.page}
            onPageChange={(event, page) => { this.handlePageChange(page) }}
          />,
          <></>
        )}
        {openActionPopover && this.getActionPopover()}
        {openImportExportPopover && this.getImportExportPopover()}
      </BusinessAcountListStyleWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BusinessAcountListStyleWrapper = styled(Box)({
  width: "100%",
  "& .topHeaderBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "17px 0 32px 0",
  },
  "& .topRightBox": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  "& .tableBodyLoader": {
    textAlign: "center",
    paddingBottom: 15,
  },
  "& .noDataFound": {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: "8px 0 12px 0",
  },
  "& .viewStatusButton": {
    padding: "4px 10px",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 600,
    color: colors().cyancobaltblue,
    background: "rgba(32, 75, 156, 0.1)",
    fontFamily: "Montserrat",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  "& .moreMenuButton": {
    padding: "5px",
    borderRadius: "8px",
    textAlign: "center",
    color: colors().viewTextColor,
    background: "rgba(32, 75, 156, 0.1)",
    cursor: "pointer",
  },
  "& .bottomStatusCell": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .statusLastColumn": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "12px",
  },
  "& .tablePaginationBox": {
    width: "100%",
    padding: "8px 0",
  },
  "& .export-btn": {
    width: "auto !important",
    margin: "0 10px",
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px !important",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px !important",
      width: "160px !important",
      height: "44px !important",
    },
  },
});

const StyledActionPopover = styled(Popover)({
  "& .noPermissions": {
    padding: "0 8px",
  },
  "& .popoverButton": {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    paddingTop: "8px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
  },
  "& .popoverContainer": {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  }  
});
// Customizable Area End
