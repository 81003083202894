Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiContentType = "application/json";
exports.requestType = "application/json";
exports.apiMethodType = "GET";
exports.countryApiMethodType = "GET";
exports.companyEndPoint = "bx_block_company/companies";
exports.countryEndPoint = "bx_block_taxcalculator/countries";
exports.httpPutMethod = "PUT";
exports.httpPostMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfSubCompany";
exports.labelBodyText = "CfSubCompany Body";

exports.btnExampleTitle = "CLICK ME";
exports.tokenKey = "token";
exports.companyHeader = "Company";
exports.addCompany = "Add Company";
exports.companyName = "Company Name";
exports.companyNameArabic = "Company Name (Arabic)";
exports.VATNumber = "VAT Number";
exports.VATNumberArabic = "VAT Number (Arabic)";
exports.emailAddress = "E-Mail Address";
exports.phoneNumber = "Telephone";
exports.country = "Country";
exports.status = "Status";
exports.address = "Address";
exports.vatDetails = "Tax Details";
exports.numberOfVat = "Number Of Tax";
exports.vatName = "Tax Name";
exports.tinNo = "TIN No.";
exports.companyInformation = "Company Information";
exports.editCompany = "Edit";
exports.back = "Back";
exports.add = "Add";
exports.cancel = "Cancel";
exports.save = "Save";
exports.testing="testing"
exports.testing2="testing2"
exports.popoverTest = "popoverTest";
exports.registrationNumber = "Company Registration Number";
exports.delete = "delete";
exports.save = "Save";
exports.companyUpdateApi = "bx_block_company/companies";
exports.taxListApi = "bx_block_taxcalculator/taxcalculators";
exports.companyNameSuggestionsEndPoint =
  "bx_block_company/companies/companies_filter?company_name=";
exports.countryNameSuggestionsEndPoint =
  "bx_block_company/companies/companies_filter?country_name=";
exports.companyBusinessAccountEndPoint = 
  "bx_block_cfcontractmanagement7/business_accounts"
  

exports.strings = {
  BusinessAccountModal: {
    businessAccounts: "Business Accounts",
    cancel: "Cancel",
    tableString_company: "Company",
    tableString_subCompany: "Sub Company",
    tableString_businessCustomer: "Business Customer",
    tableString_businessAccountNo: "Business Account No.",
    tableString_period: "Period",
    tableString_noOfTotalOrders: "No. of Total Orders",
    tableString_noOfTotalOPieces: "No. of Total Pieces",
    tableString_noAccountsFound: "No Business Account Found",
  }
}
exports.b2bCompanyName = "B2B Company Name";
exports.b2bEmailAddress = "Email ID";
exports.b2bRegNo = "Reg.No.";
exports.b2bCompanyHeader = "B2B Company";
exports.b2bSubCompanyName = "Sub-Company Name";
exports.b2bMainCompanyName = "Main Company";
exports.B2BcompanyInformation = "Add B2B Company";

// Customizable Area End
