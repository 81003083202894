// Customizable Area Start
import React from "react";
import B2bBussinessAccountListController, {
  BusinessAccountRecord,
  DialogPayload,
  Props,
  configJSON,
} from "./Cfb2bbussingessAccountListController";
import {
  styled,
  Box,
  CircularProgress,
  Typography,
  Button,
  IconButton,
  Popover,
  TablePagination,
  Dialog,
  Paper,
  PaperProps
} from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import moment, { Moment } from "moment";
import { toString } from "lodash";
import {
  ListingPageHeading,
  FilterBox,
  MainButtonComponent,
  StyledFieldLabel,
  StyledTextFieldWithLabel,
} from "../../../components/src/customComponents/CustomTags.web";
import {
  conditionalString,
  renderBaseOnConditions,
  sortStringCondition,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import FilterIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import StyledInlineDatePicker from "../../../components/src/StyledInlineDatepicker.web";
import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import CustomTimeRangePicker from "../../../components/src/CustomTimeRangePicker.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import { LoadingComponent } from "../../../components/src/Loader.web";
import FilterPopover from "../../../components/src/FilterPopover";
import { deleteIcon, warningIcon, calendarIcon } from "./assets"
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import * as commonAsset from "../../../components/src/commonAssets/assets"
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
// Customizable Area End

// Customizable Area Start
const Strings = configJSON.strings.b2bBussinessListString;
// Customizable Area End

export default class B2bBussinessAccountList extends B2bBussinessAccountListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleReturnStatusColumn = (businessAccountData: BusinessAccountRecord) => {
    const open = Boolean(this.state.openActionPopover);
    const popoverId = open ? "simple-popover" : undefined;

    return (
      <Box className={"statusLastColumn"}>
        <CustomSwitch
          checked={businessAccountData.activated}
          onChange={(checked) => this.handleToggleDialog({ action: checked ? "Activate" : "Deactivate", selectedBusinessAccount: businessAccountData })}
        />
        <Box className={"bottomStatusCell"}>
          <Button className={"viewStatusButton"} data-test-id={"view-status-button"} onClick={() => this.handleViewStatus(businessAccountData)}>
            {Strings.tabelString_viewStatus}
          </Button>
          <IconButton
            className={"moreMenuButton"}
            data-test-id={"more-menu-button"}
            aria-describedby={popoverId}
            onClick={(event) => this.handleOpenActionPopver(event, businessAccountData)}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  getImportExportPopover = () => {
    const { openImportExportPopover } = this.state;
    const open = Boolean(openImportExportPopover);
    const popoverId = open ? "simple-popover" : undefined;
    return (
      <ExportImportPopover
        popOverId={popoverId}
        openAction={open}
        popoverOpen={openImportExportPopover}
        popoverClose={() => this.handleCloseImportExportPopver()}
        exportTemplateButtonClick={() => console.debug("template")}
        importCsvButtonClick={() => console.debug("import")}
        exportCsvButtonClick={() => console.debug("export")}
      />
    );
  };

  getActionPopover = () => {
    const { openActionPopover, popoverItem } = this.state;
    const open = Boolean(this.state.openActionPopover);
    const popOverId = open ? "simple-popover" : undefined;

    return (
      <ActionPopover
        data-test-id='popver'
        style={{ boxShadow: "none" }}
        id={popOverId}
        open={open}
        anchorEl={openActionPopover}
        onClose={this.handleCloseActionPopver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {renderBaseOnConditions(
          true,
          <div className={"popoverContainer"}>
            {renderBaseOnConditions(
              true,
              <Box className={"popoverButton"} onClick={() => this.redirect("ViewBusinessAccount", popoverItem?.id)}>{Strings.view}</Box>,
              <></>
            )}
            <Box className={"popoverButton"} onClick={() => this.redirect("B2bBusinessAccountEdit", popoverItem?.id)}>{Strings.edit}</Box>
            {renderBaseOnConditions(
              true,
              <Box className={"popoverButton"} onClick={() => this.handleOpenRenewAccountForm(popoverItem as BusinessAccountRecord)}>{Strings.renew}</Box>,
              <></>
            )}
          </div>,
          <div className={"popoverContainer"}>
            <Box className={"noPermissions"}>{Strings.noPermissions}</Box>
          </div>
        )}
      </ActionPopover>
    );
  };

  renderBusinessAccountStatus = (viewStatusData: BusinessAccountRecord) => (
    <div data-test-id="view-status-dialog" className="view-status-dialog">
      <h3 className="dialog-title">
        Business Account
      </h3>
      <div className="dialog-content">
        {
          VIEW_STATUS_ROWS.map((rowElementArray, index) => (
            <div className="status-row" key={index}>
              {
                rowElementArray.map(
                  rowElement => {
                    let labelNote = ""
                    if (rowElement.binding === "total_no_of_order_for_contract") {
                      labelNote = conditionalString(viewStatusData.order_period_type_for_contract,
                        ` (per ${viewStatusData.order_period_type_for_contract})`,
                        ""
                      )
                    }
                    if (rowElement.binding === "total_no_of_pieces_for_contract") {
                      labelNote = conditionalString(viewStatusData.pieces_period_type_for_contract,
                        ` (per ${viewStatusData.pieces_period_type_for_contract})`,
                        ""
                      )
                    }
                    return (
                      <div key={rowElement.binding}>
                        <StyledFieldLabel>{rowElement.label + labelNote}</StyledFieldLabel>
                        <p className="row-value">
                          {
                            conditionalString(index > 1,
                              this.formatNumber(viewStatusData[rowElement.binding] as number | null),
                              conditionalString(
                                index === 1,
                                this.formatDate(viewStatusData[rowElement.binding] as string, "DD/MM/YYYY"),
                                toString(viewStatusData[rowElement.binding])
                              )
                            )
                          }
                        </p>
                      </div>
                    )
                  }
                )
              }
            </div>
          ))
        }
      </div>
      <div className="dialog-actions">
        <button type="button" className="cancel-btn" onClick={() => this.handleToggleDialog()}>Cancel</button>
      </div>
    </div>
  )

  renderConfirmationDialog = (dialogPayload: DialogPayload) => {
    const { action, selectedBusinessAccount, apiMessage = "", dialogTitle } = dialogPayload
    const isDone = action === "Done"
    
    return (
      <>
        <img src={conditionalString(action === "Deactivate", deleteIcon, warningIcon)} className="confirmation-img" />
        <Box paddingY={1.5}>
          <h3 className="dialog-title">
            {dialogTitle || `Confirm ${action}`}
          </h3>
          <p className="dialog-text-content">
            {conditionalString(isDone, apiMessage,
              `Are you sure you want to ${action.toLowerCase()} “${selectedBusinessAccount?.business_customer}” business account?`
            )}
          </p>
        </Box>
        <div className="dialog-actions">
          {
            renderBaseOnConditions(
              !isDone,
              <button type="button" className="cancel-btn" onClick={() => this.handleToggleDialog()}>Cancel</button>,
              null
            )
          }
          <button type="button" data-test-id="dialog-confirm-btn" onClick={() => this.handleConfirmation(selectedBusinessAccount)}>
            {conditionalString(
              isDone,
              "Back To Listing",
              action
            )}
          </button>
        </div>
      </>
    )
  }

  renderRenewForm = (businessAccountToRenew: BusinessAccountRecord) => {
    return (
      <Formik
        initialValues={businessAccountToRenew}
        onSubmit={this.handleSubmitRenewForm}
        enableReinitialize
        validationSchema={this.FormSchema}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          getFieldProps,
        }: FormikProps<BusinessAccountRecord>) => {
          const getErrorMessage = (fieldName: keyof BusinessAccountRecord) => (errors[fieldName] && touched[fieldName]) ? toString(errors[fieldName]) : ""
          const availableDays = this.weekdayOptions.filter(weekday => values.available_days.includes(weekday.id))
          const isSelectedAllDays = availableDays.length && availableDays.length === this.weekdayOptions.length
          const availableDaysValue = isSelectedAllDays ? [{id: "-1", option: "Select All"}] : availableDays
          const availableDaysForList = isSelectedAllDays ? [{id: "-1", option: "Select All"}, ...this.weekdayOptions] : availableDays
          const catalogueOptions = this.getCatalogueOptions(values.price_list)

          return (
            <Form data-test-id={configJSON.renewFormTest}>
              <h3 className="dialog-title">Renew Business Account</h3>
              <div className="form-body">
                <StyledTextFieldWithLabel
                  fieldLabel="Company"
                  value={values.company.name}
                  disabled
                />
                <StyledTextFieldWithLabel
                  fieldLabel="Sub-Company Name"
                  value={values.sub_company_name}
                  disabled
                />
                <StyledTextFieldWithLabel
                  data-test-id={configJSON.businessAccountNameTest}
                  fieldLabel="Business Account Name"
                  value={values.business_customer}
                  disabled
                />
                <StyledTextFieldWithLabel
                  data-test-id={configJSON.businessAccountNoTest}
                  fieldLabel="Business Account No."
                  value={values.business_account_no}
                  disabled
                />
                <div className="date-field">
                  <StyledFieldLabel>Contract Start Date</StyledFieldLabel>
                  <StyledInlineDatePicker
                    data-test-id='business_start_date'
                    id='business_start_date'
                    name='business_start_date'
                    value={values.business_start_date}
                    maxDate={values.business_end_date || undefined}
                    onChange={(dateValue: Moment | null) => {
                      setFieldValue("business_start_date", dateValue ? moment(dateValue).format("YYYY/MM/DD") : null);
                    }}
                    keyboardIcon={<img src={commonAsset.calendarIcon} />}
                    error={Boolean(getErrorMessage("business_start_date"))}
                    PopoverProps={{
                      disablePortal: true,
                    }}
                    InputProps={{
                      startAdornment: null,
                      style: {},
                      readOnly: true
                    }}
                  />
                </div>

                <div className="date-field">
                  <StyledFieldLabel>Contract End Date</StyledFieldLabel>
                  <StyledInlineDatePicker
                    data-test-id='business_end_date'
                    id='business_end_date'
                    name='business_end_date'
                    minDate={values.business_start_date || undefined}
                    maxDate={undefined}
                    value={values.business_end_date}
                    onChange={(dateValue: Moment | null) => {
                      setFieldValue("business_end_date", dateValue ? moment(dateValue).format("YYYY/MM/DD") : null);
                    }}
                    keyboardIcon={<img src={commonAsset.calendarIcon} />}
                    PopoverProps={{
                      disablePortal: true,
                    }}
                    InputProps={{
                      startAdornment: null,
                      style: {},
                      readOnly: true
                    }}
                    error={false}
                  />
                </div>
                <div className="autocomplete-field">
                  <StyledFieldLabel>Catalogue</StyledFieldLabel>
                  <AutocompleteSelect
                    className="catalogue-dropdown"
                    placeholder="Type to Select"
                    fieldName="price_list"
                    clearOnBlur
                    onChange={(
                      _item: React.ChangeEvent,
                      value: {
                        id: number
                        option: string
                      } | null) => {
                        setFieldValue("price_list", value ? { id: value.id, name: value.option } : null)
                    }}
                    handleScroll={this.handleScrollCatalogueDropdown}
                    onBlur={this.handleBlurCatalogueDropdown}
                    debouncedFunction={this.debouncedCatalogueAutocompleteHandler}
                    value={values.price_list?.id || null}
                    options={catalogueOptions}
                    listBoxStyle={{ maxHeight: 310 }}
                    paperComponent={this.paperComponent}
                  />
                </div>
                <div />
                <div>
                  <StyledFieldLabel>Available Time</StyledFieldLabel>
                  <CustomTimeRangePicker
                    timeRange={values.available_times}
                    onChangeTimeRange={(fromTime, toTime) =>
                      setFieldValue("available_times", {
                        id: values.available_times.id,
                        from: fromTime, 
                        to: toTime
                      })
                    }
                  />
                </div>
                <div className="autocomplete-field">
                  <StyledFieldLabel>Available Days</StyledFieldLabel>
                  <CustomSearchableMultiSelect
                    dataTestId={`dropdown_available_days`}
                    id="available_days"
                    name="available_days"
                    checkSelectAllText={() => { }}
                    value={availableDaysValue}
                    optionList={this.weekdayOptions}
                    placeHolderText="Select Available Days"
                    changeInputOnClear
                    handleScroll={() => { }}
                    handleClickOnSelectAll={() => { }}
                    isOnBlurFunction={() => { }}
                    debouncedFunction={(getValue: string) => { }}
                    handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => {
                      this.handleSelectWeekdayOptions(option.id, values.available_days, setFieldValue)
                    }}
                    emptyDataMessage={configJSON.noDataMsg}
                    handleEmptyAutoSelectValue={() => { }}
                    selectedOptionForList={availableDaysForList}
                    isError={false}
                    endAdornment={renderBaseOnConditions(!values.available_days.length, <Box position="absolute" right={16}><img src={calendarIcon} /></Box>, undefined)}
                  />
                </div>

              </div>

              <div className="dialog-actions">
                <button className="cancel-btn" onClick={() => this.handleToggleDialog()}>
                  Cancel
                </button>
                <button className="submit-btn" type="submit" data-test-id="submit-renew-btn">
                  Renew
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    )
  }

  renderDialog = () => {
    const { dialogPayload } = this.state
    switch (dialogPayload?.action) {
      case "Renew":
        return this.renderRenewForm(dialogPayload.selectedBusinessAccount as BusinessAccountRecord)
      case "View":
        return this.renderBusinessAccountStatus(dialogPayload.selectedBusinessAccount as BusinessAccountRecord)
      case "Activate":
      case "Deactivate":
      case "Done":
        return this.renderConfirmationDialog(dialogPayload)
      default:
        return null
    }
  }

  paperComponent = (props: PaperProps) => <Paper {...props} /> 
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { tableData, isLoadingTable, openImportExportPopover, openActionPopover, errorMessage, dialogPayload } = this.state;
    const open = Boolean(openImportExportPopover);
    const popoverId = open ? "simple-popover" : undefined;
    // Customizable Area End
    return (
      // Customizable Area Start
      <BusinessAcountListStyleWrapper>
        <Box className={"topHeaderBox"}>
          <ListingPageHeading>{Strings.headingTitle}</ListingPageHeading>
          <Box className={"topRightBox"}>
            <FilterBox
              data-test-id={"filterBtn"}
              className={sortStringCondition(
                this.state.isAppliedFilter,
                "active-border",
                ""
              )}
              onClick={this.openFilterPopver}
            >
              <FilterIcon color={this.handleReturnColorType()} />
              {renderBaseOnConditions(
                this.state.isAppliedFilter,
                <div className={"filterActiveIcon"} />,
                <></>
              )}
            </FilterBox>
            <FilterPopover
              data-test-id='filterPopver'
              anchor={this.state.filterAnchor}
              onClose={this.closeFilterPopover}
              filters={this.state.filters}
              onAutoCompleteChange={this.debouncedFilterSuggestion}
              onClearFilter={this.handleClearFilter}
              onFilterChange={this.handleFilterChange}
            />
            <Box>
              <LoadingComponent
                isLoading={this.state.isLoadingPermission}
                height={56}
                width={175}
                isAllowed={true} // showing on import/export permissions
                children={
                  <IconButton
                    data-test-id={`export-import-btn`}
                    id={"IconButton"}
                    aria-describedby={popoverId}
                    onClick={(event) => this.openImportExportPopover(event)}
                    className={"export-btn"}
                  >
                    {Strings.exportimportText}{" "}
                    {openImportExportPopover ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                }
              />
            </Box>
            {renderBaseOnConditions(
              true,
              <Box>
                <LoadingComponent
                  isLoading={this.state.isLoadingPermission}
                  height={56}
                  width={175}
                  isAllowed={true} // showing on import/export permissions
                  children={
                    <MainButtonComponent
                      className={"mr-0 f-weight-600"}
                      onClick={() => this.redirect("B2bBusinessAccountAdd")}
                    >
                      {Strings.addAccount}
                    </MainButtonComponent>
                  }
                />
              </Box>,
              <></>
            )}
          </Box>
        </Box>
        <Box>
          <OrderTable>
            <RowContainer header>
              <TableRow columns={"1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.9fr 1.1fr"}>
                {[
                  {
                    title: Strings.tabelString_companyName,
                    sortingKey: "company",
                  },
                  {
                    title: Strings.tabelString_subCompanyName,
                    sortingKey: "sub_company"
                  },
                  {
                    title: Strings.tabelString_customerName,
                    sortingKey: "business_customer"
                  },
                  {
                    title: Strings.tabelString_email,
                    sortingKey: "email",
                  },
                  {
                    title: Strings.tabelString_phoneNo,
                    sortingKey: "phone_no",
                  },
                  {
                    title: Strings.tabelString_catalogue,
                    sortingKey: "price_list",
                  },
                  {
                    title: Strings.tabelString_startDate,
                    sortingKey: "business_start_date",
                  },
                  {
                    title: Strings.tabelString_endDate,
                    sortingKey: "business_end_date",
                  },
                  {
                    title: Strings.tabelString_status,
                    sortingKey: "activated",
                  },
                ]
                  .filter((item: { title: string }) => {
                    return item.title;
                  })
                  .map(
                    ({
                      title,
                      sortingKey,
                    }: {
                      title: string;
                      sortingKey?: string;
                    }) => (
                      <TableCell
                        header
                        key={title}
                        content={title}
                        className={"f-16 align-item-center"}
                        sortingKey={sortingKey}
                        sortingIcons={[
                          IconSortingNormal,
                          IconSortingUp,
                          IconSortingDown,
                        ]}
                        sortingDisabled={!Boolean(sortingKey)}
                        {...this.getSortingProps()}
                      />
                    )
                  )}
              </TableRow>
            </RowContainer>
            {renderBaseOnConditions(
              false, // loading state
              <RowContainer>
                <Box className={"tableBodyLoader"}>
                  <CircularProgress />
                </Box>
              </RowContainer>,
              <RowContainer>
                {renderBaseOnConditions(
                  !tableData.length || isLoadingTable,
                  <TableRow columns="1fr" className={"table-rows"}>
                    <Box display="flex" justifyContent="center" paddingY={1}>
                      {
                        renderBaseOnConditions(
                          isLoadingTable,
                          <CircularProgress />,
                          <Typography className={"noDataFound"}>
                            {Strings.tabelString_noAccountDataFound}
                          </Typography>

                        )
                      }
                    </Box>
                  </TableRow>
                  ,
                  <>
                    {tableData.map((data) => {
                      return (
                        <TableRow
                          key={data.id}
                          className={"table-rows"}
                          columns={"1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.9fr 1.1fr"}
                        >
                          {[
                            data.company.name,
                            data.sub_company_name,
                            data.business_customer,
                            data.email,
                            data.phone_no,
                            data.price_list?.name,
                            this.formatDate(data.business_start_date),
                            this.formatDate(data.business_end_date),
                            this.handleReturnStatusColumn(data),
                          ].map((item, index) => {
                            return (
                              <TableCell
                                key={`cell-${index}`}
                                style={{ wordBreak: "break-all" }}
                                content={item as React.ReactNode}
                                className={"f-12"}
                                type={this.getTypeOfOrderTable(index)}
                              />
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </>,
                )}
              </RowContainer>
            )}
          </OrderTable>
        </Box>
        {renderBaseOnConditions(
          this.state.meta.total_pages > 1,
          <TablePagination
            onPageChange={this.handlePageChange}
            count={this.state.meta.total_count}
            component={"div"}
            data-test-id={"pagination"}
            page={this.state.meta.current_page - 1}
            rowsPerPage={this.state.rowPerPage}
            rowsPerPageOptions={[10]}
            className={"tablePaginationBox"}
          />,
          <></>
        )}
        {openActionPopover && this.getActionPopover()}
        {openImportExportPopover && this.getImportExportPopover()}
        {
          renderBaseOnConditions(
            Boolean(dialogPayload),
            <StyledDialog
              open
              onClose={() => dialogPayload?.action === "Done" ? this.handleConfirmation() : this.handleToggleDialog()}
              PaperProps={{
                className: ["Activate", "Deactivate", "Done"].includes(this.state.dialogPayload?.action as string) ? "size-sm" : undefined
              }}
            >
              <CustomLoader loading={this.state.isLoadingDialog} />
              {this.renderDialog()}
            </StyledDialog>,
            null
          )
        }
        {
          renderBaseOnConditions(
            Boolean(errorMessage),
            <CustomSnackbar
              open
              errorMessage={errorMessage}
              severity="error"
              onClose={this.handleCloseSnackbar}
            />,
            null
          )
        }
      </BusinessAcountListStyleWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const VIEW_STATUS_ROWS = [
  [
    {
      label: "Business Account Name",
      binding: "business_customer"
    },
    {
      label: "Business Account No.",
      binding: "business_account_no"
    },
  ],
  [
    {
      label: "Business Start Date",
      binding: "business_start_date"
    },
    {
      label: "Business End Date",
      binding: "business_end_date"
    },
  ],
  [
    {
      label: "Total Orders",
      binding: "total_no_of_order_for_contract"
    },
    {
      label: "Total Pieces",
      binding: "total_no_of_pieces_for_contract"
    },
  ],
  [
    {
      label: "Used Orders",
      binding: "used_orders"
    },
    {
      label: "Used Pieces",
      binding: "used_pieces"
    },
  ]
] as Array<Array<{ label: string; binding: keyof BusinessAccountRecord }>>
const BusinessAcountListStyleWrapper = styled(Box)({
  width: "100%",
  "& .topHeaderBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "17px 0 32px 0",
  },
  "& .topRightBox": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  "& .tableBodyLoader": {
    textAlign: "center",
    paddingBottom: 15,
  },
  "& .noDataFound": {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: "8px 0 12px 0",
    fontWeight: 600
  },
  "& .viewStatusButton": {
    padding: "4px 10px",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 600,
    color: colors().cyancobaltblue,
    background: "rgba(32, 75, 156, 0.1)",
    fontFamily: "Montserrat",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  "& .moreMenuButton": {
    padding: "5px",
    borderRadius: "8px",
    textAlign: "center",
    color: colors().viewTextColor,
    background: "rgba(32, 75, 156, 0.1)",
    cursor: "pointer",
  },
  "& .bottomStatusCell": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .statusLastColumn": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "12px",
  },
  "& .tablePaginationBox": {
    width: "100%",
    padding: "8px 0",
  },
  "& .export-btn": {
    width: "auto !important",
    margin: "0 10px",
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px !important",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px !important",
      width: "160px !important",
      height: "44px !important",
    },
  },
});

const ActionPopover = styled(Popover)({
  "& .noPermissions": {
    padding: "0 8px",
  },
  "& .popoverContainer": {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  "& .popoverButton": {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    paddingTop: "8px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
  },
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: 32,
    minWidth: 788,
    maxWidth: 900,
    borderRadius: 12,
    "&.size-sm": {
      minWidth: 554,
      width: 554
    },
    "& .confirmation-img": {
      width: 64,
      height: 64,
      alignSelf: "center"
    },
    "& .dialog-title": {
      textAlign: "center",
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 2,
      color: colors().viewTextColor,
      marginBottom: 24
    },
    "& .dialog-actions": {
      display: "flex",
      justifyContent: "center",
      gap: 24,
      marginTop: 24,
      "& > button": {
        padding: 16,
        borderRadius: 8,
        width: 186,
        background: colors().cyancobaltblue,
        color: "white",
        fontWeight: 600,
        fontSize: 16,
        cursor: "pointer",
        border: "none",
        "&.cancel-btn": {
          background: colors().antiFlashWhite,
          color: colors().slateGray
        }
      }
    },
    "& .dialog-text-content": {
      fontSize: 20,
      lineHeight: "28px",
      textAlign: "center"
    },
    "& .form-body": {
      display: "grid",
      gridTemplateColumns: "0.5fr 0.5fr",
      gap: 32,
      "& .MuiInputBase-root.Mui-disabled": {
        background: colors().lightborder
      },
      "& .autocomplete-field": {
        "& .MuiAutocomplete-root": {
          paddingTop: 6,
          "& .MuiOutlinedInput-root": {
            border: 0
          },
          "& .MuiInputBase-input": {
            "&::placeholder": {
              opacity: 1,
              color: colors().darkliver,
              fontSize: 14,
              fontFamily: "Montserrat",
              fontWeight: 400,
              "@media only screen and (max-width: 1024px)": {
                fontSize: 12,
              },
            },
          },
          "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors().lightborder
          },
        },
      },
      "& .date-field": {
        "& .MuiPickersBasePicker-pickerView .MuiTypography-root": {
          fontFamily: "Montserrat",
          fontSize: "0.875rem"
        },
        "& .MuiPickersCalendarHeader-transitionContainer .MuiTypography-root": {
          fontWeight: 700,
          color: colors().darkjunglegreen
        },
        "& .MuiPickersDay-daySelected": {
          border: `solid 1px ${colors().dividerColor}`,
          background: colors().brightgray,
          color: colors().cyancobaltblue
        },
        "& .MuiFormControl-root": {
          width: "100%",
          paddingTop: 6
        },
        "& .MuiInputBase-root": {
          borderWidth: 1,
          "&.Mui-error": {
            border: "1px solid red",
          }
        },
        "& .MuiInputBase-input": {
          padding: "18.5px 14px !important"
        },
        "@media only screen and (max-width: 1024px)": {
          "& .PickerWithState-root-35 div button": {
            padding: 6
          }
        },
      },
    },
    "& .view-status-dialog": {
      "& .dialog-content": {
        boxShadow: "0px 2px 8px 0px #00000014",
        padding: 16,
        borderRadius: 16
      },
      "& .status-row": {
        padding: 8,
        borderRadius: 8,
        display: "grid",
        gridTemplateColumns: "0.5fr 0.5fr",
        gap: 32,
        "&:nth-of-type(even)": {
          background: colors().ghostwhite,
          borderRadius: 8
        }
      },
      "& .row-value": {
        fontSize: 14,
        lineHeight: "22px",
        minHeight: 22,
      }
    }
  }
})
// Customizable Area End
