// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import moment from "moment";
import { FormikProps, FormikHelpers } from "formik";
import { makeApiMessage } from "../../../components/src/common";
import { difference, debounce, uniqBy, toString, groupBy, concat, isString } from "lodash";
import { conditionalString, navigateTo, sortStringCondition } from "../../utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export type Option = {
  id: string | number
  option: string
}

export type ManagerOption = Option & {
  attributes: {
    id: number
    full_name: string
    full_phone_number: string
    email: string
    regions: {
      id: number
      branch_name: string
    }[]
  }
}

type GroupItems = {
  [key: number]: Option[]
}

type GroupConfig = {
  id?: string;
  price_list: {
    "id": number,
    "name": string
  } | null,
  business_customer_group_id: number;
  product_master: { id: number; option: string }[];
  no_of_order: number | null;
  order_period_type: string;
  pieces_period_type: string;
  _destroy?: boolean;
  item_pics_configurations_attributes: Array<{
    id?: string;
    _destroy?: boolean,
    product_master_id: number,
    no_of_pieces: number | null
  }>
}

export type BusinessAccountForm = {
  id?: string
  company_id: string
  sub_company_name: string
  sub_company_id: number | null
  business_customer: string
  business_account_no: string
  business_address: string
  contract_person: string
  email: string
  phone_no: string
  cost_center: string
  sub_cost_center: string
  purchase_order: string
  sales_order: string[]
  business_start_date: string
  business_end_date: string
  contract_manager: ManagerOption | null
  region_ids: number[]
  area_ids: number[]
  store_management_ids: number[]
  auto_renewal: boolean
  price_list: {
    id: number
    name: string
  } | null
  no_of_groups: number[]
  no_of_items_per_order: number | null
  no_of_pieces_per_order: number | null
  total_no_of_order_for_contract: number | null
  order_period_type_for_contract: string
  total_no_of_pieces_for_contract: number | null
  pieces_period_type_for_contract: string
  account_group_configurations_attributes: GroupConfig[]
  business_available_times_attributes: {
    id?: number
    from: string
    to: string
  }
  available_days: string[]
}

export type DetailResponse = {
  data?: {
    id: string
    attributes: Omit<
      BusinessAccountForm,
      "company_id"
      | "account_group_configurations_attributes"
    > & {
      company: {
        id: number
        name: string
      }
      contract_manager: {
        id: string
        attributes: ManagerOption["attributes"]
      } | null
      contract_email: string
      contract_phone_no: string
      price_list: {
        id: number
        name: string
      } | null,
      business_available_times:
      {
        id: number
        start_time: string
        end_time: string
      }[]
      account_group_configurations: {
        "data":
        {
          "id": string,
          "attributes": {
            "no_of_order": number | null,
            "order_period_type": string,
            "pieces_period_type": string,
            "business_account_id": number,
            "product_master_ids": number[],
            "business_customer_group": {
              "id": number,
              "group_name": string
            },
            "price_list": {
              "id": number,
              "name": string
            } | null,
            "item_pics_configurations":
            {
              "id": string,
              "attributes": {
                "no_of_pieces": number | null,
                "product_master": {
                  "id": number,
                  "name": string
                }
              }
            }[]

          }
        }[]

      }
    }
  }
}

export type RequestBody = Omit<
  BusinessAccountForm,
  "price_list"
  | "business_available_times_attributes"
  | "no_of_groups"
  | "account_group_configurations_attributes"
  | "contract_manager"
> & {
  "business_available_times_attributes": [
    { id?: number; start_time: string, end_time: string, "_destroy"?: boolean }
  ]
  b2b_price_list_id: number | null
  contract_manager_id: number | null
  contract_region_id: number | null
  contract_manager_email: string
  contract_manager_phone_no: string
  no_of_groups: string[]
  no_of_items_per_order: number | null
  no_of_pieces_per_order: number | null
  account_group_configurations_attributes: Array<Omit<GroupConfig, "product_master" | "price_list"> & { b2b_price_list_id: number | null, product_master_ids: number[] }>
}

type DropdownResponse = { id: string; attributes: { id: number; name: string } }

const DEFAULT_FORM_VALUE: BusinessAccountForm = {
  company_id: "",
  sub_company_name: "",
  sub_company_id: null,
  business_customer: "",
  business_account_no: "",
  business_address: "",
  contract_person: "",
  email: "",
  phone_no: "",
  cost_center: "",
  sub_cost_center: "",
  purchase_order: "",
  sales_order: [],
  business_start_date: "",
  business_end_date: "",
  contract_manager: null,
  region_ids: [],
  area_ids: [],
  store_management_ids: [],
  auto_renewal: true,
  price_list: null,
  no_of_groups: [],
  no_of_items_per_order: null,
  no_of_pieces_per_order: null,
  total_no_of_order_for_contract: null,
  order_period_type_for_contract: "year",
  total_no_of_pieces_for_contract: null,
  pieces_period_type_for_contract: "year",
  account_group_configurations_attributes: [],
  business_available_times_attributes: {
    from: "",
    to: ""
  },
  available_days: [],
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: (param: string) => string | undefined
  };
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  initialFormValue: BusinessAccountForm
  companies: Option[]
  subCompanies: Option[]
  managers: ManagerOption[]
  regions: Option[]
  areas: Option[]
  stores: Option[]
  catalogues: Option[]
  groups: Option[]
  defautItems: Option[] | null
  groupItems: GroupItems
  isLoadingItems: boolean
  snackbar: {
    message: string
    severity: "error" | "success"
  } | null
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class B2bBusinessAccountAddController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  businessAcountIdParam = this.props.navigation.getParam("navigationBarTitleText")

  formRef = React.createRef<FormikProps<BusinessAccountForm>>()
  businessAccountCallId = ""
  businessAccountDetailCallId = ""
  getCompaniesCallId = ""
  getSubCompaniesCallId = ""
  getContractManagersCallId = ""
  getRegionsCallId = ""
  getAreasCallId = ""
  getStoresCallId = ""
  getPriceListsCallId = ""
  getCustomerGroupsCallId = ""
  getItemsCallId = ""
  getItemsRequestInfo: {
    groupIdsToUpdate: number[]
    groupIdsToKeep: number[]
    isDefaultItems?: boolean
  } = {
      groupIdsToUpdate: [],
      groupIdsToKeep: [],
    }
  getInitialItemsCallIdsMap = new Map<string, number[]>()
  pendingGroupItems: { [key: number]: Option[] } = {}

  catalogueQuery = ""
  cataloguePage = 1
  disableLoadMoreCatalogue = false

  contractManagerQuery = ""
  contractManagerPage = 1
  disableLoadMoreContractManager = false

  weekdayOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
    weekday => ({
      id: weekday,
      option: weekday
    })
  )

  FormSchema = Yup.object().shape({
    company_id: Yup.string().trim().required("Company name is required"),
    business_customer: Yup.string().trim().required("Business customer is required"),
    business_account_no: Yup.string().trim().required("Business account number is required"),
    business_address: Yup.string().trim().required("Business address is required"),
    email: Yup.string().email("Please enter a valid email").nullable(),
    business_start_date: Yup.string().trim().required("Business start date is required"),
    sales_order: Yup.array().of(Yup.string()).min(1, "At least one sales order is required")
  })
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      initialFormValue: { ...DEFAULT_FORM_VALUE },
      companies: [],
      subCompanies: [],
      managers: [],
      regions: [],
      areas: [],
      stores: [],
      catalogues: [],
      defautItems: null,
      groups: [],
      groupItems: {},
      isLoadingItems: false,
      snackbar: null
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCompanies()
    this.getRegions()
    this.getCatalogues()
    this.getContractManagers()

    if (this.businessAcountIdParam) {
      this.getBusinessAccountDetail(this.businessAcountIdParam)
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.receiveApiMessage(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  receiveApiMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (this.getInitialItemsCallIdsMap.has(apiRequestCallId)) {
      const customerGroupIds = this.getInitialItemsCallIdsMap.get(apiRequestCallId)
      const items = this.parseOptions(responseJson)
      customerGroupIds?.forEach((customerGroupId) => {
        this.pendingGroupItems[customerGroupId] = uniqBy(concat(items, this.pendingGroupItems[customerGroupId] || []), "id")
      })
      this.getInitialItemsCallIdsMap.delete(apiRequestCallId)
      if (this.getInitialItemsCallIdsMap.size === 0) {
        this.setState({ groupItems: this.pendingGroupItems, isLoadingItems: false })
      }
    }

    switch (apiRequestCallId) {
      case this.businessAccountCallId:
        this.receiveSubmitResponse(responseJson)
        break;
      case this.businessAccountDetailCallId:
        this.parseBusinessAccountDetail(responseJson)
        break;
      case this.getCompaniesCallId:
        this.setState({ companies: this.parseOptions(responseJson), })
        break;
      case this.getSubCompaniesCallId:
        this.setState({ subCompanies: this.parseOptions(responseJson) })
        break;
      case this.getContractManagersCallId:
        this.receiveContractManagers(responseJson)
        break;
      case this.getRegionsCallId:
        this.setState({ regions: this.parseOptions(responseJson) })
        break;
      case this.getAreasCallId:
        const areas = responseJson.data?.map(
          (item: { id: string; attributes: { id: number; area_name: string } }) => ({ id: item.attributes.id, option: item.attributes.area_name }))
          || []
        this.setState({ areas })
        break;
      case this.getStoresCallId:
        const stores = responseJson.data?.map(
          (item: { id: string; attributes: { id: number; store_name: string } }) => ({ id: item.attributes.id, option: item.attributes.store_name }))
          || []
        this.setState({ stores })
        break;
      case this.getPriceListsCallId:
        const priceLists = this.parseOptions(responseJson)
        const newPriceLists = [...this.state.catalogues, ...priceLists]
        this.disableLoadMoreCatalogue = priceLists.length < 10
        this.setState({ catalogues: uniqBy(newPriceLists, "id") })
        break;
      case this.getCustomerGroupsCallId:
        const groups = responseJson.data?.map(
          (item: { attributes: { id: number; group_name: string } }) => ({ id: item.attributes.id, option: item.attributes.group_name }))
          || []
        this.setState({ groups })
        break;
      case this.getItemsCallId:
        const items = this.parseOptions(responseJson)
        const newGroupItems: GroupItems = {}
        const groupItems = this.state.groupItems || {}
        this.getItemsRequestInfo.groupIdsToKeep.forEach((groupId) => {
          newGroupItems[groupId] = groupItems[groupId]
        })
        this.getItemsRequestInfo.groupIdsToUpdate.forEach((groupId) => {
          const currentOptions = this.state.groupItems[groupId] || []
          newGroupItems[groupId] = uniqBy([...currentOptions, ...items], "id")
        })
        this.setState({
          isLoadingItems: false,
          groupItems: newGroupItems,
          defautItems: this.getItemsRequestInfo.isDefaultItems ? items : this.state.defautItems
        })
        break;
      default:
        break;
    }
  }

  parseOptions = (responseJson: { data?: DropdownResponse[] }) =>
    responseJson?.data?.map(item => ({ id: item.attributes.id, option: item.attributes.name })) || []

  getDropdownOptions = (
    apiCallId: "getCompaniesCallId"
      | "getSubCompaniesCallId"
      | "getContractManagersCallId"
      | "getRegionsCallId"
      | "getAreasCallId"
      | "getStoresCallId"
      | "getPriceListsCallId"
      | "getCustomerGroupsCallId"
      | "getItemsCallId",
    endPoint: string
  ) => {
    const message = makeApiMessage({
      url: endPoint,
      method: "GET"
    })
    this[apiCallId] = message.messageId
    this.send(message)
  }

  getCompanies = () => {
    this.getDropdownOptions("getCompaniesCallId", configJSON.apiEndPoints.b2bCompaniesList)
  }

  getSubCompanies = (companyId: string) => {
    this.getDropdownOptions("getSubCompaniesCallId", configJSON.apiEndPoints.b2bSubCompaniesList + companyId)
  }

  getContractManagers = () => {
    this.disableLoadMoreContractManager = true
    const paginationQuery = `?page_no=${this.contractManagerPage}`
    const nameQuery = sortStringCondition(this.contractManagerQuery.length > 0, "&name=" + this.contractManagerQuery, "")
    this.getDropdownOptions("getContractManagersCallId", configJSON.apiEndPoints.contractManagerList + paginationQuery + nameQuery)
  }

  getRegions = () => {
    this.getDropdownOptions("getRegionsCallId", configJSON.apiEndPoints.regionList)
  }

  getAreas = (regionIds: number[]) => {
    if (regionIds.length) {
      this.getDropdownOptions("getAreasCallId", configJSON.apiEndPoints.areaList + regionIds.join(","))
    } else {
      this.setState({ areas: [], stores: [] })
    }
  }

  getStores = (areaIds: number[]) => {
    if (areaIds.length) {
      this.getDropdownOptions("getStoresCallId", configJSON.apiEndPoints.b2bStoresList + areaIds.join(","))
    } else {
      this.setState({ stores: [] })
    }
  }

  getCatalogues = () => {
    this.disableLoadMoreCatalogue = true
    const paginationQuery = sortStringCondition(this.cataloguePage > 1, `&page_no=${this.cataloguePage}`, "")
    const nameQuery = sortStringCondition(this.catalogueQuery.length > 0, "&filter_by[query]=" + this.catalogueQuery, "")
    this.getDropdownOptions("getPriceListsCallId", configJSON.apiEndPoints.priceList + paginationQuery + nameQuery)
  }

  getSingleDropdownOptions = (
    selectedOption: { id: number | string; name: string; attributes?:  ManagerOption["attributes"]} | null, 
    optionList: { id: number | string; option: string; attributes?:  ManagerOption["attributes"] }[]
  ) => {
    if (
      !selectedOption
      || (
        selectedOption && optionList.some(catalogue => catalogue.id === selectedOption.id)
      )
    ) return optionList
    return [...optionList, { id: selectedOption.id, option: selectedOption.name, attributes: selectedOption.attributes }]
  }

  getCustomerGroups = (company_id: string) => {
    this.getDropdownOptions("getCustomerGroupsCallId", configJSON.apiEndPoints.b2bCustomerGroups + company_id)
  }

  getItems = (
    priceListId: number,
    groupIdsToUpdate: number[],
    selectedCustomerGroupIds: number[],
    shouldLoading: boolean,
    page = 1,
    queryText = "",
  ) => {
    const defaultPriceListId = this.formRef.current?.values.price_list?.id
    const pageQuery = sortStringCondition(page > 1, `&page_no=${page}`, "")
    const filterByQuery = sortStringCondition(Boolean(queryText), `&filter_by[name]=${queryText}`, "")
    const queryString = pageQuery + filterByQuery
    if (priceListId === defaultPriceListId && this.state.defautItems && !queryString) {
      const options = this.state.defautItems
      const groupItems: GroupItems = {}
      groupIdsToUpdate.forEach(groupId => {
        groupItems[groupId] = options
      })
      this.setState((prev) => ({ groupItems: { ...prev.groupItems, ...groupItems } }))
      return;
    }
    this.setState({ isLoadingItems: shouldLoading })
    this.getItemsRequestInfo = {
      groupIdsToUpdate,
      groupIdsToKeep: difference(selectedCustomerGroupIds, groupIdsToUpdate),
      isDefaultItems: priceListId === defaultPriceListId && !queryString
    }
    this.getDropdownOptions("getItemsCallId", configJSON.apiEndPoints.productsList + priceListId + queryString)
  }

  getInitialItems = (groupConfigs: BusinessAccountForm["account_group_configurations_attributes"]) => {
    if (!groupConfigs.length) return;
    const groupConfigsByPriceList = groupBy(groupConfigs.filter(groupConfig => groupConfig.price_list?.id), (groupConfig) => groupConfig.price_list?.id)
    if (Object.keys(groupConfigsByPriceList).length) {
      this.setState({ isLoadingItems: true })
      Object.entries(groupConfigsByPriceList).forEach(([priceListId, configs]) => {
        const apiMessage = makeApiMessage({
          url: configJSON.apiEndPoints.productsList + priceListId,
          method: configJSON.httpGetMethod
        })
        this.getInitialItemsCallIdsMap.set(apiMessage.messageId, configs.map(item => item.business_customer_group_id))
        this.send(apiMessage)
      })
    }
  }

  getBusinessAccountDetail = (businessAcountId: string) => {
    const apiMessage = makeApiMessage({
      method: configJSON.httpGetMethod,
      url: configJSON.apiEndPoints.businessAccount + `/${businessAcountId}`
    })
    this.businessAccountDetailCallId = apiMessage.messageId
    this.send(apiMessage)
  }

  handleChangeCompany = (value: { id: string; option: string }) => {
    this.setState({ subCompanies: [] }, () => {
      this.getSubCompanies(value.id)
      this.getCustomerGroups(value.id)
    })
  }

  handleChangeMultipleSelectDropdown = (option: Option, allOptions: Option[], fieldName: keyof BusinessAccountForm) => {
    const updateCallbackObject: {
      [key: string]: (
        newValues: Array<string | number>,
        setFieldValue: FormikHelpers<BusinessAccountForm>["setFieldValue"],
        values: BusinessAccountForm
      ) => void,
    } = {
      "region_ids": (newRegionIds, setFieldValue) => {
        setFieldValue("area_ids", [])
        setFieldValue("store_management_ids", [])
        this.setState({ areas: [], stores: [] }, () => this.getAreas(newRegionIds as number[]))
      },
      "area_ids": (newAreaIds, setFieldValue) => {
        setFieldValue("store_management_ids", [])
        this.setState({ stores: [] }, () => this.getStores(newAreaIds as number[]))
      },
      "no_of_groups": (customerGroupIds, setFieldValue, values) => {
        const newCustomerGroupIds = customerGroupIds as number[]
        const { account_group_configurations_attributes, price_list } = values
        const newCustomerGroupConfigs: GroupConfig[] = newCustomerGroupIds.map(
          customerGroupId => {
            const currentGroupConfig = account_group_configurations_attributes.find(groupConfig => groupConfig.business_customer_group_id === customerGroupId)
            return currentGroupConfig || {
              price_list,
              business_customer_group_id: customerGroupId,
              product_master: [],
              product_master_ids: [],
              no_of_order: null,
              order_period_type: "year",
              pieces_period_type: "year",
              item_pics_configurations_attributes: []
            }
          })
        if (
          newCustomerGroupConfigs.length > account_group_configurations_attributes.length
          && values.price_list
        ) {

          this.getItems(
            values.price_list.id,
            newCustomerGroupIds
              .filter(customerGroupId => account_group_configurations_attributes.every(item => item.business_customer_group_id !== customerGroupId)),
            newCustomerGroupIds,
            true
          )
        }
        setFieldValue("account_group_configurations_attributes", newCustomerGroupConfigs)
      },
    }
    this.handleSelectOptions(option, allOptions, fieldName, updateCallbackObject[fieldName])
  }

  handleChangeCatalogue = (value: { id: number; option: string } | null, setFieldValue: (fieldName: string, fieldValue: BusinessAccountForm["price_list"]) => void) => {
    setFieldValue("price_list", value ? { id: value.id, name: value.option } : null)
    this.setState({ defautItems: null })
  }

  handleSelectOptions = (
    option: Option, allOptions: Option[],
    fieldName: keyof BusinessAccountForm,
    onUpdate?: (
      newValues: Array<string | number>,
      setFieldValue: FormikHelpers<BusinessAccountForm>["setFieldValue"],
      values: BusinessAccountForm
    ) => void
  ) => {
    if (!this.formRef.current) return;
    const values = this.formRef.current.values[fieldName] as Array<string | number>
    let newValues: Array<string | number> = []
    if (option.id === "-1") {
      if (allOptions.length && values.length < allOptions.length) {
        newValues = allOptions.map(item => item.id) as number[]
      }

    } else {
      const isSelected = values.includes(option.id)
      newValues = isSelected ? values.filter((selected) => selected != option.id) : [...values, option.id]
    }
    this.formRef.current.setFieldValue(fieldName, newValues)
    onUpdate?.(newValues, this.formRef.current.setFieldValue, this.formRef.current.values)
  }

  handleUpdateGroup = <
    K extends keyof GroupConfig,
    S extends keyof GroupConfig,
  >(
    groupConfig: GroupConfig,
    fieldName: K,
    fieldValue: GroupConfig[K],
    secondFieldName?: S,
    secondFieldValue?: GroupConfig[S]
  ) => {
    if (this.formRef.current) {
      const { account_group_configurations_attributes, no_of_groups } = this.formRef.current.values
      const configIndex = account_group_configurations_attributes.findIndex(item => item === groupConfig)
      const newCustomerGroupConfigs: GroupConfig[] = [...account_group_configurations_attributes]
      newCustomerGroupConfigs[configIndex] = { ...account_group_configurations_attributes[configIndex] }
      const { business_customer_group_id } = newCustomerGroupConfigs[configIndex]
      newCustomerGroupConfigs[configIndex][fieldName] = fieldValue
      if (secondFieldName && secondFieldValue) {
        newCustomerGroupConfigs[configIndex][secondFieldName] = secondFieldValue
      }
      if (fieldName === "price_list") {
        newCustomerGroupConfigs[configIndex].product_master = []
        newCustomerGroupConfigs[configIndex].item_pics_configurations_attributes = []
        this.setState(prev => ({
          groupItems: {
            ...prev.groupItems,
            [business_customer_group_id]: []
          }
        }), () => this.getItems(
          (fieldValue as BusinessAccountForm["price_list"])?.id as number,
          [business_customer_group_id],
          no_of_groups,
          true
        ))
      }

      if (fieldName === "product_master") {
        const { item_pics_configurations_attributes } = newCustomerGroupConfigs[configIndex]
        const newItemWithPiecesConfig: GroupConfig["item_pics_configurations_attributes"] = (fieldValue as { id: number; option: string }[]).map(
          (productMaster) => {
            const currentItemWithPiecesConfig = item_pics_configurations_attributes.find(itemWithPieces => itemWithPieces.product_master_id === productMaster.id)
            return currentItemWithPiecesConfig || {
              product_master_id: productMaster.id,
              no_of_pieces: null
            }
          }
        )
        newCustomerGroupConfigs[configIndex].item_pics_configurations_attributes = newItemWithPiecesConfig
      }

      this.formRef.current.setFieldValue("account_group_configurations_attributes", newCustomerGroupConfigs)
    }
  }

  handleScrollManagerDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreContractManager) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.contractManagerPage++
      this.getContractManagers()
    }
  };

  handleManagerAutoCompleteChange = (getValue: string) => {
    if (getValue === this.contractManagerQuery) return;
    this.contractManagerQuery = getValue
    this.contractManagerPage = 1
    if (getValue.length < 1 || getValue.length > 2) {
      this.getContractManagers()
    }
  }

  handleScrollCatalogueDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreCatalogue) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.cataloguePage++
      this.getCatalogues()
    }
  };

  handleCatalogueAutoCompleteChange = (getValue: string) => {
    if (getValue === this.catalogueQuery) return;
    this.catalogueQuery = getValue
    this.cataloguePage = 1
    if (getValue.length < 1 || getValue.length > 2) {
      this.getCatalogues()
    }
  }

  handleBlurCatalogueDropdown = () => {
    this.catalogueQuery = ""
    this.cataloguePage = 1
    this.disableLoadMoreCatalogue = false
  }

  debouncedFunction = debounce(
    (newInputValue: string, handler: (inputValue: string) => void) => handler(newInputValue),
    700,
    { maxWait: 2000 }
  );

  parseBusinessAccountDetail = (response: DetailResponse) => {
    if (!response.data) {
      this.setState({
        snackbar: {
          message: "unable to fetch business account detail",
          severity: "error"
        }

      })
      return;
    }
    const responseData = response.data.attributes
    const {
      company,
      phone_no,
      contract_manager,
      contract_email,
      contract_phone_no,
      business_available_times,
      account_group_configurations,
      ...rest
    } = responseData;
    const formValue: BusinessAccountForm = {
      ...rest,
      company_id: toString(responseData.company.id),
      phone_no: phone_no?.startsWith("966") ? phone_no.substring(3) : phone_no,
      business_available_times_attributes: {
        id: business_available_times[0]?.id,
        from: this.getTime(business_available_times[0]?.start_time),
        to: this.getTime(business_available_times[0]?.end_time)
      },
      contract_manager: contract_manager ? {
        ...contract_manager,
        option: contract_manager.attributes.full_name,
      } : null,
      no_of_groups: account_group_configurations.data.map(
        groupConfig => groupConfig.attributes.business_customer_group.id
      ),
      account_group_configurations_attributes: account_group_configurations.data.map(
        groupConfig => {
          const { attributes } = groupConfig
          return ({
            ...attributes,
            id: groupConfig.id,
            business_customer_group_id: attributes.business_customer_group.id,
            item_pics_configurations_attributes: attributes.item_pics_configurations.map(
              itemWithPieces => ({
                id: itemWithPieces.id,
                product_master_id: itemWithPieces.attributes.product_master.id,
                no_of_pieces: itemWithPieces.attributes.no_of_pieces
              })
            ),
            product_master: attributes.item_pics_configurations.map(
              itemWithPieces => ({ id: itemWithPieces.attributes.product_master.id, option: itemWithPieces.attributes.product_master.name })
            )
          })
        }
      )
    }
    formValue.account_group_configurations_attributes.forEach(
      groupConfig => {
        this.pendingGroupItems[groupConfig.business_customer_group_id] = groupConfig.product_master
      }
    )

    this.setState({ initialFormValue: formValue }, () => {
      this.getSubCompanies(formValue.company_id)
      this.getAreas(formValue.region_ids)
      this.getStores(formValue.area_ids)
      this.getCustomerGroups(formValue.company_id)
      this.getInitialItems(formValue.account_group_configurations_attributes)
    })
  }

  getGroupConfigForRequest = (groupConfig: GroupConfig) => {
    const { price_list, ...rest } = groupConfig
    return ({
      ...rest,
      b2b_price_list_id: price_list?.id || null,
      product_master_ids: groupConfig.product_master.map(product => Number(product.id)),
    })
  }

  receiveContractManagers = (responseJson: {
    data?: Array<
      {
        id: string
        attributes: ManagerOption["attributes"]
      }
    >
  }) => {
    const contractManagers: ManagerOption[] = responseJson.data?.map(
      (
        item => ({ id: item.id, option: item.attributes.full_name, attributes: item.attributes }))
    )
      || []
    this.disableLoadMoreContractManager = contractManagers.length < 10
    this.setState((prev) => ({
      managers: uniqBy(
        this.contractManagerPage === 1 ? contractManagers : concat(prev.managers, contractManagers),
        "id"
      )
    }))
  }

  handleSubmit = (formValues: BusinessAccountForm) => {
    runEngine.debugLog("Business Account Form Data", formValues)
    const {
      phone_no,
      contract_manager,
      business_available_times_attributes,
      no_of_groups,
      account_group_configurations_attributes,
      price_list,
      ...rest
    } = formValues
    const requestBody: RequestBody = {
      ...rest,
      b2b_price_list_id: price_list?.id || null,
      contract_manager_id: contract_manager?.attributes.id || null,
      contract_region_id: null,
      contract_manager_email: toString(contract_manager?.attributes.email),
      contract_manager_phone_no: toString(contract_manager?.attributes.full_phone_number),
      phone_no: conditionalString(phone_no.trim() , "966" + toString(phone_no), ""),
      no_of_groups: no_of_groups.map(selectedGroupId => toString(this.state.groups.find(group => group.id === selectedGroupId)?.option)).filter(Boolean),
      account_group_configurations_attributes: account_group_configurations_attributes.map(
        this.getGroupConfigForRequest
      ),
      business_available_times_attributes: [
        {
          id: business_available_times_attributes.id,
          start_time: business_available_times_attributes.from,
          end_time: business_available_times_attributes.to,
          _destroy: !business_available_times_attributes.from || !business_available_times_attributes.to
        }
      ]
    }
    const initialGroupConfigs = this.state.initialFormValue.account_group_configurations_attributes

    initialGroupConfigs.forEach(oldItem => {
      const currentIndex = requestBody.account_group_configurations_attributes
        .findIndex(groupConfig => groupConfig.business_customer_group_id === oldItem.business_customer_group_id)
      if (currentIndex < 0) {
        requestBody.account_group_configurations_attributes.push({
          ...oldItem,
          product_master_ids: [],
          b2b_price_list_id: oldItem.price_list?.id || null,
          _destroy: true
        })
      } else {
        const currentConfig = requestBody.account_group_configurations_attributes[currentIndex]
        currentConfig.id = oldItem.id
        oldItem.item_pics_configurations_attributes.forEach(
          oldItemWithPiecesConfig => {
            const currentItemWithPiecesIndex = currentConfig.item_pics_configurations_attributes.findIndex(element => element.product_master_id === oldItemWithPiecesConfig.product_master_id)
            if (currentItemWithPiecesIndex < 0) {
              currentConfig.item_pics_configurations_attributes.push({
                ...oldItemWithPiecesConfig,
                _destroy: true
              })
            } else {
              currentConfig.item_pics_configurations_attributes[currentItemWithPiecesIndex].id = oldItemWithPiecesConfig.id
            }

          }
        )
      }
    })

    const url = configJSON.apiEndPoints.businessAccount + (this.businessAcountIdParam ? `/${this.businessAcountIdParam}` : "")
    const apiMessage = makeApiMessage({
      url,
      method: this.businessAcountIdParam ? "PUT" : "POST",
      body: JSON.stringify({
        data: requestBody
      })
    })
    this.businessAccountCallId = apiMessage.messageId
    this.send(apiMessage)
  }

  receiveSubmitResponse = (responseJson: { data?: string | Object, full_messages?: string[] }) => {
    if (responseJson.data) {
      this.setState({
        snackbar: {
          severity: "success",
          message: sortStringCondition(isString(responseJson.data), responseJson.data as string, "Account Edited Successfully")
        }
      })
    }
    if (responseJson.full_messages?.length) {
      this.setState({
        snackbar: {
          severity: "error",
          message: responseJson.full_messages[0]
        }
      })
    }
  }

  getTime = (inputString: string) => {
    if (!inputString) return "";
    const parsedTime = moment.parseZone(inputString);
    return parsedTime.format("HH:mm")
  }


  redirect = () => navigateTo({
    props: this.props,
    screenName: "B2bBusinessAccountList"
  })

  closeSnackbar = () => {
    if (this.state.snackbar?.severity === "success") {
      this.redirect()
    }
    this.setState({ snackbar: null })
  }
  // Customizable Area End
}