import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Dialog,
  styled,
  Select,
  Snackbar,
  Collapse,
  MenuItem,
  Tab,
  Tabs,
  DialogContent,
  TablePagination,
  TextareaAutosize,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Paper,
  Grid,
  Checkbox,
  DialogActions,
  StyledComponentProps,
  OutlinedInput,
  Portal,
  withStyles,
  Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { isUndefined } from "lodash";
import FilterIcon from "@material-ui/icons/FilterList";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { Alert } from "@material-ui/lab";
import {
  CustomTab,
  CustomTabs,
} from "../../../components/src/customComponents/CustomTags.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import PageConatinerSearchableMultiSelect from "../../../components/src/PageConatinerSearchableMultiSelect.web"

import {
  StyledSelect,
  StyledMenuItem,
  BottomContainer,
} from "../../promocodes/src/PromoCodeCreate.web";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import BarcodesScanner from "../../BarcodesScanner/src/BarcodesScanner.web";
import { MenuProps } from "../../email-account-registration/src/AccountCreationController.web";
import EditPreference from "../../../blocks/ProductDescription/src/EditPreference.web";
import { StyledRadio } from "../../../components/src/customComponents/RadioDropdown.web";
import CatalogueCard from "../../../blocks/catalogue/src/CatalogueCard.web";
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import {
  RadioCheckedIcon,
  RadioUnCheckedIcon,
  CheckTrue,
  CheckFalse,
  IconBluePrint,
  AddProductIcon,
  RemoveProductIcon,
  DeleteProductIcon,
  SampleProductItemImage,
  IconOrderDetailsCircle,
  creditNoteIcon,
  CircleIcon,
  MinusLightButton,
  NoImagePlaceholder,
  UncheckedBoxImg,
  DropdownChecked,
  DropdownUnchceked,
} from "./assets";
import {
  showNotes,
  DetailsButton,
  NotesTooltip,
} from "../../advancedsearch/src/AdvancedSearch.web";

import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import {
  IChargeListItem,
} from "../../ordermanagement/src/OrderDetailsController.web";
import { IFormValues, IService } from "../../OrderCreation/src/OrderCreationController.web";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { createTheme } from "@material-ui/core/styles";
import { colors } from "../../utilities/src/Colors";
import { ExpandLess, ExpandMore, HelpOutlined } from "@material-ui/icons";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { CustomMenuProps } from "../../ProductDescription/src/HomeCleaningSettingsList.web";
import PreferenceItem from "../../../components/src/customComponents/PreferenceItem.web";
import SameProductDifferentServiceModal from "../../../components/src/customComponents/SameProductDifferentServiceModal.web";
import {
  UniqueCombination,
  IAdjustCartProduct,
  productTypes,
} from "./utils";
import {
  CatalogueList as CatalogueDataList,
  IItemData,
  Icatalouge,
  OrderItemPreferences
} from "../../../components/src/Interface";
import { MainButton } from "../../OrderCreation/src/OrderCreation.web";
import CarpetAndFurnitureProductDialogue from "../../../components/src/commonComponents/CarpetAndFurnitureProductDialogue";

const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

interface ITabListItem {
  label: string;
  apiKey: string;
  dropId: number;
  tabId: number;
  showDropItems: Array<number>
  permitted?: boolean;
  actionPermitted?: boolean
}

// Customizable Area End

import Cfplantdashboard2Controller, {
  // Customizable Area Start
  Props,
  configJSON,
  S,
  IOption,
  IDataItem,
  IOrderItem,
  // Customizable Area End
} from "./Cfplantdashboard2Controller";

export default class Cfplantdashboard2 extends Cfplantdashboard2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      searchQuery: "",
      adjustmentList: [],
      isLoadingOrderItems: false,
      isScanningOrder: false,
      isLoadingSharedFolder: false,
      orderItems: [],
      itemsToPrint: [],
      orderData: null,
      snackbarMessage: "",
      selectedOrders: [],
      selectedOption: null,
      orderList: [],
      meta: {
        total_pages: 1,
        total_count: 10,
        current_page: 1,
      },
      selectedBranches: [],
      branchList: [],
      selectedTab: 0,
      filterAnchor: undefined,
      selectedDate: null,
      suggestionFieldTitle: "",
      sortingQuery: "",
      sortingData: {
        order_number: "",
        created_at: "",
        customer: "",
      },
      regionId:null,
      isPrintShow: false,
      showResendButton: false,
      selectedAdjustmentReason: "",
      adjustmentComment: "",
      adjustmentError: false,
      isUpdateStatusLoading: false,
      adjustMentErrorModal: false,
      isPlantLoading: false,
      isOrderListLoading: false,
      filters: [
        {
          title: "Order ID",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Customer",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Date",
          type: "datepicker",
          value: ""
        },
        {
          title: "Section",
          value: "",
          type: "multipleSelect",
          options: [],
        },
      ],
      isDrawerOpen: false,
      plantSection: "",
      changeProductModal: false,
      adjustmentOrderModal: false,
      adjustmentRequestTypeSub: "",
      isCreditNoteDialogOpen: false,
      adjustmentAmount: "",
      taxAmount: "",
      adjustmentReasonId: "",
      addNote: "",
      isConfirmationChecked: true,
      adjustmentRequestType: undefined,
      editOrderPopupFromAdjustment: false,
      reviewOrderPopupFromAdjustment: false,
      adjustOrder: null,
      adjustOrignleOrder: null,
      cartTotalOpen: false,
      adjustOrderItems: [],
      adjustmentCancelOrderModal: false,
      adjustmentSelectUpChargeModal: false,
      adjustmentCancelOrderMessage: "",
      orignalOrderId: null,
      userData: null,
      cataloguesDataList: [],
      cataloguesListMeta: {
        total_pages: 0,
        total_count: 0,
        current_page: 1,
        next_page: undefined,
        pervious_page: undefined,
      },
      tabsId: 0,
      currentPage: 1,
      adjustCartProduct: [],
      preferenceData: null,
      preferenceModalVisible: false,
      selectedService: null,
      orderUpChargePreference: [],
      palntPermissionStatus:{
        storeAccessPermission: false,
        storeActionPermission: false,
        receivingAreaAccessPermission: false,
        WashPressingAccessPermission: false,
        shortingAreaAccessPermission:false,
        mainPermission: false,
        loadingAreaAccessPermission:false,
        dispatchedAccessPermission:false,
        adjustmentAccessPermission:false,
        receivingAreaActionPermission:false,
        washAndPressingActionPermission:false,
        shortingAreaActionPermission:false,
        loadingAreaActionPermission:false,
        dispatchedActionPermission:false,
        adjustPermission:false,
        adjustmentActionPermission:false,
        creditNotePermission:false,
        cleanOrderActionPermission: false
      },
      sameProductDifferentServiceModalOpen: false,
      isIncreaseBtnClicked: false,
      clickedProductCardInfo: null,
      originalAdjustOrderId: "",
      subProductModal: false,
      clickedParentCatalougeId: null,
      adjustmentOrignalOrderNumber: "",
      cartsItemsPreferencesData: [],
      storeManagementDetails: null,
      creditNoteReceipt: "",
      productPopup: false,
      carpetFurnitureProductPopupData: null,
      selectedCatalogueData: null,
      creditNoteOrder: undefined,
      creditNotePresent: false,
      hidden: true,
      isPopuDisabledWithOrderCreate: false,
      isPermittedPage: false,
      searchCatalogue: "",
      isCatalogueCardLoading: true,
      catalogueListEmptyFlag: false,
      customerPriceListId: null,
      snackBarSeverity: 'error',
      sectionList: [],
      modalOrderItems: [],
      creditLoader: false,
      creditNoteOrderNumber: null,
      openCrediNoteModalLoader: false,
      isAutoSend: false,
      selectAllStoreId: undefined,
      searchTextQuery: "",
      isAppliedFilter: false
    } as S;
    // Customizable Area End
  }

  // Customizable Area Start  

  showOrder(
    order: IOrderItem[],
    openOrderDetails: Function,
    isQuickDrop: boolean = false,
  ) {
    if (Boolean(isQuickDrop)) {
      return (
        <div>
          {order
            .map((item: IOrderItem) => Number(item.qty))
            .reduce(
              (previous: number, current: number) => previous + current,
              0
            )}
        </div>
      );
    } else if (order.length === 0) {
      return "--";
    } else {
      return (
        <div>
          {order.map((item: IOrderItem, index: number) => {
            return (
              <div key={item.name + index}>
                {item.name}{" "}
                <img
                  style={{ margin: "0 4px" }}
                  src={CircleIcon}
                  alt='circle'
                />{" "}
                {item.second_name} ({item.service_short_name || "--"}) x{" "}
                {item.qty}
                <div>
                  {Number(item?.height) !== 0 && Number(item?.width) !== 0  && (<>length * width: <div>{item.height} m * {item.width} m</div> </>)}
                </div>
                <div>
                  {Number(item?.weight) !== 0 && (<>weight : {item.weight} Kg</>)}
                </div>
              </div>
            );
          })}
          <DetailsButton
            className='details-button'
            data-test-id='btn-orderDetails'
            onClick={() => openOrderDetails()}
            component={"div"}
          >
            {configJSON.btn_details_text}
          </DetailsButton>
        </div>
      );
    }
  }

  getMultiSelectRenderValue(items: unknown) {
    const updatedLabels = this.handleUpdatedLabels(items);
    return !updatedLabels ? (
      <span className='color_4D4D4D'>
        {configJSON.stringsListPage_select_store}
      </span>
    ) : (
      updatedLabels
    );
  }

  renderSpecificationInTableRow = (order_item_preferences: OrderItemPreferences[]) => {
    const results = this.getSpecifications(order_item_preferences);
    if(Object.keys(results).length > 0) {
      return(
        <ShowPreferenceWrapper className='specification-data-show'>
          {Object.keys(results).map((objKey) => {
            return (
              <React.Fragment key={objKey}>
                <span className='slash-span'>/</span>
                <span className='specification-span-tag'>
                  {`${results[objKey].join(" / ")}`}
                </span>
              </React.Fragment>
            );
          })}
        </ShowPreferenceWrapper>
      )
    } else {
      return "---"
    }
  }

  renderOrderDetails() {
    let pcsIndexNumber = 1;
    const {
      orderData,
      orderItems,
      adjustmentList,
      adjustmentError,
      isLoadingOrderItems,
      isPrintShow,
      itemsToPrint,
      modalOrderItems,
      isUpdateStatusLoading
    } = this.state;
    const {
      total,
      id: varID,
      store_name,
      total_items,
      order_number,
      total_pieces,
      parent_order,
      no_of_items,
      no_of_pieces,
      notes,
      customer_complaint
    } = orderData?.attributes || {};
    const isSelectAllToPrint = this.isSelectAllToPrint()
    const totalPieces = isUndefined(no_of_pieces) ? total_pieces : no_of_pieces

    return (
      <OrderDetailsDialog
        data-test-id="order-details-dialog"
        maxWidth={"md"}
        open={orderData ? true : false}
        fullWidth
        onClose={() => this.onCloseDetailsModal()}
      >
        <DialogContent className='p-24'>
          <CustomLoader loading={isLoadingOrderItems || isUpdateStatusLoading}/>
          <StyledWrapper>
            <OrderDetailsHeaderContainer>
              <OrderDetailsHeadingText>
                <div>
                  {configJSON.stringsListPage_table_storeName} {store_name}
                </div>
                <div>
                  {configJSON.stringsListPage_table_orderId} {order_number}
                </div>
                <div>
                  {configJSON.stringsListPage_table_totalItems} {isUndefined(no_of_items) ? total_items : no_of_items}
                </div>
                <div>
                  {configJSON.stringsListPage_table_totalPcs} {totalPieces}
                </div>
              </OrderDetailsHeadingText>
              <OrderDetailsHeadingText className="right-column">
                <div>
                  {configJSON.stringsListPage_table_total} SAR {total}
                </div>
                {parent_order?.order_number && (
                  <div>
                    {configJSON.stringsListPage_table_parentOrderId} {parent_order?.order_number}
                  </div>
                )}
                {<ul className="product-qty">
                  {
                    modalOrderItems.map((item) => {
                      return (
                        <li>
                          <p>{item.attributes.catalogue.product_name} :</p>
                          <p>{item.attributes.quantity}</p>
                        </li>
                      )
                    })
                  }
                </ul>}
              </OrderDetailsHeadingText>
            </OrderDetailsHeaderContainer>
            <Box className='p-16' />
            <BottomContainer className='bottom-container'>
              {
                renderBaseOnConditions(
                  orderData?.attributes.status_plant === "receiving_area",
                  <button
                    className='cancel mw-105'
                    data-test-id='btn-print'
                    onClick={() => this.handleClickPrint(true)}
                  >
                    <Box className='printIcon2'>
                      <img src={IconBluePrint} />
                      {configJSON.stringsListPage_print}
                    </Box>
                  </button>,
                  null
                )
              }
              {
                renderBaseOnConditions(
                  isPrintShow,
                  <button
                    className='cancel mw-105'
                    data-test-id='btn-print-cancel'
                    onClick={() => this.handleClickPrint()}
                  >
                    <Box className='printIcon2'>
                      <img src={IconBluePrint} />
                      {configJSON.stringsListPage_table_cancel}
                    </Box>
                  </button>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(this.getCurrentSelectedTab().actionPermitted,
                  <>
                    <button
                      data-test-id="btn-submit-top"
                      className={this.moveToNextButtonClasses()}
                      disabled={!this.isShowNextLabel()}
                      onClick={() => this.moveToNextPlantStatus(varID as number)}
                    >
                       {configJSON.stringsListPage_table_moveToNext}
                    </button>
                    {
                      renderBaseOnConditions(
                        this.isShowNextLabel(),
                        <div className="move-to-next-top" data-test-id={configJSON.moveToNextLabelTestId}>
                          {this.renderNextStatusLable()}
                        </div>,
                        null
                      )
                    }
                  </>,
                  null
                )
              }
            </BottomContainer>
            <Box className='p-16' />

            <OrderTable>
              <RowContainer header>
                <TableRow columns="2fr 1fr 1fr 1fr 2fr 2fr 3fr" className={this.getTableRowClassesForOrderDetails()}>
                  {this.state.isPrintShow && (
                    <TableCell
                      header
                      type='none'
                      data-test-id='selectAllCheckbox'
                      className='pl-0 align-item-center'
                      content={
                        <img
                          data-test-id='selectAll'
                          className='cursor-pointer'
                          onClick={() => this.handleSelectAllItemsToPrint()}
                          src={this.getConditionBased(isSelectAllToPrint, CheckTrue, CheckFalse) as string}
                        />
                      }
                    />
                  )}
                  {[
                    configJSON.stringsListPage_table_item,
                    configJSON.stringsListPage_table_service,
                    configJSON.stringsListPage_table_pcs,
                    configJSON.stringsListPage_table_id,
                    configJSON.stringsListPage_table_tag,
                    configJSON.stringsListPage_table_notes,
                    "Specification"
                  ].map((heading: string) => {
                    return (
                      <TableCell
                        header
                        content={heading}
                        className='align-item-center'
                      />
                    );
                  })}
                </TableRow>
              </RowContainer>
              <RowContainer>
                {orderItems.map((item: IOrderItem, itemIndex) => {
                  const {
                    notes,
                    catalogue,
                    tag_numbers,
                    tag_image,
                    order_item_preferences,
                    service
                  } = item.attributes;
                  
                  const counterForTag = pcsIndexNumber;
                  pcsIndexNumber = pcsIndexNumber + 1;
                  if(counterForTag === totalPieces) {
                    pcsIndexNumber = 1
                  }
                  return (
                    <TableRow
                      columns="2fr 1fr 1fr 1fr 2fr 2fr 3fr" 
                      className={this.getTableRowClassesForOrderDetails()}
                    >
                      {this.state.isPrintShow && (
                        <TableCell
                          type='none'
                          className='pl-0'
                          content={
                          renderBaseOnConditions(
                            tag_image.length > 0,
                            <img
                              data-test-id={`checkbox-${itemIndex}`} 
                              src={this.getConditionBased(itemsToPrint.includes(item), CheckTrue, CheckFalse) as string}
                              onClick={() => this.handleSelectItemToPrint(item)} 
                            />,
                            <div />
                          )}
                        />
                      )}
                      {[
                        <Box className='catalogue_name'>
                          <p>{catalogue.name}</p>
                          <p>{catalogue.product_second_name}</p>
                        </Box>,
                          <span>{service.attributes.short_name}</span>,

                        <span>{counterForTag} - {totalPieces} </span>,
                        item.id,
                        <Box className='d-inlineFlex align-item-center'>
                          <span>{showNotes(tag_numbers?.join(", "), 10)}</span>
                          {
                            orderData?.attributes.status_plant === "receiving_area"
                            && (
                              <Box 
                                className={this.printIconClasses(!item.attributes.tag_image.length)}
                                onClick={() => this.handlePrintTag([item])}
                              >
                                <img src={IconBluePrint} />
                              </Box>
                            )
                          }
                        </Box>,
                        <p style={{ fontSize: "14px", color: "#204B9C"}}>
                          {this.returnDetailsTableRowNotes(notes)}
                        </p>,
                        <Box>{this.renderSpecificationInTableRow(order_item_preferences)}</Box>
                      ].map((heading: unknown, index: number) => (
                        <TableCell
                          content={heading as React.ReactNode}
                          type={this.getTypeOfOrderItemTable(index)}
                        />
                      ))}
                    </TableRow>
                  );
                })}
              </RowContainer>
            </OrderTable>
            {
              renderBaseOnConditions(
                notes !== null || customer_complaint !== null,
                <Box className='p-8' />,
                <></>
              )
            }
            <Box className="d-flex gap-16">
              {
                renderBaseOnConditions(
                  this.renderNotesInDetails(notes),
                  <div className="order-notes">
                    <p className="notes-heading">
                      {configJSON.stringsListPage_table_detailsnotes}
                    </p>
                    <p>{notes}</p>
                  </div>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(
                  this.renderCustomerComplaint(customer_complaint),
                  <div className="order-notes">
                    <p className="notes-heading">
                      {configJSON.stringsListPage_table_customerComplaint}
                    </p>
                    <p>{customer_complaint}</p>
                  </div>,
                  <></>
                )
              }
            </Box>
            <Box className='p-8' />
            {(this.getCurrentSelectedTab().tabId == 2 && this.state.palntPermissionStatus.receivingAreaActionPermission) && (
              <Box className='d-flex gap-16'>
                <Box className='adjustWrapper'>
                  <Box className='d-flex gap-16'>
                    <div>
                      <BorderedSelect
                        displayEmpty
                        variant='outlined'
                        className='reasonSelect'
                        data-test-id='field-adjustmentReasonDropdown'
                        MenuProps={MenuProps}
                        renderValue={(item: unknown) =>
                          this.getRenderValueOfReason(item)
                        }
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) =>
                          this.onChangeSelectedAdjustmentReason(
                            event.target.value as string
                          )
                        }
                      >
                        {adjustmentList.map((item: IOption) => (
                          <StyledMenuItemWithBreakSpaces value={item.id}>
                            {item.label}
                          </StyledMenuItemWithBreakSpaces>
                        ))}
                      </BorderedSelect>
                    </div>
                    <div className='fgrow1'>
                      <TextareaAutosize
                        minRows={1}
                        data-test-id='adjustCommentTextInput'
                        value={this.state.adjustmentComment}
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) =>
                          this.onChangeAdjustmentComment(
                            event.target.value as string
                          )
                        }
                        placeholder={configJSON.stringsListPage_table_addNote}
                        className='textAreaStyles'
                      />
                    </div>
                  </Box>
                  {adjustmentError && (
                    <span className='adjustmentReasonError'>
                      {configJSON.adjustmentErrorMsg}
                    </span>
                  )}
                </Box>
                <div>
                  <BottomContainer className='pt-0'>
                    <button
                      data-test-id='adjustment-btn-submit'
                      className='add mw-206'
                      onClick={() =>
                        this.handleSubmitAdjustment(varID as number)
                      }
                    >
                      {configJSON.stringsListPage_table_reqadjust}
                    </button>
                  </BottomContainer>
                </div>
              </Box>
            )}

            <Box className='text-center'>
              <OrderDetailsBottomContainer>
                <button
                  className='cancel mw-186'
                  data-test-id='btn-cancel'
                  onClick={() => this.onCloseDetailsModal()}
                >
                  {configJSON.stringsListPage_table_cancel}
                </button>
                {renderBaseOnConditions(this.getCurrentSelectedTab().actionPermitted,
                  <>
                    <button
                      data-test-id='btn-submit'
                      className={`add mw-186 ${!this.isShowNextLabel() && this.getCurrentSelectedTab().actionPermitted && "disable-btn"
                        }`}
                      disabled={!this.isShowNextLabel()}
                      onClick={() => this.moveToNextPlantStatus(varID as number)}
                    >
                      {configJSON.stringsListPage_table_moveToNext}
                    </button>
                    {this.renderNextStatusLable()}
                  </>,
                  <></>
                )}
              </OrderDetailsBottomContainer>
            </Box>
          </StyledWrapper>
        </DialogContent>
      </OrderDetailsDialog>
    );
  }

  renderNextStatusLable() {
    return (
      <div className='sideText'>
        {this.isShowNextLabel() &&
          configJSON.stringsListPage_table_nextWillBe}
        <br />
        {this.isShowNextLabel() && this.getNextPlantActionTab().label}
      </div>
    )
  }

  renderPlantActionDropdown() {
    return (
      <BorderedSelect
        displayEmpty
        name='statusDropDown'
        data-test-id='field-optionDropdown'
        onClick={() => this.onClickOptionSelect()}
        disabled={this.getBorderSelectDisable()}
        variant='outlined'
        MenuProps={MenuProps}
        renderValue={(varItem: unknown) =>
          this.getBorderSelectRenderValue(varItem)
        }
        value={this.state.selectedOption}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          this.onChangeSelectedOption(event.target.value as string)
        }
      >
        {
          renderBaseOnConditions(
            this.getCurrentSelectedTab().actionPermitted,
            this.getTabList()
              .filter(
                (item: ITabListItem) =>
                  item.showDropItems.includes(this.getCurrentSelectedTab().tabId)
              )
              .map(({ label, apiKey, actionPermitted }: ITabListItem) => (
                <ModifiedMenuItem key={"drop-down-item-" + label} value={apiKey}>
                  <StyledRadio checked={apiKey === this.state.selectedOption} />{" "}
                  {label}
                </ModifiedMenuItem>
              ))
            ,
            <>
              <ModifiedMenuItem key={"drop-down-item-" + "Nopermission"} disabled>
                <StyledRadio disabled />{" "}
                {configJSON.noPermissions}
              </ModifiedMenuItem>
            </>
          )
        }
      </BorderedSelect>
    );
  }

  renderAdjustmentErrorModal() {
    return (
      <AdjustmentErrorDialog open={this.state.adjustMentErrorModal}>
        <DialogContent className='adjustmentDialogContent'>
          <p className='adjustmentPara'>{configJSON.adjustmentErrorPara}</p>
          <UpdateButton
            className='add mw-132 mt-24'
            data-test-id='cancel-btn'
            onClick={() => this.handleCancelAdjustOrderPopup()}
          >
            {configJSON.stringsListPage_table_cancel}
          </UpdateButton>
        </DialogContent>
      </AdjustmentErrorDialog>
    );
  }

  getCreditNoteButton(item: IDataItem) {
    if (!item?.attributes?.credit_note) return <></>;
    const isCreditOrder = item.attributes.credit_order_at
    return (
      <>
      {renderBaseOnConditions(
        this.state.palntPermissionStatus.creditNotePermission , 
        <div className={isCreditOrder ? 'credit-note-show-button' : 'credit-note-button'}>
         <button
          onClick={() => this.toggleCreditNoteDialog(item)}
          data-test-id={"credit-note-button-" + item.id}
        >
          <img src={creditNoteIcon} />
          <span style={{marginTop: "3px"}}>{isCreditOrder ? configJSON.credit_note_view_button  : configJSON.credit_note_button}</span>
        </button>
      </div>
      ,
      <></>)}
      </>
    );
  }

  showCommentCell(comment: string | null, orderId: string, item: IDataItem) {
    if (comment != null && comment != "") {
      return (
        <div className='commentWrapper'>
          {showNotes(comment)}
          {this.getCurrentSelectedTab().tabId == 7 && (
            <>
              {renderBaseOnConditions(
                this.state.palntPermissionStatus.adjustPermission , 
                  <AdjustTag
                    data-test-id='btn-adjust'
                    onClick={() => this.handleClickAdjustOrder(orderId)}
                  >
                      {configJSON.stringsListPage_table_adjust}
                  </AdjustTag>
                ,
              <></>)}
            </>
          )}
          {this.getCurrentSelectedTab().tabId == 6 &&
            this.getCreditNoteButton(item)}
        </div>
      );
    } else {
      return (
        <div className='commentWrapper'>
          <span>---</span>
          {this.getCurrentSelectedTab().tabId == 7 && (
            <>
            {renderBaseOnConditions(
              this.state.palntPermissionStatus.adjustPermission , 
                <AdjustTag
                  data-test-id='btn-adjust'
                  onClick={() => this.handleClickAdjustOrder(orderId)}
                >
                  {configJSON.stringsListPage_table_adjust}
                </AdjustTag>
              ,
            <></>)}
          </>
          )}
          {this.getCurrentSelectedTab().tabId == 6 &&
            this.getCreditNoteButton(item)}
        </div>
      );
    }
  }

  showPlantSNotes(
    notes: string,
    orderId: string,
    status: string,
    length: number = 30
  ) {
    if (notes != null && notes != undefined && notes != "") {
      if (notes.length > length) {
        return (
          <Box className='notesCell'>
            <NotesTooltip title={<p>{notes}</p>} arrow>
              <span>{notes.slice(0, length)}...</span>
            </NotesTooltip>
            {renderBaseOnConditions(
              this.state.palntPermissionStatus.cleanOrderActionPermission &&
              status !== "in_store" && status !== "ready" && status !== "cancelled"
              ,
              <AdjustTag
                data-test-id={`btn-adjust-${orderId}`}
                onClick={() => this.sendOrderToClean(orderId)}
              >
                {configJSON.stringsListPage_table_clean}
              </AdjustTag>,
              <></>
            )}
          </Box>
        );
      } else {
        return (
          <Box className='notesCell'>
            <p>{notes}</p>
            {renderBaseOnConditions(
              this.state.palntPermissionStatus.cleanOrderActionPermission &&
              status !== "in_store" && status !== "ready" && status !== "cancelled",
              <AdjustTag
                data-test-id={`btn-adjust-${orderId}`}
                onClick={() => this.sendOrderToClean(orderId)}
              >
                {configJSON.stringsListPage_table_clean}
              </AdjustTag>,
              <></>
            )}
          </Box>
        );
      }
    } else {
      return (
        <Box className='notesCell'>
          <p>---</p>
          {renderBaseOnConditions(
            this.state.palntPermissionStatus.cleanOrderActionPermission &&
            status !== "in_store" && status !== "ready" && status !== "cancelled",
            <AdjustTag
              data-test-id={`btn-adjust-${orderId}`}
              onClick={() => this.sendOrderToClean(orderId)}
            >
              {configJSON.stringsListPage_table_clean}
            </AdjustTag>,
            <></>
          )}
        </Box>
      );
    }
  }

  showCancelledOrderText(item: IDataItem) {
    if(
      this.getCurrentSelectedTab().tabId == 6 &&
      item.attributes.status === "cancelled"
    ) {
      return <div className='text breakWord'>{configJSON.cancelled_order_text}</div>
    } else {
      return <></>
    }
  }

  showReasonCell(item: IDataItem) {
    const reason = item.attributes.adjustment_reason;
    if (reason != null && reason != "") {
      return (
        <>
          <div className='commentWrapper'>
            <span className="breakWord">{reason}</span>
            {this.showCancelledOrderText(item)}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className='commentWrapper'>
            <span>---</span>
            {this.showCancelledOrderText(item)}
          </div>
        </>
      );
    }
  }

  renderAdjustOrderCustomer() {
    const { adjustOrder } = this.state;
    const customer = adjustOrder?.customer;
    const store = adjustOrder?.store_management.data.attributes;

    return (
      <OrderDetailsHeaderContainer className='responsive'>
        <OrderDetailsHeadingText>
          <div>
            <b>{configJSON.adjustmentPopup_customer_name}</b>
            {customer?.full_name}
          </div>
          <div>
            <b>{configJSON.adjustmentPopup_customer_mobile_no}</b>
            {customer?.full_phone_number}
          </div>

          <div>
            <b>{configJSON.adjustmentPopup_business}</b>{" "}
            {customer?.business || "--"}
          </div>

          <div>
            <b>{configJSON.adjustmentPopup_order_creation_date}</b>
            {this.getMomentFormat(adjustOrder?.created_at || "")}
          </div>
        </OrderDetailsHeadingText>
        <OrderDetailsHeadingText>
          <div>
            <b>{configJSON.adjustmentPopup_order_id}</b>{" "}
            {adjustOrder?.order_number}
          </div>
          <div>
            <b>{configJSON.adjustmentPopup_store_name}</b> {store?.store_name}
          </div>

          <div>
            <b>{configJSON.adjustmentPopup_price_list}</b>{" "}
            {adjustOrder?.price_list_name || "--"}
          </div>

          <div>
            <b>{configJSON.adjustmentPopup_discount}</b> {adjustOrder?.currency}{" "}
            {adjustOrder?.applied_discount || "--"}
          </div>

          <div>
            <b>{configJSON.adjustmentPopup_promocode_applied}</b>
            {adjustOrder?.promo_code?.promo_code || "--"}
          </div>
        </OrderDetailsHeadingText>
      </OrderDetailsHeaderContainer>
    );
  }

  renderAdjustOrderPopup() {
    const {
      adjustmentOrderModal,
      adjustmentRequestType,
      adjustmentRequestTypeSub,
    } = this.state;

    const renderObject: {
      [field: string]: Array<string>;
    } = {
      [configJSON.editOrderPopup_text_adjust_quantities]: [
        configJSON.adjustmentPopup_add_quantity,
        configJSON.adjustmentPopup_minus_quantity,
      ],
      [configJSON.editOrderPopup_text_edit_products]: [
        configJSON.adjustmentPopup_add_new_product,
        configJSON.adjustmentPopup_change_product,
        configJSON.adjustmentPopup_remove_product,
      ],
      [configJSON.editOrderPopup_text_edit_upcharge]: [
        configJSON.adjustmentPopup_add_upcharge,
        configJSON.adjustmentPopup_change_upcharge,
        configJSON.adjustmentPopup_remove_upcharge,
      ],
      [configJSON.editOrderPopup_text_undone]: [
        configJSON.adjustmentPopup_full_undone,
        configJSON.adjustmentPopup_partial_undone,
      ],
    };

    return (
      <OrderDetailsDialog open={adjustmentOrderModal} maxWidth={"md"} fullWidth>
        <DialogContent className='p-24'>
          <StyledWrapper>
            <AdjustOrderContainer>
              <div className='title'>
                {configJSON.adjustmentPopup_adjust_order}
              </div>
              <div className='shadow-box'>
                {this.renderAdjustOrderCustomer()}
                <Box className='p-16' />
                <OrderDetailsHeadingText>
                  <div>
                    <div>
                      <div className='select-label'>
                        {configJSON.adjustmentPopup_adjustment_reason}
                      </div>
                      <BorderedSelect
                        displayEmpty
                        variant='outlined'
                        MenuProps={MenuProps}
                        className='reasonSelect'
                        value={adjustmentRequestType}
                        name='adjustmentReasonDropdown'
                        data-test-id='field-adjustmentReasonDropdown'
                        renderValue={(value: unknown) =>
                          !value
                            ? configJSON.adjustmentPopup_select_reason
                            : (value as string)
                        }
                        onChange={(
                          event: React.ChangeEvent<{
                            value: unknown;
                          }>
                        ) =>
                          this.handleChangeAdjustmentRequestType(
                            event.target.value as string
                          )
                        }
                      >
                        {[
                          configJSON.adjustmentPopup_adjustment_quantities,
                          configJSON.adjustmentPopup_edit_products,
                          configJSON.adjustmentPopup_edit_upcharge,
                          configJSON.adjustmentPopup_undone,
                          configJSON.adjustmentPopup_cancel,
                        ].map((varItem: string) => (
                          <AdjustMenuItem value={varItem}>
                            <AdjustOrderRadio
                              checked={varItem == adjustmentRequestType}
                            />
                            {varItem}
                          </AdjustMenuItem>
                        ))}
                      </BorderedSelect>
                    </div>
                  </div>
                </OrderDetailsHeadingText>
                {Boolean(adjustmentRequestType) &&
                  adjustmentRequestType !=
                    configJSON.editOrderPopup_text_cancel && (
                    <div>
                      <div className='p-16' />
                      <div>
                        <b>{configJSON.adjustmentPopup_adjustment_request}</b>
                      </div>
                      <div className='flex responsive'>
                        {renderObject[adjustmentRequestType as string].map(
                          (item) => (
                            <div
                              data-test-id={"reason-sub-" + item}
                              onClick={() =>
                                this.handleChangeAdjustmentRequestTypeSub(item)
                              }
                            >
                              <StyledRadio
                                checked={item == adjustmentRequestTypeSub}
                              />
                              {item}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
              </div>
              <Box className='text-center'>
                <OrderDetailsBottomContainer>
                  <button
                    className='cancel mw-186'
                    data-test-id='btn-cancel'
                    onClick={() => this.handleAdjustOrderPopupFlag(false)}
                  >
                    {configJSON.stringsListPage_table_cancel}
                  </button>
                  <button
                    data-test-id='btn-submit'
                    className='add mw-186'
                    disabled={!this.getEnableDisableForAdjustOrder()}
                    onClick={() => this.handleContinueAdjustOrder()}
                  >
                    {configJSON.adjustmentPopup_confirm}
                  </button>
                </OrderDetailsBottomContainer>
              </Box>
            </AdjustOrderContainer>
          </StyledWrapper>
        </DialogContent>
      </OrderDetailsDialog>
    );
  }

  showUpchargeInProductCart(upcharge_list_ids: IChargeListItem[]) {
    if (upcharge_list_ids && upcharge_list_ids.length > 0) {
      return upcharge_list_ids.map((item) => {
        return (
          <div className='upcharge-name'>
            {configJSON.editOrderPopup_text_upcharge} : {item.attributes.name}
          </div>
        );
      });
    }
  }

  showServicesInProductCart(service: IService) {
    const icon = service?.attributes.icon.data.attributes.image;
    return (
      <button className='active'>
        <img src={icon} />
      </button>
    );
  }

  getProductItem(isChangeProductPopup?: boolean, orderItem?: IItemData) {
    const {
      addProduct,
      fullUndone,
      addUpcharge,
      changeProduct,
      partialUndone,
      removeProduct,
      changeUpcharge,
      removeUpcharge,
      decreaseQuantity,
      increaseQuantity,
    } = this.getAdjustmentRequestType();

    const item = orderItem?.attributes;

    return (
      <div className='border-box'>
        <div className='flex-cols'>
          <div className='product-image'>
            <img src={item?.product_image || SampleProductItemImage} />
          </div>
          <div>
            <b>{item?.catalogue.product_name}</b>
          </div>

          {(changeProduct || removeProduct || fullUndone) &&
            !isChangeProductPopup && (
              <>
                <div>
                  {configJSON.editOrderPopup_text_service}{" "}
                  {item?.service.attributes.name}
                </div>
                <div>{configJSON.editOrderPopup_text_category} Men</div>
              </>
            )}

          {!addProduct &&
            !isChangeProductPopup &&
            this.showUpchargeInProductCart(
              item?.upcharge_list_ids as IChargeListItem[]
            )}

          {changeProduct && !isChangeProductPopup && (
            <div
              className='link-button'
              data-test-id='btn-change'
              onClick={() => this.handleChangeItemInEditOrder()}
            >
              {configJSON.editOrderPopup_text_change}
            </div>
          )}
          {removeProduct && (
            <div className='link-button'>
              {configJSON.editOrderPopup_text_remove}
            </div>
          )}

          {(addProduct || isChangeProductPopup) && (
            <div>
              {configJSON.editOrderPopup_text_price} SAR {item?.unit_price}
            </div>
          )}

          {(addProduct ||
            isChangeProductPopup ||
            increaseQuantity ||
            decreaseQuantity ||
            addUpcharge ||
            partialUndone ||
            changeUpcharge ||
            removeUpcharge) && (
            <div className='quantity'>
              <button>
                <img src={RemoveProductIcon} />
              </button>
              <span>{item?.quantity}</span>
              <button>
                <img src={AddProductIcon} />
              </button>
            </div>
          )}

          {(increaseQuantity ||
            decreaseQuantity ||
            addProduct ||
            isChangeProductPopup ||
            addUpcharge ||
            changeUpcharge ||
            removeUpcharge ||
            partialUndone) && (
            <div className='services'>
              {this.showServicesInProductCart(item?.service as IService)}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderProductCard() {
    const { cataloguesDataList, cataloguesListMeta } = this.state;
    return (
      <>
      <div 
        style={{height: "calc(100vh - 20vh", overflowY: 'auto'}}
        onScroll={this.handleCatalogueListScroll}
        data-test-id='catalogueCardScroll'
      >
        <div className='product-item-container'>
          {cataloguesDataList.map((item) => {
            return this.renderCatalougeCard(item);
          })}
        </div>
          {this.state.cataloguesDataList?.length < 1 &&
            !this.state.isCatalogueCardLoading &&
            "No products available."}
      </div>
      </>
    );
  }

  renderCarpetAndFurnitureSQMWeightDialog = () => {
    return (
      <CarpetAndFurnitureProductDialogue 
        productPopup={this.state.productPopup}
        carpetFurnitureProductPopupData={this.state.carpetFurnitureProductPopupData}
        selectedService={this.state.selectedService}
        handleSubmitCarpetAndFurnitureProduct={(items: IFormValues[]) => this.handleSubmitCarpetAndFunrnitureProduct(items)}
        closeCarpetAndFurnitureModal={() => this.onCloseCareptAndFurnitureProductPopup()}
      />
    )
  }

  renderCatalougeCard(item: CatalogueDataList) {
    const { tabsId, adjustCartProduct } = this.state;
    const catalogueId = item?.attributes?.id;

    const selectedProductData = adjustCartProduct.find(
      (product) =>
        product.catalogue_id === catalogueId &&
        product.category_id === tabsId
    );

    let selectedProductQuantity = 0;

    const selectedServiceId = selectedProductData?.service_id || undefined;

    if (item.attributes.product_type_id === productTypes.PARENT) {
      const newQty = adjustCartProduct.filter(
        (product: IAdjustCartProduct) =>
          product.parent_Product_id === catalogueId &&
          product.category_id === tabsId
      );
      const sumOfAllQty = newQty.reduce(
        (accu: number, product: { quantity: number }) => {
          return accu + product.quantity;
        },
        0
      );
      selectedProductQuantity = sumOfAllQty;
    } else {
      selectedProductQuantity = selectedProductData?.quantity || 0;
    }

    return (
      <CatalogueCard
        navigation={this.props.navigation}
        data-test-id='product-card-component'
        key={item.id}
        id='catalogueCard'
        tabsId={tabsId}
        catalogue={item}
        selectedServiceId={selectedServiceId}
        selectedProductQuantity={selectedProductQuantity}
        addQuantityClickHandler={this.qtyIncreaseHandler}
        removeQuantityClickHandler={this.qtyDecreaseHandler}
        serviceClickHandler={this.serviceClickHandler}
        parentProductId={this.state.clickedParentCatalougeId}
        isQuickDrop={false}
      />
    );
  }

  renderSubProductCard() {
    const { cataloguesDataList, clickedParentCatalougeId } = this.state;
    const subProductList = cataloguesDataList.find(
      (item) => item.attributes.id === clickedParentCatalougeId
    )?.attributes.sub_products.data;

    return (
      <>
        <div className='product-item-container no-grid'>
          {(subProductList as CatalogueDataList[]).map((item) => {
            return this.renderCatalougeCard(item);
          })}
        </div>
      </>
    );
  }

  showCatalougeInProductCart(
    catalogue: Icatalouge,
    qtyNumber: number,
    serviceName: string
  ) {
    return (
      <div className='item-name'>
        <div className='d-flex'>
          <p>{catalogue.name}</p>
          <span className='mx-6'>X</span>
          <p>{qtyNumber}</p>
        </div>
      </div>
    );
  }

  getSpecifications = (orderItem_Preference: OrderItemPreferences[]) => {
    const result: {[key: string]: Array<string>} = {};

    orderItem_Preference.forEach(item => {
      if(item.attributes.specifications) {
        Object.entries(item.attributes.specifications).forEach(([objectKey, objVal]) => {
          if (!result[objectKey]) {
            result[objectKey] = [];
          }
          if (!result[objectKey].includes(objVal)) {
            result[objectKey].push(objVal);
          }
        });
      }
    });

    return result;
  };

  getUpcharges = (orderItem_Preference: OrderItemPreferences[]) => {
    const output = orderItem_Preference
      .flatMap((item) => item.attributes.upcharges)
      .reduce((accIUpcharge: IChargeListItem[], current) => {
        if (
          !accIUpcharge.some(
            (item) => item.attributes.id === current.attributes.id
          )
        ) {
          accIUpcharge.push(current);
        }
        return accIUpcharge;
      }, []);

    return output;
  };

  showAllUpchargesInCart = (orderItem_Preference: OrderItemPreferences[]) => {
    const upcharges_lists = this.getUpcharges(orderItem_Preference);
    return (
      <ShowAllUpcharges>
        {upcharges_lists.map((upcharge) => {
          return (
            <div className='d-flex'>
              <div className='d-flex'>
                <span>{upcharge.attributes.name}</span>
                {upcharge.attributes.name_translation && (
                  <>
                    <span className='circle' />
                    <span className='mr-6'>
                      {upcharge.attributes.name_translation}
                    </span>
                  </>
                )}
              </div>
              <span> : SAR {upcharge.attributes.order_price}</span>
            </div>
          );
        })}
      </ShowAllUpcharges>
    );
  };

  showAllSpecifications = (orderItem_Preference: OrderItemPreferences[]) => {
    const results = this.getSpecifications(orderItem_Preference);

    const title = (
      <>
        <ShowPreferenceWrapper className='specification-data-show'>
          {Object.keys(results).map((objKey) => {
            return (
              <React.Fragment key={objKey}>
                <span className='slash-span'>/</span>
                <span className='specification-span-tag'>
                  {`${results[objKey].join(" / ")}`}
                </span>
              </React.Fragment>
            );
          })}
        </ShowPreferenceWrapper> 
      </>
    );

    const noDataTxt = (
      <ShowPreferenceWrapper className='specification-data-show'>
        <span className='specification-span-tag'>
          No Specification Selected
        </span>
      </ShowPreferenceWrapper>
    );

    const condition = Object.keys(results).length !== 0;

    return (
      <PreferenceTooltip
        title={condition ? title : noDataTxt}
        arrow
        placement='top-end'
      >
        <span
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <span
            style={{
              fontSize: "13px",
              textDecoration: "underline",
              fontFamily: "Montserrat",
            }}
          >
            See all preferences
          </span>
          <HelpOutlined
            style={{
              margin: "0 0 0 6px",
              fontSize: "13px",
              fontFamily: "Montserrat",
            }}
          />
        </span>
      </PreferenceTooltip>
    );
  };

  showPreferencesData = (
    orderItem_Preference: OrderItemPreferences[],
    preference_id: number
  ) => {
    const uniqueNames = Array.from(
      orderItem_Preference
        .reduce((mapKey, objVal) => {
          if(objVal.attributes.preference !== null) {
            if (
              !mapKey.has(
                objVal.attributes.preference.attributes.preference_first_name
              )
            ) {
              mapKey.set(
                objVal.attributes.preference.attributes.preference_first_name,
                objVal
              );
            }
          }
          return mapKey;
        }, new Map())
        .values()
    );

    const filterEditedPreferences = uniqueNames.filter(
      (item: OrderItemPreferences) =>
        item.attributes.preference.attributes.id !== preference_id
    );

    const allPreferencesName = filterEditedPreferences.map(
      (item: OrderItemPreferences) =>
        item.attributes.preference.attributes.preference_first_name
    );

    return (
      <div className='specification-data-show'>
        <span>{allPreferencesName.join(" / ")}</span>
      </div>
    );
  };

  showSQMData(height: string, width: string, weight: string) {
    return (
      <>
      { Number(height) !== 0 && Number(width) !== 0 &&
        <div className='upcharge'>
          length * width: <div> {height} m * {width} m </div>
        </div>
      }
      { Number(weight) !== 0 &&
        <div className='upcharge'>
         weight : {weight} Kg
        </div>
      }
      </>
    );
  }

  getProductTotals() {
    const { adjustOrder, cartTotalOpen } = this.state;
    const { fullUndone, cancelOrder } = this.getAdjustmentRequestType();
    const buttonText = this.getUpChargeButtonTitle();
    const combinationList = this.getCombinationList();
    let uniqueCatalogueList = this.getUniqueCombinationList(combinationList);

    const {
      total,
      currency,
      sub_total,
      total_tax,
      no_of_items,
      tax_percentage,
    } = adjustOrder || {};

    return (
      <div className='product-totals'>
        <div className='shadow-box'>
          <div>
            <div className='flex space-between heading'>
              <div className='title'>
                {configJSON.editOrderPopup_text_order_details}
              </div>
              <div>
                {!(fullUndone || cancelOrder) && (
                  <button
                    className='action-btn'
                    data-test-id='btn-upcharge'
                    onClick={() => this.getUpchargeListForProduct()}
                  >
                    {buttonText} {configJSON.editOrderPopup_text_upcharge}
                  </button>
                )}
              </div>
            </div>
            <div>
              {uniqueCatalogueList.map((uniqueItem: UniqueCombination) => {
                const item = this.findItemFromUnique(uniqueItem);
                const subItems = this.findSubItemsList(uniqueItem);
                return (
                  <div
                    key={uniqueItem.category_id}
                    className='product-total-items'
                    data-test-id={`service-name-${item?.attributes.service?.id}`}
                  >
                    <div className='name'>
                      {`${item?.attributes.category_name} - ${item?.attributes.service?.attributes.name}`}
                    </div>
                    <div className='product-sub-items'>
                      {subItems.map((item: IItemData) => {
                        return (
                          <div className='mt-10' key={item.id}>
                            <div className='item-grid'>
                              <div className='flex110'>
                                {this.showCatalougeInProductCart(
                                  item.attributes.catalogue,
                                  item.attributes.quantity,
                                  item.attributes.service.attributes.second_name
                                )}
                                {
                                  this.showPreferencesData(
                                    item.attributes.order_item_preferences,
                                    item.attributes.preference?.attributes.id
                                  )
                                }
                                {this.showSQMData(
                                  item.attributes.height,
                                  item.attributes.width,
                                  item.attributes.weight
                                )}
                                {this.showAllUpchargesInCart(
                                  item.attributes.order_item_preferences
                                )}
                                {
                                  this.showAllSpecifications(
                                    item.attributes.order_item_preferences
                                  )
                                }
                              </div>
                              <div>
                                <div className='total-wrapper'>
                                  <div className='total top-space'>
                                    SAR {item.attributes.total_price}
                                  </div>
                                  {!cancelOrder && !fullUndone && (
                                    <IconButton
                                      className='delete top-space'
                                      data-test-id={`btn-delete-${item.attributes.id}`}
                                      onClick={() =>
                                        this.deleteOrderItemHandler(item)
                                      }
                                    >
                                      <img
                                        src={DeleteProductIcon}
                                        className='deleteIcon'
                                        data-test-id='itemDelete'
                                      />
                                    </IconButton>
                                  )}
                                </div>
                                <div className='pcs'>
                                  {!cancelOrder && !fullUndone && (
                                    <IconButton
                                      data-test-id={`decrease-qty-in-cart-${item.attributes.id}`}
                                      onClick={() =>
                                        this.qtyHandlerInCart(item, "minus")
                                      }
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  )}
                                  <input
                                    className="qty-input"
                                    data-test-id={`qty-input-${item.id}`}
                                    value={item.attributes.quantity}
                                    onChange={(event) => this.handleInputQty(event, item)}
                                  />
                                  {!cancelOrder && !fullUndone && (
                                    <IconButton
                                      data-test-id={`increase-qty-in-cart-${item.attributes.id}`}
                                      onClick={() =>
                                        this.qtyHandlerInCart(item, "plus")
                                      }
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div
          className='order-summary-total'
          onClick={() => this.handleToggleCartTotal()}
        >
          <Collapse in={cartTotalOpen} timeout='auto' unmountOnExit>
            <div className='total-item'>
              <div>{configJSON.editOrderPopup_text_total_items}</div>
              <div>{no_of_items || "0.0"}</div>
            </div>
            <div className='total-item'>
              <div>{configJSON.editOrderPopup_text_sub_total}</div>
              <div>
                {currency} {sub_total || "0.0"}
              </div>
            </div>
            <div className='total-item'>
              <div>
                {configJSON.editOrderPopup_text_tax} ({tax_percentage})
              </div>
              <div>
                {currency} {total_tax || "0.0"}
              </div>
            </div>
            <div>
              <hr />
            </div>
          </Collapse>
          <div className='total-item total' data-test-id='btn-total'>
            <div>{configJSON.editOrderPopup_text_total}</div>
            <div className='flex total' style={{ gap: 8, paddingBottom: 0 }}>
              <div>
                {currency} {total || "0.0"}
              </div>
              <div className='caret-icon'>
                {cartTotalOpen ? <ExpandMore /> : <ExpandLess />}
              </div>
            </div>
          </div>
        </div>

        <div>
          <PaymentDropdown
            fullWidth
            displayEmpty
            variant='outlined'
            MenuProps={MenuProps}
            style={{ display: "none" }}
          >
            <MenuItem>{configJSON.editOrderPopup_text_payment_method}</MenuItem>
          </PaymentDropdown>
        </div>
      </div>
    );
  }

  getSectionTabs() {
    const {
      addProduct,
      changeProduct,
      increaseQuantity,
      decreaseQuantity,
      removeProduct,
      addUpcharge,
      changeUpcharge,
      removeUpcharge,
      partialUndone,
    } = this.getAdjustmentRequestType();
    const { storeManagementDetails } = this.state;

    const isVisible =
      addProduct ||
      changeProduct ||
      increaseQuantity ||
      decreaseQuantity ||
      removeProduct ||
      addUpcharge ||
      changeUpcharge ||
      removeUpcharge ||
      partialUndone;
    if (!isVisible) return <></>;

    const allSectionList =
      storeManagementDetails?.data.attributes.store_configuration.attributes
        .sections;

    if(allSectionList !== undefined) {
      return (
        <WrapperSectionTabs>
          <StyledSectionTabs
            value={this.state.tabsId}
            scrollButtons='on'
            variant='scrollable'
            onChange={this.tabSectionChangeHandler}
          >
            {(allSectionList as { id: number; name: string }[]).map((item) => {
              return (
                <Tab
                  data-test-id={`section-tab-${item?.id}`}
                  label={item?.name}
                  value={item?.id}
                  key={item?.id}
                />
              );
            })}
          </StyledSectionTabs>

          <OutlinedInput
            placeholder='Search for Product'
            inputProps={{
              "data-test-id": "searchCatalogue",
              "data-testid": "searchCatalogue",
            }}
            value={this.state.searchCatalogue}
            onChange={this.handleSearchCatalogue}
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
          }
        />
        </WrapperSectionTabs>
      );
    }
  }

  renderChangeProduct() {
    const { changeProductModal } = this.state;
    return (
      <OrderDetailsDialog open={changeProductModal} maxWidth={"lg"} fullWidth>
        <DialogContent className='p-24'>
          <StyledWrapper>
            <AdjustOrderContainer>
              <div className='title'>
                {configJSON.editOrderPopup_change_product}
              </div>

              {this.getSectionTabs()}

              <div className='adjust-order-product-container change-product-popup'>
                <div className='product-item-container'>
                  {Array.from({ length: 6 }).map(() =>
                    this.getProductItem(true)
                  )}
                </div>
              </div>

              <Box className='text-center'>
                <OrderDetailsBottomContainer className='responsive-buttons'>
                  <button
                    className='cancel mw-186'
                    data-test-id='btn-cancel'
                    onClick={() => this.handleBackInChangeItemModal()}
                  >
                    {configJSON.editOrderPopup_btn_back}
                  </button>
                  <button className={`add mw-186`} data-test-id='btn-submit'>
                    {configJSON.editOrderPopup_btn_continue}
                  </button>
                </OrderDetailsBottomContainer>
              </Box>
            </AdjustOrderContainer>
          </StyledWrapper>
        </DialogContent>
      </OrderDetailsDialog>
    );
  }

  renderEditOrderForAdjust() {
    const { editOrderPopupFromAdjustment, adjustOrderItems } = this.state;
    const {
      increaseQuantity,
      decreaseQuantity,
      addProduct,
      changeProduct,
      removeProduct,
      addUpcharge,
      changeUpcharge,
      removeUpcharge,
      partialUndone,
    } = this.getAdjustmentRequestType();

    return (
      <OrderDetailsDialog
        open={editOrderPopupFromAdjustment}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogContent className='p-24'>
          <StyledWrapper>
            <AdjustOrderContainer>
              <div className='title'>{this.getAdjustEditOrderTitle()}</div>

              {this.renderAdjustOrderCustomer()}

              {this.getSectionTabs()}

              <div className='adjust-order-product-container'>
                <div>
                  {increaseQuantity ||
                  decreaseQuantity ||
                  addProduct ||
                  changeProduct ||
                  removeProduct ||
                  addUpcharge ||
                  changeUpcharge ||
                  removeUpcharge ||
                  partialUndone ? (
                    this.renderProductCard()
                  ) : (
                    <div className='product-item-container'>
                      {adjustOrderItems?.map((item: IItemData) =>
                        this.getProductItem(false, item)
                      )}
                    </div>
                  )}
                </div>
                <div className='border-box'>{this.getProductTotals()}</div>
              </div>

              <Box className='text-center'>
                <OrderDetailsBottomContainer className='responsive-buttons'>
                  <button
                    className='cancel mw-186'
                    data-test-id='btn-cancel'
                    onClick={() => this.handleBackEditOrder()}
                  >
                    {configJSON.editOrderPopup_btn_back}
                  </button>
                  <button
                    disabled={this.state.adjustOrder?.order_items.length === 0}
                    className={`add mw-186`}
                    data-test-id='btn-submit'
                    onClick={() => this.handleContinueEditOrder()}
                  >
                    {this.getEditOrderPopupConfirmButtonText()}
                  </button>
                </OrderDetailsBottomContainer>
              </Box>
            </AdjustOrderContainer>
          </StyledWrapper>
        </DialogContent>
      </OrderDetailsDialog>
    );
  }

  renderReviewOrder() {
    const { reviewOrderPopupFromAdjustment, adjustOrder, adjustOrderItems } =
      this.state;

    return (
      <OrderDetailsDialog
        fullWidth
        maxWidth={"md"}
        data-test-id='reviewOrderModal'
        open={reviewOrderPopupFromAdjustment}
      >
        <DialogContent className='p-24'>
          <StyledWrapper>
            <Typography className='titleReviewOrder'>
              {configJSON.txtReviewOrder}
            </Typography>

            {this.renderAdjustOrderCustomer()}

            <Box className='p-16' />

            <ReviewOrderTableContainer>
              <Typography className='label-15-600'>
                {configJSON.txtNewOrderDetails}
              </Typography>
              <ReviewOrderTableRowContainer>
                {adjustOrderItems.map((item: IItemData) => {
                  const product = item.attributes.catalogue;
                  const upCharges = item.attributes.upcharge_list_ids;
                  return (
                    <ReviewOrderTableRow key={item.attributes.id}>
                      <div>
                        <Typography>{`${product?.product_name} • ${product?.product_second_name} X ${item.attributes.quantity}`}</Typography>
                        <Typography>
                          {upCharges.map((item) => {
                            const { name, price, name_translation } =
                              item.attributes;
                            return `Upcharge : ${name} • ${name_translation} (SAR ${price})`;
                          })}
                        </Typography>
                      </div>
                      <div>
                        <Typography>
                          {`SAR ${item.attributes.total_price}`}
                        </Typography>
                      </div>
                    </ReviewOrderTableRow>
                  );
                })}

                <hr />

                {[
                  {
                    label: configJSON.review_order_text_total_items,
                    value: adjustOrder?.no_of_items,
                  },
                  {
                    label: configJSON.review_order_text_sub_total,
                    value: "SAR " + adjustOrder?.sub_total,
                  },
                  {
                    label: `${configJSON.review_order_text_tax} (${adjustOrder?.tax_percentage})`,
                    value: "SAR " + adjustOrder?.tax_charges,
                  },
                  { label: configJSON.review_order_text_discount, value: "" },
                  {
                    label: configJSON.review_order_text_promocode_discount,
                    value: "SAR " + adjustOrder?.applied_discount,
                  },
                  {
                    label: <b>{configJSON.review_order_text_total_amount}</b>,
                    value: <b>SAR {adjustOrder?.total}</b>,
                  },
                ].map(({ label, value }) => (
                  <ReviewOrderTableRow key={label}>
                    <div>{label}</div>
                    <div>{value}</div>
                  </ReviewOrderTableRow>
                ))}
              </ReviewOrderTableRowContainer>
            </ReviewOrderTableContainer>

            <Box className='p-8' />
            <ReviewOrderAddReasonTextArea
              style={{ display: "none" }}
              placeholder={configJSON.txtAddReason}
            />

            <Box className='text-center'>
              <OrderDetailsBottomContainer>
                <button
                  className='cancel mw-186'
                  data-test-id='btn-cancel'
                  onClick={this.handleCloseReviewOrder.bind(this)}
                >
                  {configJSON.backText}
                </button>

                <button
                  className={`add mw-186`}
                  data-test-id='btn-submit'
                  onClick={() => this.handleReviewCreateOrderClick()}
                >
                  {configJSON.txtCreateNewOrder}
                </button>
              </OrderDetailsBottomContainer>
            </Box>
          </StyledWrapper>
        </DialogContent>
      </OrderDetailsDialog>
    );
  }

  renderOrderDetailTable() {
    const { customer, currency, created_at, total, tax_percentage } =
      this.state.creditNoteOrder?.attributes || {};

    return (
      <>
      <MainWrapper>
        <TableContainer component={Paper}>
          <Table aria-label='customized table'>
            <TableHead>
              <MuiTableRow
                className='tableheaderRow'
                style={{ color: colors().background, background: "#F8FAFC" }}
              >
                <MuiTableCell className="tableHeader">Customer Name</MuiTableCell>
                <MuiTableCell className="tableHeader">Order Creation Date</MuiTableCell>
                <MuiTableCell className="tableHeader">Total Order Amount</MuiTableCell>
                <MuiTableCell className="tableHeader">Tax</MuiTableCell>
                <MuiTableCell className="tableHeader">Adjustment Amount</MuiTableCell>
                <MuiTableCell className="tableHeader">Refund Amount</MuiTableCell>
              </MuiTableRow>
            </TableHead>
            <TableBody data-test-id='tblBody'>
              <MuiTableRow>
                <MuiTableCell>{customer?.full_name}</MuiTableCell>
                <MuiTableCell>
                  {this.getMomentFormat(String(created_at))}
                </MuiTableCell>
                <MuiTableCell>{total}</MuiTableCell>
                <MuiTableCell>
                  <CreditNoteInput
                    value={`${tax_percentage} %`}
                    className='tax'
                    readOnly
                  />
                </MuiTableCell>
                <MuiTableCell>
                  <CreditNoteInput
                    value={currency + " " + total}
                    className='amount'
                    readOnly
                  />
                </MuiTableCell>
                <MuiTableCell>{currency + " " + total}</MuiTableCell>
              </MuiTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </MainWrapper>
      </>
    );
  }

  renderCreditNoteDialog() {
    const {
      addNote,
      creditNoteOrder,
      isCreditNoteDialogOpen,
      isConfirmationChecked,
      creditNoteReceipt,
      hidden,
      openCrediNoteModalLoader
    } = this.state;

    const {
      adjustment_reason,
      adjustment_note,
      order_number,
      total_tax,
      sub_total,
      currency,
      total,
    } = creditNoteOrder?.attributes || {};
    return (
      <StyledDialogWrapper
        fullWidth
        maxWidth={"md"}
        data-test-id='dialog'
        className='mainDialog'
        open={isCreditNoteDialogOpen}
      >
        <Typography className='headerTitle' variant='h4' align='center'>
          {configJSON.stringsCreditNotePage_CreateCreditNoteHeader}
        </Typography>
        <CustomLoader loading={this.state.openCrediNoteModalLoader} />
        <DialogContent className='dialogContent'>
          <Box className="credit-note-modal-header">
            <Typography className='orderId'>
              Order ID : {this.renderCreditOrderNumber(order_number)}
            </Typography>
            {renderBaseOnConditions(
              hidden && !openCrediNoteModalLoader,
              <Typography className='orderId'> Reference ID : {order_number} </Typography>,
              <></>
            )}
          </Box>
          <TextLabel>
            {configJSON.stringsCreditNotePage_AdjustMentReason}
          </TextLabel>
          <StyledSelectAdjustmentReason
            id='adjustmentReasonId'
            variant='outlined'
            name='adjustmentReasonId'
            value={adjustment_reason}
            data-test-id='adjustmentReasonId'
            MenuProps={CustomMenuProps}
          >
            <MenuItem value={adjustment_reason || ""}>
              {adjustment_reason}
            </MenuItem>
          </StyledSelectAdjustmentReason>

          <br></br>
          <br></br>
          <Grid>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography className='tableHeading' variant='h4'>
                {configJSON.stringsCreditNotePage_table_heading}
              </Typography>
              <button
                  disabled={!creditNoteReceipt}
                  data-test-id='btn-print-credit-note'
                  className='printBtn'
                  onClick={() => this.hanldeClickPrintCreditNote()}
              >
                <img src={IconBluePrint} />
                {configJSON.stringsListPage_print}
              </button>
            </Box>
            

            <br></br>
            {this.renderOrderDetailTable()}
          </Grid>

          <br></br>
          <br></br>

          <PriceGrid
            container
            justifyContent='flex-end'
            direction='column'
            alignItems='flex-end'
            className="total_price"
          >
            <Grid>
              Total : {currency} {sub_total}
            </Grid>
            <Grid>
              Tax : {currency} {total_tax}
            </Grid>
            <Grid>
              Total inc. Tax: {currency} {total}
            </Grid>
          </PriceGrid>

          <Typography className='adjustmentDetails' variant='h6'>
            {configJSON.stringsCreditNotePage_AdjustMentDetails}
          </Typography>
          <Typography className='addedNewUpcharge' variant='h6'>
            {adjustment_note}
          </Typography>
          <TextareaAutosize
            minRows={2}
            className='textAreaStyles'
            value={addNote}
            onChange={this.handleAddNote}
            data-test-id='creditNotTextInput'
            disabled={this.state.creditNotePresent}
            placeholder={configJSON.stringsCreditNotePage_CreditNote_Add_Note}
          />
        </DialogContent>

        <div className='confirm-text-container'>
          <img
            alt='checkbox'
            data-test-id='confirmationBox'
            onClick={this.handleConfirmationCheckBox}
            src={isConfirmationChecked ? CheckTrue : CheckFalse}
          />
          <span className='confirmationText'>
            {configJSON.stringsCreditNotePage_ConfirmationText}
          </span>
        </div>

        <Box className='groupButton'>
          <Grid container direction='row' spacing={1}>
            <Grid item xs={12} md={6}>
              <Button
                disableRipple
                className='cancelButton'
                data-test-id='cancel-btn-customer-preference'
                onClick={() => this.toggleCreditNoteDialog()}
              >
                {configJSON.stringsCreditNotePage_CancelButton}
              </Button>
            </Grid>
            {this.state.hidden === false && (
              <Grid item xs={12} md={6}>
                <Button
                  disableRipple
                  className='addButton'
                  onClick={() => this.createCreditNoteApi()}
                  disabled={!this.state.isConfirmationChecked || this.state.creditLoader}
                  data-test-id='create-button-customer-preference'
                >
                 {this.state.creditLoader ?
                  <CircularProgress style={{ width: 24, height: 24, color: "white" }} />
                  :
                  configJSON.stringsCreditNotePage_CreateCreditNoteButton
                }
                  
                </Button>
              </Grid>
             )} 
          </Grid>
        </Box>
      </StyledDialogWrapper>
    );
  }

  renderOrderCancelPopup = () => {
    return (
      <OrderDetailsDialog
        maxWidth={"sm"}
        data-test-id='reviewOrderModal'
        open={this.state.adjustmentCancelOrderModal}
        fullWidth
      >
        <DialogContent className='p-32'>
          <StyledWrapper>
            <div className='flex-column gap-24'>
              <ErrorOutlineRoundedIcon className='warn-icon' />

              <div className='flex-column gap-10'>
                <Typography className='label-24-600' align='center'>
                  {configJSON.txtOrderCancelled}
                </Typography>
                <Typography className='label-20-400 p-rl-15' align='center'>
                  {this.state.adjustmentCancelOrderMessage}
                </Typography>
              </div>

              <Button
                disableRipple
                onClick={this.handleCloseCancelOrderPopup.bind(this)}
                className={`done-btn`}
                data-test-id='btn-submit'
              >
                {configJSON.txtDone}
              </Button>
            </div>
          </StyledWrapper>
        </DialogContent>
      </OrderDetailsDialog>
    );
  };

  renderPreferencesModal = () => {
    return (
      <PreferenceItem
        data-test-id='preferenceItem'
        preferenceData={this.state.preferenceData}
        selectedService={this.state.selectedService}
        handleClose={this.handleClosePreference}
        data={[]}
        headerTitle={"Create Item Preference"}
        saveForFuture={true}
        open={this.state.preferenceModalVisible}
        responseData={{
          id: "11",
          type: "preference",
          attributes: {
            id: 11,
            name: "Preference 1",
            product_second_name: "Product 1",
            specifications: [],
            upcharges: [],
            preferences: [],
            services: [],
          },
        }}
        onCreate={(data) => this.handleCreateAdjustOrderPreference(data)}
      />
    );
  };

  displayTableColumnBasedOnTabGroup = () => {
    return (
      this.getCurrentSelectedTab().tabId == 7 ||
      this.getCurrentSelectedTab().tabId == 6
    );
  };

  renderSameProductDifferenceServiceModal() {
    const { sameProductDifferentServiceModalOpen } = this.state;
    return (
      <SameProductDifferentServiceModal
        open={sameProductDifferentServiceModalOpen}
        handleClose={this.sameProductDiffServiceModalClose}
        handleConfirmClick={this.sameProductDiffServiceConfirmClick}
      />
    );
  }

  renderSubProductModal() {
    const { subProductModal } = this.state;

    return (
      <SubProductModal open={subProductModal} maxWidth='md'>
        <DialogContent className='p-24'>
          <StyledWrapper>
            <AdjustOrderContainer>
              <p className='dialogTitle'>{configJSON.select_sub_product}</p>
              <div className='adjust-order-product-container change-product-popup'>
                {subProductModal && this.renderSubProductCard()}
              </div>
              <DialogActions className='dialogActionWrapper'>
                <MainButton
                  data-test-id='btnSubCancel'
                  onClick={this.subProductPopupModalHandler}
                  className='buttonSecondary'
                >
                  {configJSON.stringsCreditNotePage_CancelButton}
                </MainButton>
                <MainButton
                  data-test-id='btnSubNext'
                  onClick={this.subProductPopupModalHandler}
                >
                  {configJSON.stringsCreditNotePage_NextButton}
                </MainButton>
              </DialogActions>
            </AdjustOrderContainer>
          </StyledWrapper>
        </DialogContent>
      </SubProductModal>
    );
  }

  getUpdateButton = () => {
    return (
      <>
        {this.state.isUpdateStatusLoading && (
          <CircularProgress size={16} color="inherit" />
        )}
        {configJSON.stringsListPage_submit}
      </>
    );
  }

  getMainPlanRender = () =>{
    return (
    <>
        <StyledWrapper>
          <CustomLoader loading={this.shouldShowLoader()} />
          {this.renderChangeProduct()}
          {this.renderEditOrderForAdjust()}
          {this.renderOrderDetails()}
          {this.renderAdjustOrderPopup()}
          {this.renderAdjustmentErrorModal()}
          {this.renderReviewOrder()}
          {this.renderCreditNoteDialog()}
          {this.renderOrderCancelPopup()}
          {this.renderPreferencesModal()}
          {this.renderSameProductDifferenceServiceModal()}
          {this.renderSubProductModal()}
          {this.renderCarpetAndFurnitureSQMWeightDialog()}
          {this.state.adjustmentSelectUpChargeModal && this.renderEditPreferenceModal()}
          <Portal>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackbarMessage.length !== 0}
            autoHideDuration={4000}
            onClose={() => this.onCloseSnackBar()}
          >
            <Alert severity={this.state.snackBarSeverity}>{this.state.snackbarMessage}</Alert>
          </Snackbar>
          </Portal>
          <StyledActionsGroup>
            {
              renderBaseOnConditions(
                this.isShortingAreaTab(),
                <Button
                  data-test-id="autosend-toggle" 
                  className={this.getToggleButtonClass()} 
                  onClick={() => this.toggleAutoSendMode()}
                >
                  {renderBaseOnConditions(this.state.isAutoSend, "Auto Send (On)", "Auto Send (Off)")}
                </Button>,
                <></>
              )
            }
            <BarcodesScanner 
              openDialog={!Boolean(this.state.orderData)} 
              navigation={this.props.navigation} 
              id="" 
              onSubmit={this.scanOrder}
              loading={this.state.isScanningOrder}
            />
          </StyledActionsGroup>
          <Box className='d-flex align-item-center contentWrapperOrder gap-8 flex-wrap'>
            <div className='fgrow1'>
              <TabContainer isDrawerOpen={this.state.isDrawerOpen} className='ml-auto'>
                <CustomTabs
                  scrollButtons="on"
                  variant={this.handleStatusScroll(this.state.isDrawerOpen)}
                  data-test-id={configJSON.tabsTestId}
                  value={this.state.selectedTab}
                  textColor='primary'
                  indicatorColor='primary'
                  aria-label='icon label tabs example'
                  className='custom-tabs'
                  TabIndicatorProps={{ style: { display: "none" } }}
                  onChange={(
                    event: React.ChangeEvent<{ value: unknown }>,
                    value: number
                  ) => this.onChangeTabs(value as number)}
                >
                  {this.getTabList(true).map(({ label,permitted }: ITabListItem) => {
                    return (
                        renderBaseOnConditions(
                          permitted as boolean,
                          <StyledTabs
                            label={label}
                            key={"tab-item-" + label}
                            className={"customTab"}
                          />
                          ,
                          <></>
                        )
                      
                    );
                  })}
                </CustomTabs>
              </TabContainer>
            </div>

            <div className={"headerWrapperOrder"}>
              <div className={"pageHeaderOrder"} />
              <div className={"headerButtonPartAccountOrder"}>
                {this.isAdjustmentTabLoad() || this.getCurrentSelectedTab().apiKey === "dispatched" ? (
                  <></>
                ) : (
                  this.renderPlantActionDropdown()
                )}
                <div
                  data-test-id='filterButton'
                  onClick={(event) => this.handleFilterAnchor(event)}
                  className={sortStringCondition(
                    this.state.isAppliedFilter,
                    "filterButtonAccountOrder active-border",
                    "filterButtonAccountOrder"
                  )}
                >
                  <FilterIcon color={this.handleReturnColorType()} />
                  {renderBaseOnConditions(
                    this.state.isAppliedFilter,
                    <div className="filterActiveIcon"></div>,
                    <></>
                  )}
                </div>
                <FilterPopover
                  onClose={() => this.handleFilterAnchor()}
                  anchor={this.state.filterAnchor as HTMLDivElement | undefined}
                  onFilterChange={this.handleFilterChange}
                  onAutoCompleteChange={(title: string, value: string) =>
                    this.handleAutocompleteFilterChange(title, value)
                  }
                  onClearFilter={() => this.handleClearFilter()}
                  refreshFilterState={(filters: IFilter[]) =>
                    this.handleSetStateFilter(filters)
                  }
                  filters={this.state.filters as IFilter[]}
                />
              </div>
            </div>
          </Box>
          <Box className='mt-24 contentWrapperOrder'>
            <Box>
              <OrderTable>
                <RowContainer header>
                  <TableRow
                    columns={this.getTableRowColumns()}
                  >
                    {this.state.selectedOption && (
                      <TableCell
                        header
                        className='pl-0 align-item-center'
                        content={
                          <img
                            data-test-id='checkbox-selectAll'
                            className='cursor-pointer'
                            onClick={() => this.onSelectAllOrders()}
                            src={
                              this.getConditionBased(
                                this.state.orderList.length ===
                                  this.state.selectedOrders.length,
                                CheckTrue,
                                CheckFalse
                              ) as string
                            }
                          />
                        }
                      />
                    )}
                    {[
                      {
                        title: configJSON.stringsListPage_orderId,
                        sortingKey: "order_number",
                      },
                      {
                        title: configJSON.stringsListPage_orderCreated,
                        sortingKey: "created_at",
                      },
                      {
                        title: configJSON.stringsListPage_customer,
                        sortingKey: "customer",
                      },
                      {
                        title: configJSON.stringsListPage_order,
                        sortingKey: "",
                      },
                      {
                        title: configJSON.stringsListPage_pcs,
                        sortingKey: "",
                      },
                      {
                        title: configJSON.stringsListPage_notes,
                        sortingKey: "",
                      },
                      {
                        title:
                          this.displayTableColumnBasedOnTabGroup() &&
                          configJSON.stringsListPage_adjustmentReason,
                        sortingKey: "",
                      },
                      {
                        title:
                          this.displayTableColumnBasedOnTabGroup() &&
                          configJSON.stringsListPage_adjustmentNote,
                      },
                    ]
                      .filter((item: { title: string }) => {
                        return item.title;
                      })
                      .map(
                        ({
                          title,
                          sortingKey,
                        }: {
                          title: string;
                          sortingKey?: string;
                        }) => (
                          <TableCell
                            header
                            key={title}
                            content={title}
                            className='f-16 align-item-center'
                            sortingKey={sortingKey}
                            sortingIcons={[
                              IconSortingNormal,
                              IconSortingUp,
                              IconSortingDown,
                            ]}
                            sortingDisabled={!Boolean(sortingKey)}
                            {...this.getSortingProps()}
                          />
                        )
                      )}
                  </TableRow>
                </RowContainer>
                {
                  this.isLoadingOrders() ? (
                  <RowContainer>
                    <Box className='plantLoader'>
                      <CircularProgress />
                    </Box>
                  </RowContainer>
                ) : (
                  <RowContainer>
                    {this.state.orderList.length === 0 && (
                      <TableRow key={"NoDataTableRow"} columns='1fr'>
                        <TableCell
                          type='none'
                          className='w-100'
                          content={
                            <Box className='no_records'>
                              {configJSON.stringsListPage_noRecordsFound}
                            </Box>
                          }
                        />
                      </TableRow>
                    )}
                    {this.state.orderList.map((item: IDataItem, index) => {
                      const { selectedOrders } = this.state;
                      const formatted_created_at = this.getMomentFormat(
                        item.attributes.placed_at
                      );
                      const varId = item.id;
                      return (
                        <TableRow
                          key={index + "TableRow"}
                          className='table-rows'
                          columns={this.getTableRowColumns()}
                        >
                          {this.getConditionBased(
                            Boolean(this.state.selectedOption),
                            <TableCell
                              type='none'
                              className='pl-0 align-self-center'
                              content={
                                <img
                                  onClick={() =>
                                    this.onToggleOrderSelection(
                                      item.attributes.id
                                    )
                                  }
                                  className={"cursor-pointer"}
                                  data-test-id={`singleCheckbox${index}`}
                                  src={
                                    this.getConditionBased(
                                      selectedOrders.includes(
                                        item.attributes.id
                                      ),
                                      CheckTrue,
                                      CheckFalse
                                    ) as string
                                  }
                                />
                              }
                            />,
                            <></>
                          )}
                          {[
                            item.attributes.order_number,
                            formatted_created_at,
                            item.attributes?.customer?.full_name || "--",
                            this.showOrder(
                              item.attributes.order_items || [],
                              () => this.onOpenOrderDetails(item),
                              Boolean(item.attributes.is_quick_drop),
                            ) || "--",
                            item.attributes.total_pieces || "--",
                            this.showPlantSNotes(
                              item.attributes.notes,
                              varId,
                              item.attributes.internal_status
                            ) || "--",
                            this.displayTableColumnBasedOnTabGroup() &&
                              this.showReasonCell(item),
                            this.displayTableColumnBasedOnTabGroup() &&
                              this.showCommentCell(
                                item.attributes.adjustment_note,
                                varId,
                                item
                              ),
                          ]
                            .filter((item: unknown) => {
                              return item;
                            })
                            .map(
                              (
                                item: unknown,
                                index: number,
                                items: unknown[]
                              ) => (
                                <TableCell
                                  style={{wordBreak:"break-all"}}
                                  content={item as React.ReactNode}
                                  className='f-12'
                                  type={this.getTypeOfOrderTable(index)}
                                />
                              )
                            )}
                        </TableRow>
                      );
                    })}
                  </RowContainer>
                )}
              </OrderTable>
            </Box>

            {this.getConditionBased(
              Boolean(this.state.orderList.length > 0),
              <>
                {this.renderResendButton()}
                <TablePagination
                  data-test-id='pagination'
                  className={this.getPaginationClasses()}
                  rowsPerPageOptions={[100]}
                  component='div'
                  count={this.state.meta.total_count}
                  rowsPerPage={100}
                  page={this.state.meta.current_page - 1}
                  onPageChange={(
                    event: React.MouseEvent<HTMLElement> | null,
                    page: number
                  ) => this.onChangePage(page + 1)}
                />
              </>,
              <></>
            )}
            {this.getConditionBased(
              this.shouldShowActionButtons(),
              <BottomContainer className='pb-48'>
                <button
                  className='cancel w-128'
                  data-test-id='btn-cancel'
                  onClick={() => this.onCancel()}
                >
                  {configJSON.stringsListPage_cancel}
                </button>
                <button
                  data-test-id={configJSON.btnSubmitTestId}
                  className='add w-128'
                  onClick={() => this.onUpdateOrders()}
                  style={{ display: "flex", gap: "10px"}}
                  disabled={this.state.isUpdateStatusLoading}
                >
                  {this.getUpdateButton()}
                </button>
              </BottomContainer>,
              <></>
            )}
            
          </Box>
        </StyledWrapper>
      </>
    )
  }

  renderEditPreferenceModal() {
    const { adjustOrder, adjustmentSelectUpChargeModal } = this.state;
    return (
      <EditPreference
        navigation={undefined}
        id={""}
        laundryOrder={adjustOrder}
        open={adjustmentSelectUpChargeModal}
        handleClosePopup={this.handleCloseEditPreferencesPopup}
        handleOnSavePreference={this.handleSavePreference}
        isCfPlantAdjustmentscreen={true}
      />
    )
  }

  renderResendButton = () => {
    if (!this.shouldShowResendButton()) return null
    return (
      <ResendButtonContainer>
        {
          this.state.selectedOption ?
            <>
              <button
                className='btn-cancel'
                data-test-id='btn-cancel'
                onClick={() => this.onCancel()}
              >
                {configJSON.stringsListPage_cancel}
              </button>
              <button
                data-test-id="btn-proceed"
                className='btn-proceed'
                onClick={() => this.handleResendOrders()}
              >
                Resend Selected
              </button>
              <button
                data-test-id="btn-proceed"
                className='btn-proceed'
                onClick={() => this.handleResendOrders(true)}
              >
                Resend All Orders
              </button>
            </>
            :
            <button
              data-test-id="btn-resend"
              className="btn-proceed"
              onClick={() => this.onChangeSelectedOption("metal_progetti")}
            >
              Resend Orders
            </button>
        }
      </ResendButtonContainer>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      {!this.state.isPermittedPage ?
        this.getMainPlanRender(): 
        <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.isPermittedPage}
          >
            <Alert severity='warning'>{configJSON.permissionMessage}</Alert>
          </Snackbar>
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const WrapperSectionTabs = styled("div")({
  marginTop: 16,
  maxWidth: "100%",
  paddingBottom: 12,
  overflowY: "auto",
  textAlign: "center",
  display: "flex",
  justifyContent: "space-between"
});

const StyledSectionTabs = styled(Tabs)({
  display: "inline-flex",
  borderBottom: `1px solid ${colors().lightborder}`,
  "& .MuiTab-root": {
    fontSize: 14,
    fontWeight: 400,
    minWidth: "100px",
    textTransform: "none",
    color: colors().darkliver,
    "&.Mui-selected": {
      fontWeight: "bold",
      color: colors().cyancobaltblue,
    },
  },
  "& .MuiTabs-indicator": {
    backgroundColor: `${colors().cyancobaltblue} !important`,
  },
  "& .MuiPaper-elevation": {
    boxShadow: "none",
  },
});

const PaymentDropdown = styled(Select)({
  marginTop: 16,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors().lightborder,
  },
  "& .MuiInputBase-input": {
    fontSize: 14,
  },
  "& .MuiOutlinedInput-input": {
    padding: 12,
    color: colors().darkliver,
  },
});

const AdjustOrderRadio = styled(StyledRadio)({
  padding: 0,
});

const AdjustOrderContainer = styled("div")({
  "& .title": {
    fontSize: 24,
    fontWeight: 600,
    paddingBottom: 16,
    textAlign: "center",
    fontFamily: "Montserrat",
  },
  "& .select-label": {
    color: colors().charcoal,
  },
  "& .shadow-box": {
    padding: 24,
    borderRadius: 12,
    boxShadow: "0px 2px 8px 0px #00000014",
  },
  "& .reasonSelect": {
    minWidth: "340px !important",
    padding: 0,
    "& .MuiSelect-select": {
      margin: 0,
      fontSize: 16,
    },
  },
  "& .flex": {
    gap: 24,
    fontSize: 16,
    paddingTop: 16,
    display: "flex",
    alignItems: "center",
    "& .MuiIconButton-root": {
      padding: 0,
      paddingRight: 8,
    },
  },
  "& .adjust-order-product-container": {
    gridGap: 24,
    paddingTop: 24,
    display: "grid",
    gridTemplateColumns: "1fr 452px",
    "& .product-totals": {
      textAlign: "left",
      "& .order-summary-total": {
        fontSize: 16,
        marginTop: 32,
        borderRadius: 8,
        padding: "16px 24px",
        color: colors().white,
        backgroundColor: colors().orange,
        "& .total-item": {
          fontSize: 14,
          fontWeight: 600,
          display: "flex",
          paddingBottom: 8,
          justifyContent: "space-between",
        },
        "& .total-item:last-child": {
          paddingBottom: 0,
        },
        "& hr": {
          marginTop: 8,
          border: "none",
          marginBottom: 16,
          borderBottom: `1px solid ${colors().white}`,
        },
        "& .total": {
          fontSize: 16,
        },
      },
      "& .product-total-items": {
        fontSize: 14,
        "& .product-sub-items": {
          gap: 8,
          display: "flex",
          marginBottom: 16,
          flexFlow: "column",
          padding: "16px 0px",
          borderBottom: `1px solid ${colors().lightborder}`,
        },
        "& .mt-10": {
          margin: "10px 0",
        },
        "&:last-child .product-sub-items": {
          marginBottom: 0,
          paddingBottom: 0,
          borderBottom: `none`,
        },
        "& .total": {
          fontWeight: 500,
          color: colors().viewTextColor,
        },
        "& .pcs": {
          gap: 4,
          display: "flex",
          borderRadius: 8,
          padding: "5px 12px",
          alignItems: "center",
          color: colors().white,
          justifyContent: "center",
          background: colors().orange,
        },
        "& .qty-input": {
          width: "35px",
          background: "transparent",
          outline: "none",
          border: "1px solid transparent",
          textAlign: "center",
          color: colors().white,
        },
        "& .qty-input:focus": {
          border: "1px solid white",
        },
        "& .total-wrapper": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0 0 12px 0",
        },
        "& .specification-data-show": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          "& .specification-span": {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: colors().darkjunglegreen,
          },
          "& .slash": {
            margin: "0 4px",
          }
        },
        "& .flex110": {
          flex: "1 1 0"
        },
        "& .item-grid": {
          gap: 8,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          "& .grow": {
            flexGrow: 1,
          },
          "& .delete": {
            margin: "0 0 0 8px !important",
            border: "none",
            cursor: "pointer",
            backgroundColor: colors().white,
          },
          "& .MuiIconButton-root": {
            margin: 0,
            padding: 0,
            color: colors().white,
          },
        },
        "& .upcharge": {
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: "Montserrat",
        },
        "& .name": {
          fontWeight: 600,
          color: colors().darkliver,
        },
        "& .item-name": {
          display: "flex",
          flexWrap: "wrap",
          flexGrow: 1,
          fontWeight: 500,
          color: colors().darkjunglegreen,
          "& .circle": {
            height: "3px",
            width: "3px",
            background: colors().black,
            borderRadius: "50%",
            margin: "0 6px",
          },
          "& .mx-6": {
            margin: "0 6px",
          },
          "& .mr-6": {
            margin: "0 6px 0 0",
          },
        },
        "& .upcharge-name": {
          fontSize: "12px",
        },
      },
      "& .flex ": {
        paddingTop: 0,
        paddingBottom: 16,
      },
      "& .space-between": {
        justifyContent: "space-between",
      },
      "& .title": {
        fontSize: 15,
        fontWeight: 600,
        paddingBottom: 0,
      },
      "& .action-btn": {
        fontSize: 14,
        border: "none",
        fontWeight: 500,
        borderRadius: 8,
        cursor: "pointer",
        padding: "6px 10px",
        color: colors().cyancobaltblue,
        backgroundColor: colors().cyancobaltblue + "1A",
      },
    },
    "& .product-item-container": {
      gap: 8,
      display: "grid",
      textAlign: "center",
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
      "& .link-button": {
        fontSize: 14,
        fontWeight: 700,
        cursor: "pointer",
        color: colors().cyancobaltblue,
        "&:hover": {
          textDecoration: "underline",
        },
      },
      "& .flex-cols": {
        gap: 12,
        display: "flex",
        flexFlow: "column",
      },
      "& .product-image": {
        margin: "auto",
        width: "128px",
        height: "128px",
        "& > img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
      "& .quantity": {
        gap: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .services": {
        gap: 24,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        "& button": {
          maxWidth: 32,
          paddingBottom: 2,
          borderBottom: `2px solid ${colors().white}`,
          "&.active": {
            borderBottom: `2px solid ${colors().cyancobaltblue}`,
          },

          "& img": {
            maxWidth: "100%",
          },
        },
      },
      "@media only screen and (max-width: 1024px)": {
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
      }
    },
    "& .border-box": {
      padding: 16,
      borderRadius: 8,
      border: "1px solid " + colors().lightborder,
    },
    "@media only screen and (max-width: 940px)": {
      gap: 24,
      display: "flex",
      flexFlow: "column",
    },
    "@media only screen and (max-width: 560px)": {
      "& .product-item-container": {
        gridTemplateColumns: "1fr !important",
      },
    },
    "@media only screen and (max-width: 450px)": {
      "& .heading": {
        flexWrap: "wrap",
        gap: 8,
      },
      "& .item-grid": {
        marginBottom: 8,
        paddingBottom: 8,
        flexFlow: "column",
        gap: "12px !important",
        borderBottom: `1px solid ${colors().lightborder}`,
      },
    },
    "&.change-product-popup": {
      display: "flex !important",
      justifyContent: "center",
      "& .product-item-container": {
        display: "flex !important",
        justifyContent: "center",
        flexWrap: "wrap",
      },
    },
  },
  "@media only screen and (max-width: 600px)": {
    "& .responsive": {
      flexFlow: "column",
      alignItems: "flex-start",
    },
  },
  "@media only screen and (max-width: 520px)": {
    "& .responsive-buttons": {
      flexFlow: "column-reverse",
      display: "flex",
      gap: 16,
    },
  },
});

const StyledWrapper = styled("div")({
  "& .extendedTableRow": {
    gridTemplateColumns: "45px 2fr 1fr 1fr 2fr 2fr 3fr"
  },
  "& .order-notes": {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    color: colors().cyancobaltblue,
    gap: "8px",
    fontSize: "14px",
    fontWeight: 500,
    maxWidth: '50%',
    width: "100%"
  },
  "& .notes-heading": {
    fontWeight: 600
  },
  "& .product-qty": {
    padding: 0,
    margin: 0,
    listStyle: "none",
    "& li": {
      display: "flex",
      gap: "8px"
    }
  },
  "& .breakWord":{
    wordBreak:"break-word"
  },
  "& .align-item-center": {
    alignItems: "center",
  },
  "& .text-center": {
    textAlign: "center",
  },
  "& .d-inlineFlex": {
    display: "inline-flex",
  },
  "& .mt-24": {
    marginTop: "24px !important",
  },
  "& .align-self-center": {
    alignSelf: "center",
  },
  "& .ml-auto": {
    marginLeft: "auto",
  },
  "& .fgrow1": {
    flexGrow: 1,
  },
  "& .w-100": {
    width: "100%",
  },
  "& .w-128": {
    width: 128,
  },
  "& .f-12": {
    fontSize: 12,
  },
  "& .f-16": {
    fontSize: 16,
  },
  "& .gap-16": {
    gap: 16,
  },
  "& .d-flex": {
    display: "flex",
    alignItems: "center",
  },
  "& .pb-48": {
    paddingBottom: 48,
  },
  "& .pt-0": {
    paddingTop: 0,
  },
  "& .pl-0": {
    paddingLeft: 0,
  },
  "& .my-10": {
    marginTop: 10,
    marginBottom: 10,
  },
  "& .mw-105": {
    minWidth: 105,
  },
  "& .mw-132": {
    minWidth: 132,
  },
  "& .mw-186": {
    minWidth: 186,
  },
  "& .mw-206": {
    minWidth: 206,
  },
  "& .p-8": {
    padding: 8,
  },
  "& .p-16": {
    padding: 16,
  },
  "& .no_records": {
    width: "100%",
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
  },
  "& .cursor-pointer": {
    cursor: "pointer",
  },
  "& .custom-tabs": {
    backgroundColor: "#F8FAFC",
    display: "inline-flex",
    margin: "0px",
    "& .MuiTabs-scroller": {
      display:"flex"
    },
    "& .MuiTabs-scrollButtons": {
      background:"#F8FAFC",
      width: 20
    },
    "@media only screen and (max-width: 1024px)": {
      margin: "15px 0px"
    }
  },
  "& .bordered-select": {
    minWidth: "260px",
    margin: "0 12px",
  },
  "& .bottom-container": {
    paddingTop: 0,
    justifyContent: "flex-end",
  },
  "& .color_4D4D4D": {
    color: "#4D4D4D",
  },
  "& .catalogue_name": {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: 5,
  },
  "& .printIcon2": {
    gap: 8,
    display: "flex",
    color: "rgba(32, 75, 156, 1)",
  },
  "& .printIcon": {
    padding: 2,
    marginLeft: 12,
    borderRadius: 4,
    cursor: "pointer",
    bgcolor: "rgba(32, 75, 156, 0.1)",
    "&.disabled": {
      opacity: 0.5,
      pointerEvents: "none",
      cursor: "none"
    }
  },
  "& .textAreaStyles": {
    width: "100%",
    padding: "8px",
    borderRadius: "8px",
    background: "#F8F8F8",
    height: "58px !important",
    border: "2px solid #ECECEC",
  },
  webStyles: {},
  "& .table": {
    minWidth: 700,
  },
  "& .mainWrapperOrder": {
    marginTop: "32px",
    fontFamily: "Montserrat",
  },
  "& .pageHeaderOrder": {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  "& .headerWrapperOrder": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .flex-wrap": {
    flexWrap: "wrap"
  },
  "& .gap-8": {
    gap: "8px"
  },
  "& .contentWrapperOrder": {
    "& .notesCell": {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  "& .headerButtonPartAccountOrder": {
    gap: 24,
    display: "flex",
    justifyContent: "flex-end",
    "@media only screen and (max-width: 1024px)": {
      gap: 12
    },
  },
  "& .filterButtonAccountOrder": {
    cursor: "pointer",
    position: "relative",
    background: "#FFFFFF",
    height: "56px",
    color: "#4d4d4d",
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${colors().lightborder}`,
    "@media only screen and (max-width: 1024px)": {
      width: "40px",
      height: "40px",
    },
  },
  "& .active-border" : {
    border: `1px solid ${colors().cyancobaltblue}`
  },
  "& .filterActiveIcon": {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    background: "#204B9C",
    minHeight: "15px",
    borderRadius: "50%"
  },
  "& .summary": {
    display: "flex",
    width: "100%",
    padding: "16px 32px",
    alignItems: "flex-start",
    gap: "176px",
    borderRadius: "12px",
    background: "#EC4E20",
    marginBottom: "24px",
  },
  "& .summaryLabel": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    width: "25%",
    display: "inlineBlock",
  },
  "& .summaryValue": {
    fontWeight: 600,
  },
  "& .detailBtn": {
    color: "#204B9C",
    textDecoration: "underline",
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "12px",
    cursor: "pointer",
  },
  "& .resend-btn": {
    textDecoration: "underline",
    fontWeight: 600,
    cursor: "pointer",
    width: "fit-content"
  },
  "& .cellText": {
    fontSize: "12px",
    fontWeight: 500,
    paddingTop: "16px",
  },
  "& .adjustmentTextArea": {
    height: 58,
    width: "100%",
    padding: "8px",
    borderRadius: "8px",
    background: "#F8F8F8",
    border: "2px solid #ECECEC",
  },
  "& .customTab": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    margin: "2px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px",
      padding: "0 5px",
      minHeight: "28px"
    }
  },
  "& .tabbingSection": {
    backgroundColor: "#f8fafc",
    borderRadius: "40px",
    margin: "0 auto",
    boxShadow: "none",
  },
  "& .inputField": {
    width: "100%",
    height: "56px",
    fontFamily: "Montserrat !important",
  },
  "& .formControl": {
    width: "100%",
    borderRadius: "8px",
    fontFamily: "Montserrat",
  },
  "& .addButton": {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "56px",
    width: "184px",
    borderRadius: "8px",
  },
  "& .adjustmentDialogContent": {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    padding: 24,
  },
  "& .adjustmentPara": {
    margin: "0 0 24px 0",
    textAlign: "center" as const,
  },
  "& .adjustmentReasonError": {
    color: "red",
    fontSize: "13px",
    margin: "8px 0",
  },
  "& .adjustWrapper": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  "& .reasonSelect": {
    minWidth: "220px",
  },
  "& .commentWrapper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    position: "relative",
    "& .text": {
      fontSize: 13,
      fontWeight: 500,
      color: colors().statusRed,
      padding: "6px 10px",
    },
  },
  "& .updateBtnWrap": {
    minWidth: "256px",
    textAlign: "right",
  },
  "& .disable-btn": {
    opacity: 0.85,
  },
  "& .plantLoader": {
    textAlign: "center",
    paddingBottom: 15,
  },
  "& .titleReviewOrder": {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "20px",
    fontFamily: "Montserrat",
  },
  "& .label-15-600": {
    fontSize: "15px",
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  "& .table-rows > div": {
    minHeight: "100px",
  },
  "& .table-rows > div:last-child": {
    position: "relative",
  },
  "& .credit-note-button": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& button": {
      gap: 6,
      border: 0,
      display: "flex",
      cursor: "pointer",
      padding: "6px 10px",
      textAlign: "center",
      borderRadius: "6px",
      alignItems: "center",
      color: colors().cyancobaltblue,
      backgroundColor: colors().cyancobaltblue + "1A",
      fontWeight: 600,
      "& span": {
        whiteSpace: "nowrap",
      },
    },
  },
  "& .credit-note-show-button": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    "& button": {
      gap: 6,
      display: "flex",
      cursor: "pointer",
      padding: "6px 10px",
      border: 0,
      textAlign: "center",
      borderRadius: "6px",
      backgroundColor: colors().cyancobaltblue + "1A",
      alignItems: "center",
      color: "gray",
      fontWeight: 600,
      "& span": {
        whiteSpace: "nowrap",
      },
    },
  },
  "& .flex-column": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .gap-24": {
    gap: "24px",
  },
  "& .gap-10": {
    gap: "10px",
  },
  "& .p-rl-15": {
    padding: "0 15px",
  },
  "& .warn-icon": {
    width: "64px",
    height: "64px",
    color: colors().cyancobaltblue,
  },
  "& .label-24-600": {
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  "& .label-20-400": {
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
  "& .done-btn": {
    width: "186px",
    height: "50px",
    color: colors().white,
    textTransform: "none",
    borderRadius: "8px",
    background: colors().cyancobaltblue,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "700",
  },
});

const UpdateButton = styled("button")({
  padding: "16px 32px",
  borderRadius: 8,
  background: "rgba(32, 75, 156, 1)",
  border: "none",
  outline: "none",
  fontFamily: "Montserrat",
  fontSize: 16,
  fontWeight: 600,
  color: "white",
  "@media only screen and (max-width: 1024px)": {
    padding: "10px 24px",
    fontSize: 14,
  },
});

const AdjustTag = styled("div")({
  width: "67px",
  padding: "4px 0",
  cursor: "pointer",
  textAlign: "center",
  borderRadius: "8px",
  color: colors().cyancobaltblue,
  background: colors().cyancobaltblue + "1A",
});

const StyledMenuItemWithBreakSpaces = styled(StyledMenuItem)({
  whiteSpace: "break-spaces",
});

const AdjustMenuItem = styled(StyledMenuItem)({
  gap: 8,
  fontSize: 14,
  padding: "9px 20px",
});

const ModifiedMenuItem = styled(StyledMenuItem)({
  fontSize: 16,
  padding: "10px 16px",
  lineHeight: "unset",
  fontFamily: '"Montserrat", sans-serif',
  "@media only screen and (max-width: 1024px)": {
    padding: "5px 16px",
    fontSize: 14,
  },
  "&.Mui-selected": {
    color: "rgba(32, 75, 156, 1)",
    backgroundColor: "unset",
  },
  "& .MuiIconButton-root": {
    padding: 0,
  },
});

const StyledTabs = styled(CustomTab)({
  padding: "10px 12px",
});

export const OrderDetailsDialog = styled(Dialog)({
  "& .MuiDialog-paper.MuiPaper-rounded": {
    borderRadius: 24,
  },
  "& .p-24": {
    padding: 24,
  },
  "& .p-32": {
    padding: 32,
  },
  "& .move-to-next-top": {
    alignSelf: "center",
    "& .sideText": {
      fontSize: 14,
      fontWeight: 500,
      paddingRight: 24,
      color: colors().cyancobaltblue,
      textAlign: "left",
      lineHeight: "22px",
      fontFamily: "Montserrat",
    },
  }
});

const AdjustmentErrorDialog = styled(Dialog)({
  fontFamily:"Montserrat",
  "& .adjustmentDialogContent": {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    padding: 24,
  },
  "& .mt-24": {
    margin: "24px 0 0 0",
  },
  "& .MuiDialog-paper.MuiPaper-rounded": {
    borderRadius: 24,
    width: "400px",
  },
});

export const OrderDetailsBottomContainer = styled(BottomContainer)({
  marginTop: 32,
  paddingTop: 0,
  paddingBottom: 24,
  position: "relative",
  display: "inline-flex",
  justifyContent: "center",
  "& .sideText": {
    top: 5,
    right: 0,
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: 24,
    color: "#204B9C",
    textAlign: "left",
    lineHeight: "22px",
    position: "absolute",
    fontFamily: "Montserrat",
    transform: "translateX(100%)",
  },
});

export const OrderDetailsHeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: 16,
  borderRadius: 12,
  border: "1px solid #DEDEDE",
  "& > div:first-child": {
    paddingBottom: 8,
  },
});

const ReviewOrderTableContainer = styled("div")({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  padding: 16,
  borderRadius: 12,
  gap: "32px",
  boxShadow: "0px 2px 8px 0px #00000014",
});

const ReviewOrderTableRowContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "16px",
  "& hr": {
    color: colors().lightborder,
  },
});

const ReviewOrderAddReasonTextArea = styled("textarea")({
  width: "100%",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  minHeight: "64px",
  padding: "8px",
});

const ReviewOrderTableRow = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  "& > div:nth-child(1)": {
    display: "flex",
    flexDirection: "column",
    "& > p:nth-child(1)": {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Montserrat",
    },
    "& > p:nth-child(2)": {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Montserrat",
      color: "#64748B",
    },
  },
  "& > div:nth-child(2)": {
    "& > p": {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Montserrat",
    },
  },
});

const OrderDetailsHeadingText = styled("div")({
  fontSize: 16,
  fontWeight: 500,
  color: "#1A1A1A",
  alignItems: "flex-start",
  flexDirection: "column",
  fontFamily: "Montserrat",
  justifyContent: "space-between",
  "& div": {
    margin: "0 0 6px 0",
  },
  "& div:last-child": {
    margin: "0",
  },
  "&.right-column": {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    justifyContent: "flex-start",
  }
});

export const ReviewOrderHeadingText = styled("div")({
  fontSize: 16,
  fontWeight: 500,
  display: "flex",
  color: "#1A1A1A",
  alignItems: "flex-start",
  flexDirection: "column",
  fontFamily: "Montserrat",
  justifyContent: "10px",
  "& div": {
    margin: "0 0 6px 0",
    "& span": {
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Montserrat",
    },
  },
  "& div:last-child": {
    margin: "0",
  },
});

export const TabContainer = styled('div')((props: StyledComponentProps & { isDrawerOpen: boolean }) => {
  let containerWidth = '';

  switch (true) {
    case props.isDrawerOpen && window.innerWidth <= 1024:
      containerWidth = "440px";
      break;
    case !props.isDrawerOpen && window.innerWidth <= 1024:
      containerWidth = "550px";
      break;
    case props.isDrawerOpen && window.innerWidth <= 1280:
      containerWidth = "550px";
      break;
    case !props.isDrawerOpen && window.innerWidth <= 1280:
      containerWidth = "710px";
      break;
    case props.isDrawerOpen && window.innerWidth <= 1366:
      containerWidth = "630px";
      break;
    case !props.isDrawerOpen && window.innerWidth <= 1366:
      containerWidth = "810px";
      break;
    case props.isDrawerOpen && window.innerWidth < 1560:
      containerWidth = "710px";
      break;
    default:
      containerWidth = "auto";
      break;
  }

  return {
    "& .MuiTab-fullWidth": {
      flexGrow: "unset",
      flexBasis: "unset",
    },
    "& .MuiTabs-flexContainer": {
      gap: 0,
      justifyContent: "flex-start",
      width: containerWidth,
      "@media only screen and (max-width: 1024px)": {
        paddingBottom: "3px"
      },
    },
  };
});

const BorderedSelect = styled(StyledSelect)({
  minWidth: 256,
  fontFamily: "Montserrat",
  "@media only screen and (max-width: 1024px)": {
    minWidth: "200px",
    height: 40
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ECECEC",
  },
});

export const StyledDialogWrapper = styled(Dialog)({
  "& .MuiDialog-paper": {
    maxWidth: "1139 !important",
    borderRadius: 24,
    padding: "24px 32px 0 32px",
    "@media only screen and (max-width: 1366px)": {
      maxWidth: "1040 !important",

    },
    "@media only screen and (max-width: 1240px)": {
      maxWidth: "940 !important",

    },
    "@media only screen and (max-width: 1024px)": {
      maxWidth: "750 !important",

    },
  },
  "& .credit-note-modal-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .headerTitle": {
    fontSize: "24px",
    fontWeight: 600,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "19px",
    },
  },
  "& .dialogContent": {
    boxShadow: "0px 2px 8px 0px #00000014",
    padding: "24px",
    "& .orderId": {
      fontSize: "23px",
      fontWeight: 600,
      "@media only screen and (max-width: 1024px)": {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
  },
  "& .tableHeading": {
    fontWeight: 600,
    fontSize: "19px",
    color: colors().viewTextColor,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
    },
  },
  "& .printBtn": {
    fontSize: 16,
    padding: 16,
    minWidth: 105,
    maxWidth: "fit-content",
    border: "none",
    fontWeight: 700,
    borderRadius: 8,
    cursor: "pointer",
    lineHeight: "24px",
    alignItems: "center",
    display: "inline-flex",
    gap: 8,
    justifyContent: "center",
    fontFamily: "Montserrat",
    color: "rgba(32, 75, 156, 1)",
    background: colors().antiFlashWhite,
    "&:disabled": {
      opacity: 0.5,
    },
    "@media only screen and (max-width: 1024px)": {
      padding: "8px 0",
      fontSize: 13
    }
  },
  "& .groupButton": {
    margin: "10px auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ["& button"]: {
      textTransform: "capitalize",
      padding: "18px 35px",
      fontWeight: 600,
      fontSize: 16,
      borderRadius: 8,
      width: 235,
      "&:disabled": {
        opacity: 0.5,
      },
    },
  },
  "& .cancelButton": {
    color: colors().slateGray,
    background: colors().antiFlashWhite,
    marginRight: 20,
    ["&:hover"]: {
      backgroundColor: colors().antiFlashWhite,
    },
    height: "56px",
    width: "184px",
    "@media only screen and (max-width: 1024px)": {
      height: "44px",
      width: "128px",
    }
  },
  "& .addButton": {
    color: colors().background,
    background: colors().cyancobaltblue,
    ["&:hover"]: {
      backgroundColor: colors().cyancobaltblue,
    },
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    "@media only screen and (max-width: 1024px)": {
      height: "44px",
      width: "128px",
    }
  },
  "& .addMoreIcon": {
    marginTop: "20px",
    marginLeft: 20,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  "& .plusICon": {
    fontSize: "16px",
    fontWeight: 600,
    color: colors().cyancobaltblue,
    marginLeft: "8px",
  },
  "& .textAreaStyles": {
    width: "100%",
    padding: "8px",
    borderRadius: "8px",
    height: "58px !important",
    border: "2px solid #ECECEC",
    "@media only screen and (max-width: 1024px)": {
    height: "48px !important",
    fontSize: "13px",
    
     
    },
  },
  "& .addedNewUpcharge": {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    textAlign: "left",
    color: colors().viewTextColor,
    margin: "10px 0 40px",
    padding: "16px",
    borderTop: "1px solid #ECECEC",
    fontFamily: "Montserrat",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "15px",
    margin: "10px 0 10px",
    padding: "6px",
    },
  },
  "& .adjustmentDetails": {
    paddingLeft: "16px",
    color: colors().viewTextColor,
    fontSize: "18px",
    fontWeight: 600,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
    },
  },
  "& .confirmationText": {
    color: colors().darkjunglegreen,
    fontWeight: 400,
    fontSize: "16px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "14px",
    },
  },
  "& .confirm-text-container": {
    gap: 12,
    paddingTop: 8,
    display: "flex",
    alignItems: "center",
  },
  "& .tableheaderRow": {
    "$ .MuiTableRow-root": {
      backgroundColor: "gold",
    },
    color: colors().background,
    backgroundColor: colors().ghostwhite,
    "& .tableHeader": {
      fontSize:"14px",
      fontWeight:700,
      color:"#475569",
      minWidth:"160px",
      "@media only screen and (max-width: 1024px)": {
        fontWeight:600,
        fontSize:"13px",
      },
    }
  },
  "& .taxField": {
    width: "70%",
  },
  "& .adjustmentAmountField": {
    width: "90%",
  },
});
export const StyledSelectAdjustmentReason = styled(Select)({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors().lightborder,
  },
  "&.MuiOutlinedInput-root": {
    fontSize: 14,
    borderRadius: 8,
    color: colors().slateGray,
    width: "350 !important",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  "& .MuiSelect-root": {
    padding: "15px 8px",
    paddingRight: 32,
  },
  "& .MuiSelect-icon": {
    color: colors().darkjunglegreen,
  },
});

export const TextLabel = styled("label")({
  display: "block",
  fontSize: 16,
  lineHeight: "24px",
  fontWeight: 600,
  paddingBottom: 4,
  color: `var(--neutrals-cool-gray-700, ${colors().charcoal})`,
  marginTop: "20px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },
});

const SelectUpchargeItemNameRow = styled("div")({
  display: "flex",
  gap: "5px",
  "& p": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: colors().cyancobaltblue,
  },
});

const SelectUpchargeRow = styled("div")({
  gap: "24px",
  display: "grid",
  alignItems: "flex-start",
  gridTemplateColumns: "148px 1fr",
  "& p:first-child": {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    whiteSpace: "pre",
    padding: "8px",
  },
  "& > div": {
    gap: "10px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    "& > div:first-child": {
      rowGap: "16px",
      display: "grid",
      flexWrap: "wrap",
      columnGap: "32px",
      gridTemplateColumns: "1fr 1fr",
    },
    "& > div:last-child": {
      marginLeft: "60px",
      "& > select": {
        padding: "10px 8px 10px 8px",
        borderRadius: "8px",
        border: `1px solid ${colors().lightborder}`,
        fontSize: "12px",
        fontWeight: 400,
        background: colors().white,
        fontFamily: "Montserrat",
        width: "200px",
      },
    },
  },
});

const SelectUpchargeCheckboxContainer = styled("div")({
  gap: "10px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  "& img": {
    objectFit: "contain",
  },
  "& > input": {
    width: "20px",
    height: "20px",
  },
  "& > div:last-child": {
    borderRadius: "8px",
    display: "flex",
    minWidth: "268px",
    justifyContent: "space-between",
    padding: "8px",
    backgroundColor: colors().brightgray,
    boxSizing: "border-box",
    // color: colors().cyancobaltblue,
    fontSize: "16px",
    fontWeight: 500,
    flexGrow: 1,
    fontFamily: "Montserrat",
    "& > div:first-child": {
      display: "flex",
      gap: "5px",
    },
    border: `1px solid ${colors().brightgray}`,
  },
  "& > div.inActiveCheckbox": {
    border: `1px solid ${colors().lightborder}`,
    background: colors().white,
  },
  "& .inActiveCheckbox": {
    color: colors().black
  },
  "& .activeCheckBox": {
    color: colors().cyancobaltblue
  }
});

const SpecificationSelectContainer = styled("div")({
  marginLeft: 30,
  position: "relative",
  "& .label": {
    top: -11,
    left: 11,
    zIndex: 1,
    fontSize: 13,
    fontWeight: 500,
    padding: "0px 6px",
    position: "absolute",
    color: colors().darkliver,
    backgroundColor: colors().white,
  },
  "& .MuiSelect-root": {
    padding: 14,
    paddingTop: 16,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${colors().lightborder}`,
    borderRadius: 8,
  },
});

const CustomSelect = styled(Select)({
  padding: "10px 8px",
  borderRadius: "8px",
  border: `1px solid ${colors().lightborder}`,
  fontSize: "12px",
  fontWeight: 400,
  background: colors().white,
  fontFamily: "Montserrat",
  width: "200px",
  height: "32px",
  "&:focus": {
    borderRadius: "8px",
    borderColor: colors().cyancobaltblue,
    outline: "none",
  },
});

const UpchargeSelectMenuItem = styled(MenuItem)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: "8px",
  paddingBottom: "8px",
  "&.MuiListItem-root.Mui-selected": {
    backgroundColor: `${colors().brightgray} !important`,
  },
  "& .upchargeName": {
    fontSize: "15px",
    fontFamily: "Montserrat",
  },
  "& .upchargeActiveName": {
    fontSize: "15px",
    fontFamily: "Montserrat",
    color: colors().cyancobaltblue
  },
  "& .dropdownUpchargeName": {
    fontSize: "14px",
    fontFamily: "Montserrat",
  },
  "& .dropdownUpchargeActiveName": {
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: colors().cyancobaltblue
  },
  "& .upchargeIcon": {
    width: "18px",
    height: "18px",
    borderRadius: "4px",
    margin: "0 8px",
  },
  "& .d-flex": {
    display: "flex",
    alignItems: "center",
  },
});

const ResendButtonContainer = styled("div")({
  position: "absolute",
  bottom: 4,
  width: "fit-content",
  display: "flex",
  gap: 10,
  background : "#f8fafc",
  boxShadow: "0px 2px 8px 0px #00000014",
  padding: 8,
  zIndex: 1,
  "& > button": {
    border: 0,
    color: colors().background,
    background: colors().cyancobaltblue,
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 600,
    minWidth: 150,
    padding: 12,
    cursor: "pointer",
    "&.btn-cancel": {
      color: colors().slateGray,
      background: colors().antiFlashWhite,
    }
  }
})

const SubProductModal = styled(Dialog)({
  "& .MuiDialog-paperScrollPaper": {
    borderRadius: "24px",
  },
  "& .dialogTitle": {
    fontSize: "20px",
    textAlign: "center",
  },
  "& .dialogActionWrapper": {
    justifyContent: "center",
    paddingTop: "24px",
    paddingBottom: "32px",
  },
});

export const CreditNoteInput = styled("input")({
  width: "100%",
  fontSize: 14,
  fontWeight: 400,
  borderRadius: 8,
  border: "1px solid " + colors().lightborder,
  padding: "10px 8px 10px 8px",
  "&.tax": {
    width: 100,
  },
  "&.amount": {
    width: 150,
  },
});

export const PriceGrid = styled(Grid)({
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },

});
export const MainWrapper = styled(Box)({
  "@media only screen and (max-width: 1024px)": {
   "& .MuiTableCell-root" :{
      padding: 13,
      fontSize: 13,
 
    }
  },

});
const ShowPreferenceWrapper = styled("div")({
  "&.specification-data-show": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .slash-span": {
      margin: "0 4px",
    },
    "& .slash-span:first-child": {
      display: "none"
    },
    "& .specification-span-tag": {
      fontSize: "12px",
      fontFamily: "Montserrat",
      color: colors().darkjunglegreen,
      textTransform: "capitalize"
    }
  },
});

const ShowAllUpcharges = styled("div")({
  "& span": {
    color: colors().darkjunglegreen,
    fontSize: "13px",
    fontWeight: 400,
  },
  "& .mr-6": {
    margin: "0 6px 0 0"
  },
  "& .d-flex": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  "& .circle": {
    height: "3px",
    width: "3px",
    background: colors().darkjunglegreen,
    margin: "0 6px",
    borderRadius: "50%",
  }
});

export const PreferenceTooltip = withStyles({
  tooltip: {
    color: "black",
    fontSize: "12px",
    backgroundColor: "white",
    width: "300px",
    maxWidth: "300px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
  arrow: {
    color: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
})(Tooltip);

const StyledActionsGroup = styled(Box)({
  display: "flex",
  justifyContent: "end",
  marginBottom: 16,
  gap: 16,
  fontFamily: "Montserrat",
  "& > .MuiButton-root": {
    padding: 12,
    backgroundColor: colors().cyancobaltblue,
    color: "white",
    "&.off-btn": {
      backgroundColor: colors().antiFlashWhite,
      color: colors().slateGray,
    }
  }
})
// Customizable Area End
