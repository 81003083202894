import React from "react";

// Customizable Area Start
import {
  Typography,
  Button,
  Modal,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Snackbar,
  FormControl,
  Grid,
  IconButton,
  styled,
  InputAdornment
} from "@material-ui/core";

import AddCircleOutlineOutlined from "@material-ui/icons/AddCircleOutlineOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { StyledCheckBox } from "../../../components/src/CustomTheme.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import {
  SaveAndCancelButton,
  HeaderText,
  DefultBox,
  SelectFieldCustom,
  InputLabel,
  CustomStyledTextField,
} from "../../../components/src/customComponents/CustomTags.web";

import CfCompanyInfoController, {
  Props,
  configJSON,
} from "./CfCompanyInfoController";
import { imgDelete, imgPlus } from "./assets";
import { CustomAutoCompleteSearchable } from "../../../components/src/customComponents/CustomAutoCompleteSearchable.web";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End
export default class CfCompanyInfo extends CfCompanyInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTextInput = (
    titleInput: string,
    options: {
      placeHolder?: string;
      value?: string;
      onChange?: (value: string) => void;
      dirInput?: string;
    },
    error?: string | null,
    showDeleteIcon?: boolean,
    requiredType: boolean = false
  ) => {
    const formErrors = this.state.formErrors;
    return (
      <Grid item xs={12} sm={6} style={webStyle.columnContainer}>
        {titleInput && (
          <div>
            <InputLabelContainer>
              <InputLabel style={{ marginBottom: "0px" }}>
                {titleInput} {sortCondition(requiredType, " *", "")}
              </InputLabel>
              {showDeleteIcon && (
                <IconButton
                  data-test-id='fieldDeleteBtn'
                  onClick={() => this.handleDeleteDynamicField(titleInput)}
                  className='deleteIconBtn'
                  aria-label='delete'
                  size='small'
                >
                  <DeleteIcon className='deleteIcon' fontSize='inherit' />
                </IconButton>
              )}
            </InputLabelContainer>
            <CustomStyledTextField
              key={titleInput}
              value={options.value}
              inputProps={{
                "data-test-id": titleInput,
              }}
              dir={options.dirInput}
              error={!!formErrors[titleInput]}
              variant='outlined'
              type={"text"}
              placeholder={options.placeHolder || "Enter " + titleInput}
              onChange={(e) => {
                options.onChange && options.onChange(e.target.value);
              }}
            />
            <span style={webStyle.errorText}>
              {formErrors[titleInput] ||
                (error && (
                  <div style={webStyle.errorText}>
                    {formErrors[titleInput] || error}
                  </div>
                ))}
            </span>
          </div>
        )}
      </Grid>
    );
  };

  renderSelectInput = (
    titleSelect: string,
    selectOptions: { text: string; value: string }[] | string[],
    options: {
      showDefaultRadio?: boolean;
      isDefault?: boolean;
      onDefaultClick?: () => void;
      placeHolder?: string;
      value?: string;
      onChange?: (value: string) => void;
    },
    showDeleteIcon?: boolean,
    error?: string | null
  ) => {
    const placeHolder = options.placeHolder || "Select " + titleSelect;
    let fixedSelectOptions: { text: string; value: string }[] = [];
    if (typeof selectOptions[0] === "string") {
      selectOptions = selectOptions as string[];
      fixedSelectOptions = selectOptions.map((option: string) => ({
        text: option,
        value: option,
      }));
    } else {
      fixedSelectOptions = selectOptions as { text: string; value: string }[];
    }
    const formErrors = this.state.formErrors;

    const CustomRadio: React.FC<{ checked: boolean; onClick: () => void }> = ({
      checked,
      onClick,
    }) => (
      <Radio
        icon={
          <span
            style={{
              display: "inline-block",
              borderRadius: "51%",
              width: 20,
              height: 20,
              border: "1px solid #64748C",
              marginRight: "10px",
            }}
          />
        }
        checked={checked}
        onClick={onClick}
        checkedIcon={
          <span
            style={{
              display: "inline-block",
              width: 20,
              borderRadius: "51%",
              height: 20,
              border: "6px solid #204B9D",
              color: "#204b9c",
              marginRight: "10px",
            }}
          />
        }
      />
    );

    return (
      <Grid item xs={12} md={6} style={webStyle.columnContainer}>
        {titleSelect && (
          <>
            <InputLabelContainer>
              <InputLabel style={{ marginBottom: "0px" }}>
                {titleSelect}
              </InputLabel>
              {showDeleteIcon && (
                <IconButton
                  data-test-id='fieldDeleteBtn'
                  onClick={() => this.handleDeleteDynamicField(titleSelect)}
                  className='deleteIconBtn'
                  aria-label='delete'
                  size='small'
                >
                  <DeleteIcon className='deleteIcon' fontSize='inherit' />
                </IconButton>
              )}
            </InputLabelContainer>
            <SelectFieldCustom
              key={titleSelect}
              data-test-id={titleSelect}
              value={options.value}
              renderValue={(value) =>
                fixedSelectOptions.find((option) => option.value === value)
                  ?.text || placeHolder
              }
              onChange={(event) => {
                options.onChange &&
                  options.onChange(event.target.value as string);
              }}
              placeholder={placeHolder}
              displayEmpty
              variant='outlined'
              error={!!formErrors[titleSelect]}
            >
              {fixedSelectOptions.map((option) => (
                <MenuItem value={option.value}>{option.text}</MenuItem>
              ))}
            </SelectFieldCustom>
            {formErrors[titleSelect] ||
              (error && (
                <div style={{ ...webStyle.errorText }}>
                  {formErrors[titleSelect] || error}
                </div>
              ))}
          </>
        )}
        {options.showDefaultRadio && (
          <div
            style={webStyle.setAsDefault}
            data-test-id={"radio-" + titleSelect}
          >
            <CustomRadio
              checked={!!options.isDefault}
              onClick={() => {
                options.onDefaultClick && options.onDefaultClick();
              }}
            />
            <DefultBox>Set As Default</DefultBox>
          </div>
        )}
      </Grid>
    );
  };

  renderOthersInfoColumn = (
    columnKey: string,
    columnValue: string | { title: string; isSelected: boolean }[]
  ) =>
    Array.isArray(columnValue)
      ? this.renderSelectInput(
          columnKey,
          columnValue.map((item) => item.title),
          {
            value: columnValue.find((item) => item.isSelected)?.title,
            onChange: (value) => {
              this.setState({
                companyInfo: {
                  ...this.state.companyInfo,
                  attributes: {
                    ...this.state.companyInfo.attributes,
                    others: {
                      ...this.state.companyInfo.attributes.others,
                      [columnKey]: columnValue.map((item) => ({
                        ...item,
                        isSelected: item.title === value,
                      })),
                    },
                  },
                },
              });
            },
          },
          true,
          this.validateAddedField(this.state.addedFieldError, columnKey)
        )
      : this.renderTextInput(
          columnKey,
          {
            value: columnValue,
            onChange: (value) => {
              this.setState({
                companyInfo: {
                  ...this.state.companyInfo,
                  attributes: {
                    ...this.state.companyInfo.attributes,
                    others: {
                      ...this.state.companyInfo.attributes.others,
                      [columnKey]: value,
                    },
                  },
                },
              });
            },
          },
          this.validateAddedField(this.state.addedFieldError, columnKey),
          true
        );

  renderOthersInfo = () => {
    const otherInfoKeys = Object.keys(this.state.companyInfo.attributes.others);
    const returnVal = [];

    for (let i = 0; i < otherInfoKeys.length; i += 2) {
      const key1 = otherInfoKeys[i];
      const key2 = otherInfoKeys[i + 1];
      const value1 = this.state.companyInfo.attributes.others[key1];
      const value2 = this.state.companyInfo.attributes.others[key2];
      returnVal.push(
        <>
          {this.renderOthersInfoColumn(key1, value1)}
          {key2
            ? this.renderOthersInfoColumn(key2, value2)
            : this.renderTextInput("", {})}
        </>
      );
    }

    return returnVal;
  };
  renderVatDetails = () => {
    const returnVal = [];
    const renderVatInput = (index: number) => {
      if (index >= 0) {
        return this.renderSelectInput(
          configJSON.vatName + " " + (index + 1),
          this.state.taxList.map((item) => ({
            text: item.attributes.tax_name,
            value: item.attributes.id + "",
          })),
          {
            showDefaultRadio: true,
            isDefault:
              this.state.companyInfo.attributes.vat_details_attributes[index]
                ?.default_tax,
            onDefaultClick: () => this.onClickVatDefault(index),
            value:
              this.state.companyInfo.attributes.vat_details_attributes[index]
                ?.tax_calculator_id + "",
            onChange: (value) => {
              this.onChangeVatName(value, index);
            },
          }
        );
      } else {
        return this.renderTextInput("", {});
      }
    };
    const renderSingleRowInfo = (index: number) => (
      <div style={webStyle.rowContainer}>
        {renderVatInput(index)}
        {renderVatInput(-1)}
      </div>
    );

    const renderRowInfo = (index: number) => (
      <div style={webStyle.rowContainer}>
        {renderVatInput(index)}
        {renderVatInput(index + 1)}
      </div>
    );

    if (this.state.companyInfo.attributes.vat_details_attributes.length === 2)
      returnVal.push(renderSingleRowInfo(1));
    else if (
      this.state.companyInfo.attributes.vat_details_attributes.length === 3
    )
      returnVal.push(renderRowInfo(1));
    else if (
      this.state.companyInfo.attributes.vat_details_attributes.length === 4
    ) {
      returnVal.push(renderRowInfo(1));
      returnVal.push(renderSingleRowInfo(3));
    }

    return returnVal;
  };

  getTelephoneForB2b = () => {
    const formErrors = this.state.formErrors;
    return (
      <>
          <Grid item xs={12} sm={6} style={webStyle.columnContainer}>
            <InputLabelContainer>
              <InputLabel style={{ marginBottom: "0px" }}>
                Telephone *
              </InputLabel>
            </InputLabelContainer>
            <div style={webStyle.columnContainerTelephone}>
              <Grid item xs={4} sm={3} md={3} lg={2}>
                <CustomStyledTextField
                  inputProps={{
                    "data-test-id": "field-prefix",
                  }}
                  id='mobile_number_prefix'
                  name='mobile_number_prefix'
                  error={!!formErrors["prefix"]}
                  fullWidth={true}
                  value={this.state.telephonePrefix}
                  placeholder='Enter Prefix'
                  variant='outlined'
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => this.handlePrefixChange(event)}
                  InputProps={{
                    startAdornment: 
                    <InputAdornment style={{marginRight: "2px"}} position="start">
                    <p style={{color:"#4D4D4D",fontSize:"20px"}}>+</p>
                    </InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={8} sm={9} md={9} lg={10}>
                <CustomStyledTextField
                    key={"Telephone"}
                    value={this.state.companyInfo.attributes.telephone}
                    inputProps={{
                      "data-test-id": "Telephone",
                    }}
                    error={!!formErrors["Telephone"]}
                    variant='outlined'
                    type={"text"}
                    placeholder={"Enter Telephone Number"}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => this.handleTelephoneChange(event)
                    }
                />
                 <span style={webStyle.errorText}>
                    {formErrors["Telephone"] ||
                        <div style={webStyle.errorText}>
                          {formErrors["Telephone"]}
                        </div>
                      }
                  </span>
              </Grid>
            </div>
          </Grid>
      </>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <CustomLoader loading={this.state.isLoading} />
        <div style={webStyle.mainContainer}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={!!this.state.snackBarMessage}
            autoHideDuration={4000}
            onClose={this.closeSnackBar}
          >
            <Alert severity='success'>{this.state.snackBarMessage}</Alert>
          </Snackbar>
          <HeaderText style={{ margin: "30px 0px" }}>
            {this.state.isB2bActive ? configJSON.B2BcompanyInformation : configJSON.companyInformation}
          </HeaderText>

          <Grid container spacing={4} style={webStyle.formContainer}>
            {renderBaseOnConditions(
              this.state.isB2bActive,
              <Grid item xs={12} sm={12} style={webStyle.columnContainer}>
                <StyleSubCheckDiv>
                  <StyledCheckBox
                    dataTestId= "subCompanyCheck"
                    style={{
                      border: "1px solid #64748B",
                      borderRadius: "6px",
                    }}
                    onClick={() => this.handleSubCompanyClick()}
                    checked={this.state.isSubcompanyCheck}
                  />
                  <span>Sub Company</span>
                </StyleSubCheckDiv>
              </Grid>,
              <></>
            )}

            {renderBaseOnConditions(
              this.state.isSubcompanyCheck && this.state.isB2bActive,
              <Grid item xs={12} sm={12} style={webStyle.columnContainer}>
                {this.renderTextInput(
                  configJSON.b2bSubCompanyName,
                  {
                    value: this.state.companyInfo.attributes.name,
                    onChange: (value) => {
                      this.setState({
                        companyInfo: {
                          ...this.state.companyInfo,
                          attributes: {
                            ...this.state.companyInfo.attributes,
                            name: value,
                          },
                        },
                      });
                    },
                  },
                  null,
                  undefined,
                  true
                )}
              </Grid>,
              <></>
            )}

            {this.state.isB2bActive &&
              renderBaseOnConditions(
                !this.state.isSubcompanyCheck,
                this.renderTextInput(
                  configJSON.b2bCompanyName,
                  {
                    value: this.state.companyInfo.attributes.name,
                    onChange: (value) => {
                      this.setState({
                        companyInfo: {
                          ...this.state.companyInfo,
                          attributes: {
                            ...this.state.companyInfo.attributes,
                            name: value,
                          },
                        },
                      });
                    },
                  },
                  null,
                  undefined,
                  true
                ),
                <Grid item xs={12} sm={6} style={webStyle.columnContainer}>
                   <div>
                      <InputLabelContainer>
                        <InputLabel style={{ marginBottom: "0px" }}>
                          Main Company *
                        </InputLabel>
                      </InputLabelContainer>

                      <AutocompleteSelect
                        data-test-id='mainCompanyDropdown'
                        fieldName='main_company'
                        placeholder='Type to Select'
                        onChange={(
                          _event: React.ChangeEvent,
                          value: {
                            id: string;
                            option: string;
                          }
                        ) =>
                          this.handleMainCompanySelection(value)
                        }
                        value={this.state.selectedMainCompany?.id as string}
                        disableClear
                        options={this.state.mainCompanyList}
                        listBoxStyle={{ maxHeight: 270 }}
                        error={Boolean(
                          this.state.formErrors && this.state.formErrors["Main Company"]
                        )}
                      />
                       <span style={webStyle.errorText}>
                       {this.state.formErrors["Main Company"] && (
                          <div style={{ ...webStyle.errorText }}>
                            {this.state.formErrors["Main Company"]}
                          </div>
                        )}
                      </span>
                   </div>
                </Grid>
              )}

            {renderBaseOnConditions(
              !this.state.isB2bActive,
              this.renderTextInput(
                configJSON.companyName,
                {
                  value: this.state.companyInfo.attributes.name,
                  onChange: (value) => {
                    this.setState({
                      companyInfo: {
                        ...this.state.companyInfo,
                        attributes: {
                          ...this.state.companyInfo.attributes,
                          name: value,
                        },
                      },
                    });
                  },
                },
                null,
                undefined,
                true
              ),
              <></>
            )}

            {renderBaseOnConditions(
              !this.state.isB2bActive,
              this.renderTextInput(configJSON.companyNameArabic, {
                value: this.state.companyInfo.attributes.name_arabic,
                dirInput: "rtl",
                onChange: (value) => {
                  this.setState({
                    companyInfo: {
                      ...this.state.companyInfo,
                      attributes: {
                        ...this.state.companyInfo.attributes,
                        name_arabic: value,
                      },
                    },
                  });
                },
              }),
              <></>
            )}

            {this.renderTextInput(
              configJSON.registrationNumber,
              {
                value: this.state.companyInfo.attributes.registration_no,
                placeHolder: "Enter Registration Number",
                onChange: (value) => {
                  this.setState({
                    companyInfo: {
                      ...this.state.companyInfo,
                      attributes: {
                        ...this.state.companyInfo.attributes,
                        registration_no: value,
                      },
                    },
                  });
                },
              },
              null,
              undefined,
              true
            )}

            {renderBaseOnConditions(
              this.state.isB2bActive,
              <Grid item md={6} xs={12} style={webStyle.columnContainer}>
                <InputLabel>Country *</InputLabel>
                <CustomAutoCompleteSearchable
                  value={this.state.companyInfo.attributes.country_data}
                  dataTestID='country'
                  debouncedFunction={() => {}}
                  optionList={this.state.countries.map((region) => ({
                    option: region.name,
                    id: region.id + "",
                  }))}
                  handleSelectOptions={(value: any) => {
                    this.setState({
                      companyInfo: {
                        ...this.state.companyInfo,
                        attributes: {
                          ...this.state.companyInfo.attributes,
                          country_id: parseInt(value.id),
                          country_data: { id: value.id, option: value.option },
                        },
                      },
                    });
                  }}
                  handleScroll={this.handleScrollCountryDropdown}
                  selectedItem={this.state.companyInfo.attributes.country_data}
                  isError={Boolean(
                    this.state.formErrors && this.state.formErrors["Country"]
                  )}
                  placeholder={"Select Country"}
                />
                {this.state.formErrors["Country"] && (
                  <div style={{ ...webStyle.errorText }}>
                    {this.state.formErrors["Country"]}
                  </div>
                )}
              </Grid>,
              <></>
            )}

            {this.renderTextInput(
              configJSON.tinNo,
              {
                value: this.state.companyInfo.attributes.tin_no,
                placeHolder: "Enter TIN Number",
                onChange: (value) => {
                  this.setState({
                    companyInfo: {
                      ...this.state.companyInfo,
                      attributes: {
                        ...this.state.companyInfo.attributes,
                        tin_no: value,
                      },
                    },
                  });
                },
              },
              null,
              undefined,
              true
            )}

            <Grid item xs={12}>
              <InputLabel style={{ marginBottom: "20px" }}>
                {configJSON.vatDetails}
              </InputLabel>
              <div style={webStyle.rowContainerBordered}>
                <Grid container spacing={3}>
                  {this.renderSelectInput(
                    configJSON.numberOfVat,
                    ["1", "2", "3", "4"],
                    {
                      value: this.state.companyInfo.attributes.number_of_vats,
                      onChange: (value) => {
                        this.setState({
                          companyInfo: {
                            ...this.state.companyInfo,
                            attributes: {
                              ...this.state.companyInfo.attributes,
                              number_of_vats: value,
                            },
                          },
                        });
                      },
                    }
                  )}
                  {this.renderSelectInput(
                    configJSON.vatName + " " + (0 + 1),
                    this.state.taxList.map((item) => ({
                      text: item.attributes.tax_name,
                      value: item.attributes.id + "",
                    })),
                    {
                      showDefaultRadio: true,
                      isDefault:
                        this.state.companyInfo.attributes
                          .vat_details_attributes[0]?.default_tax,
                      value:
                        this.state.companyInfo.attributes
                          .vat_details_attributes[0]?.tax_calculator_id + "",
                      onDefaultClick: () => this.onClickVatDefault(0),
                      onChange: (value) => {
                        this.onChangeVatName(value, 0);
                      },
                    }
                  )}
                </Grid>
                {this.renderVatDetails()}
              </div>
            </Grid>

            {renderBaseOnConditions(
              this.state.isB2bActive,
             this.getTelephoneForB2b(),
              this.renderTextInput(
                "Telephone",
                {
                  value: this.state.companyInfo.attributes.telephone,
                  placeHolder: "Enter Telephone Number",
                  onChange: (value) => {
                    value = value.replace(/[^0-9+]/g, "");
                    this.setState({
                      companyInfo: {
                        ...this.state.companyInfo,
                        attributes: {
                          ...this.state.companyInfo.attributes,
                          telephone: value,
                        },
                      },
                    });
                  },
                },
                null,
                undefined,
                true
              )
            )}
            {this.renderTextInput(
              "E-Mail Address",
              {
                value: this.state.companyInfo.attributes.email,
                placeHolder: "Enter Email ID",
                onChange: (value) => {
                  this.setState({
                    companyInfo: {
                      ...this.state.companyInfo,
                      attributes: {
                        ...this.state.companyInfo.attributes,
                        email: value,
                      },
                    },
                  });
                },
              },
              null,
              undefined,
              true
            )}
            {this.renderTextInput(
              "Address",
              {
                value: this.state.companyInfo.attributes.address,
                placeHolder: "Enter Company/Area/City",
                onChange: (value) => {
                  this.setState({
                    companyInfo: {
                      ...this.state.companyInfo,
                      attributes: {
                        ...this.state.companyInfo.attributes,
                        address: value,
                      },
                    },
                  });
                },
              },
              null,
              undefined,
              true
            )}

            {renderBaseOnConditions(
              !this.state.isB2bActive,
              <Grid item xs={12} md={6} style={webStyle.columnContainer}>
                <InputLabel>Country *</InputLabel>
                <CustomAutoCompleteSearchable
                  dataTestID='country'
                  value={this.state.companyInfo.attributes.country_data}
                  optionList={this.state.countries.map((region) => ({
                    option: region.name,
                    id: region.id + "",
                  }))}
                  debouncedFunction={() => {}}
                  handleScroll={this.handleScrollCountryDropdown}
                  handleSelectOptions={(value: any) => {
                    this.setState({
                      companyInfo: {
                        ...this.state.companyInfo,
                        attributes: {
                          ...this.state.companyInfo.attributes,
                          country_id: parseInt(value.id),
                          country_data: { id: value.id, option: value.option },
                        },
                      },
                    });
                  }}
                  selectedItem={this.state.companyInfo.attributes.country_data}
                  placeholder={"Select Country"}
                  isError={Boolean(
                    this.state.formErrors && this.state.formErrors["Country"]
                  )}
                />
                {this.state.formErrors["Country"] && (
                  <div style={{ ...webStyle.errorText }}>
                    {this.state.formErrors["Country"]}
                  </div>
                )}
              </Grid>,
              <></>
            )}

            {renderBaseOnConditions(
              !this.state.isB2bActive,
              <>
                {this.renderTextInput(configJSON.VATNumber, {
                  value: this.state.companyInfo.attributes.vat_number,
                  onChange: (value) => {
                    this.setState({
                      companyInfo: {
                        ...this.state.companyInfo,
                        attributes: {
                          ...this.state.companyInfo.attributes,
                          vat_number: value,
                        },
                      },
                    });
                  },
                })}
                {this.renderTextInput(configJSON.VATNumberArabic, {
                  value: this.state.companyInfo.attributes.vat_number_arabic,
                  dirInput: "rtl",
                  onChange: (value) => {
                    this.setState({
                      companyInfo: {
                        ...this.state.companyInfo,
                        attributes: {
                          ...this.state.companyInfo.attributes,
                          vat_number_arabic: value,
                        },
                      },
                    });
                  },
                })}
              </>,
              <></>
            )}

            {this.renderOthersInfo()}
            <Grid item xs={12}>
              <Button
                data-test-id={"AddButton"}
                variant='contained'
                onClick={this.onAddButtonClick}
                size='small'
                style={{ ...webStyle.addButton, textTransform: "none" }}
                startIcon={<AddCircleOutlineOutlined />}
              >
                Add Field
              </Button>
            </Grid>
          </Grid>
          <Box
            component='div'
            style={{
              marginTop: "25px",
              paddingBottom: "50px",
            }}
          >
            <SaveAndCancelButton
              data-test-id={"CancelButton"}
              variant='contained'
              onClick={this.onCancel}
              size='medium'
              style={{
                ...webStyle.cancelButton,
                textTransform: "none",
                margin: "10px",
              }}
              disabled={!!this.state.snackBarMessage}
            >
              {configJSON.cancel}
            </SaveAndCancelButton>
            <SaveAndCancelButton
              variant='contained'
              data-test-id={"SaveButton"}
              onClick={this.onSave}
              size='medium'
              style={{
                ...webStyle.saveButton,
                textTransform: "none",
                margin: "10px",
                color:"#FFFFFF"
              }}
              color='secondary'
              disabled={!!this.state.snackBarMessage || this.state.isLoading}
            >
              {configJSON.add}
            </SaveAndCancelButton>
          </Box>
        </div>

        <Modal
          data-test-id='AddFieldPopup'
          open={this.state.addModalVisible}
          onClose={() => this.setState({ addModalVisible: false })}
        >
          <Box
            sx={{
              ...webStyle.addModalStyle,
              width: { xs: "90%", md: "720px" },
              left: "59%",
            }}
          >
            <div>
              <Typography
                variant='h6'
                style={{ ...webStyle.modalHeader, textAlign: "center" }}
              >
                Add Field
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "50px",
                  alignItems: "center",
                  marginBottom: "32px",
                }}
              >
                <div style={webStyle.addModalCategory}>Select Category:</div>
                <FormControl>
                  <RadioGroup
                    data-test-id={"CategoryRadioGroup"}
                    row
                    value={this.state.modalCategory}
                    onChange={this.onModalCategoryChange}
                    style={{ gap: "16px" }}
                  >
                    <FormControlLabel
                      style={{ fontFamily: "Montserrat !important" }}
                      value='text'
                      control={<Radio />}
                      label='TextField'
                    />
                    <FormControlLabel
                      value='dropdown'
                      control={<Radio />}
                      label='Dropdown'
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              {this.state.modalCategory === "text" ? (
                <div style={{ display: "flex", gap: "32px" }}>
                  <Grid container spacing={2}>
                    {this.renderTextInput(
                      "Title",
                      {
                        value: this.state.modalTitle,
                        onChange: (value) =>
                          this.setState({ modalTitle: value }),
                      },
                      this.state.modalError.title
                    )}
                    {this.renderTextInput(
                      "Textfield Text",
                      {
                        value: this.state.modalText,
                        onChange: (value) =>
                          this.setState({ modalText: value }),
                      },
                      this.state.modalError.text
                    )}
                  </Grid>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "auto",
                  }}
                >
                  {this.renderTextInput(
                    "Title",
                    {
                      value: this.state.modalTitle,
                      onChange: (value) => this.setState({ modalTitle: value }),
                    },
                    this.state.modalError.title
                  )}
                  <div
                    style={{ maxHeight: "300px", overflow: "auto" }}
                    ref={(container: HTMLDivElement | null) =>
                      (this.modalOptionRef = container)
                    }
                  >
                    {this.state.modalOptions.map((option, index) => {
                      return (
                        <div style={{ display: "flex", marginTop: "12px" }}>
                          {this.renderTextInput(
                            "Option " + (index + 1),
                            {
                              value: option.title,
                              onChange: (value) => {
                                const options = [...this.state.modalOptions];
                                options[index].title = value;
                                this.setState({ modalOptions: options });
                              },
                            },
                            this.state.modalError.option
                          )}
                          {index === this.state.modalOptions.length - 1 ? (
                            <Button
                              data-test-id={"AddOptionButton"}
                              variant='contained'
                              onClick={this.onAddOption}
                              size='medium'
                              style={webStyle.plusButton}
                              color='secondary'
                            >
                              <img src={imgPlus} />
                            </Button>
                          ) : (
                            <Button
                              data-test-id={"DeleteOptionButton"}
                              variant='text'
                              onClick={() => this.onDeleteOption(index)}
                              size='medium'
                              style={webStyle.deleteButton}
                            >
                              <img src={imgDelete} />
                            </Button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                gap: "32px",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <SaveAndCancelButton
                data-test-id='btn-cancel'
                variant='contained'
                onClick={this.onModalCancelClick}
                size='medium'
                style={{
                  ...webStyle.cancelButton,
                  fontSize: "16px",
                  textTransform: "none",
                  color: "rgba(100, 116, 139, 1)",
                }}
              >
                {configJSON.cancel}
              </SaveAndCancelButton>
              <SaveAndCancelButton
                data-test-id='btn-submit'
                variant='contained'
                onClick={this.onModalAddClick}
                size='medium'
                style={{
                  ...webStyle.saveButton,
                  fontSize: "16px",
                  textTransform: "none",
                }}
                color='secondary'
              >
                {configJSON.add}
              </SaveAndCancelButton>
            </div>
          </Box>
        </Modal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const InputLabelContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "6px",
  minHeight: "24px",
  "& .deleteIconBtn": {
    display: "none",
  },
  "&:hover .deleteIconBtn": {
    display: "initial",
  },
  "& .deleteIcon": {
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
    },
  },
  "@media only screen and (max-width: 1024px)": {
    minHeight: "19px",
  },
});

const webStyle = {
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  mainContainer: {
    margin: 0,
    fontFamily: "Montserrat !important",
  },
  formContainer: {
    padding: "32 10",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "12px",
  },
  inputField: {
    width: "100%",
    height: "56px",
    marginTop: "4px",
    fontFamily: "Montserrat !important",
  },
  rowContainer: {
    display: "flex",
    gap: "32px",
  },
  rowContainerBordered: {
    padding: "24px",
    paddingBottom: "0px",
    marginBottom: "24px",
    border: "1px solid #DEDEDE",
    borderRadius: "8px",
  },
  columnContainer: {
    width: "100%",
  },
  columnContainerTelephone: {
    display: "flex",
    gap: "15px"
  },
  addButton: {
    backgroundColor: "#F1F5F9",
    fontSize: "16px",
    fontWeight: 700,
    color: "rgba(77, 77, 77, 1)",
  },
  cancelButton: {
    backgroundColor: "#F1F5F9",
    width: "128px",
    color: "#64748B",
    fontSize: "16px",
    fontWeight: 700,
  },
  saveButton: {
    fontSize: "16px",
    backgroundColor: "#204B9C",
    width: "128px",
    fontWeight: 700,
  },
  plusButton: {
    backgroundColor: "#204B9C",
    width: "56px",
    height: "51px",
    marginTop: "32px",
    marginLeft: "20px",
  },
  deleteButton: {
    width: "56px",
    height: "51px",
    marginTop: "32px",
    marginLeft: "20px",
  },
  addModalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#FFFFFF",
    border: "1px solid #000",
    fontFamily: "Montserrat !important",
    boxShadow: 24,
    p: 4,
    width: "720px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  addModalCategory: {
    fontWeight: 500,
    fontSize: "20px",
    fontFamily: "Montserrat !important",
    lineHeight: "24px",
  },
  errorText: {
    color: "red",
    fontSize: "14px",
    marginTop: "4px",
  },
  pageHeader: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    marginTop: "82px",
  },
  modalHeader: {
    marginBottom: "32px",
    color: "#1A1A1A",
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  setAsDefault: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    marginTop: "10px",
  },
};

const StyleSubCheckDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

// Customizable Area End
